import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import store, { persistor } from './redux/store'; // Import store and persistor

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AgoraRTCProvider client={client}>
          <App />
        </AgoraRTCProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

reportWebVitals();
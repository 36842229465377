import { Box, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PatientDetailNav({ patient }) {
    const [certificateModalopen, certificateModal] = React.useState(false);
    const certificateModalShow = () => certificateModal(true);
    const certificateModalClose = () => certificateModal(false);

    const [prescriptionModalopen, prescriptionModal] = React.useState(false);
    const prescriptionModalShow = () => prescriptionModal(true);
    const prescriptionModalClose = () => prescriptionModal(false);
    return (
        <>
            <Grid container spacing={3} className='items-center'>
                <Grid item lg={6} md={12} xs={12}>
                    <div className='flex items-center gap-4'>
                        <div>
                            <img src={patient?.photo} alt='wait...' className='w-20 h-20 object-cover rounded-full' />
                        </div>
                        <div>
                            <h2 className='text-xl font-semibold'>{patient?.firstName} {patient?.lastName}</h2>
                            <p className='text-base font-semibold'>11 anni</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} md={12} xs={12} className='text-center'>
                    <div className='mb-2 lg:mt-[-40px]'>
                        <button type='button' className='min-w-72 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-[#828DEE] text-base font-semibold hover:bg-[#828DEE] hover:text-white transition-all '>Invia messaggio in privato</button>
                    </div>
                    <div className='mb-2'>
                        <button type='button' className='min-w-72 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-[#828DEE] text-base font-semibold hover:bg-[#828DEE] hover:text-white transition-all '>Invia avviso di salute Pubblica</button>
                    </div>
                    <div className='flex gap-3 justify-center'>
                        <button onClick={prescriptionModalShow} type='button' className='min-w-40 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-white text-base font-semibold bg-[#828DEE] transition-all'>Prescrizione</button>
                        <button onClick={certificateModalShow} type='button' className='min-w-40 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-white text-base font-semibold bg-[#828DEE] transition-all'>Certificato</button>
                    </div>
                </Grid>
            </Grid>

            <Modal
                open={certificateModalopen}
                onClose={certificateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[650px] w-[90%] bg-[#F8FFFC]  rounded-2xl">
                    <div className='pt-6 pb-6'>
                        <button type="button" onClick={certificateModalClose} className="absolute top-4 right-4 text-2xl"><i className="fa-solid fa-circle-xmark"></i></button>
                        <h2 className='md:text-2xl text-xl font-bold text-center mb-8'>Seleziona il certificato</h2>
                        <div className='mx-auto md:w-[320px]'>
                            <form>
                                <div className="relative">
                                    <input type="text" className="pl-10 w-full py-2 border border-gray-300 rounded-lg" placeholder="Cerca" />
                                    <button className="absolute top-2 left-3 text-gray-400"><i class="fa-regular fa-magnifying-glass"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                        <FormControl className='w-full'>
                            <RadioGroup name="certificate" className='radiobggroup'>
                                <FormControlLabel style={{ margin: '0' }} value="1" control={<Radio />} label=" Lorem ipsum dolor sit amet, consectetur a	" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="2" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="3" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="4" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="5" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="6" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className='p-4 text-center border-t'>
                        <button className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Inserisci</button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={prescriptionModalopen}
                onClose={prescriptionModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-8 p-6 rounded-2xl">
                    <div className='md:w-[290px] mx-auto'>
                        <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Seleziona il tipo di prescrizione</h2>

                        <div className='mb-8'>
                            <button className='p-6 bg-[#828DEE] rounded-lg'>
                                <div className='flex gap-5 items-center text-left'>
                                    <div><img src='../assets/images/rounded-colored.png' className='h-16 min-w-16' /></div>
                                    <h2 className='text-xl font-semibold text-white'>Ricetta Elettronica</h2>
                                </div>
                            </button>
                        </div>
                        <div className='mb-4'>
                            <button className='p-6 bg-[#AAB5FF] rounded-lg'>
                                <div className='flex gap-5 items-center text-left'>
                                    <div><img src='../assets/images/rounded-gray.png' className='h-16 min-w-16' /></div>
                                    <h2 className='text-xl font-semibold text-white'>Ricetta Bianca Elettronica</h2>
                                </div>
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

import './App.css';
import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate, Link } from 'react-router-dom';
import Home from './Component/Home/Home';
import Aboutus from './Component/About/Aboutus';
import Login from './Component/Login/Login';
import Register from './Component/Register/Register';
import PediatricianRegister from './Component/PediatricianRegister/PediatricianRegister';
import Dashboard from './Component/Dashboard/Dashboard';
import Payments from './Component/Payments/Payments';
import Bankinginfo from './Component/Payments/BankingInfo';
import AddChildren from './Component/Dashboard/AddChildren';
import DefaultLayout from './Layouts/DefaultLayout';
import MinimalLayout from './Layouts/MinimalLayout';
import Profile from './Component/Profile/Profile';
// import EditPersonalDetails from './Component/Profile/Editprofile';
import EditPersonalDetails from './Component/Profile/editprofile/editPersonaldata';
import EditProfessionalDetails from './Component/Profile/editprofile/editProfesstionaldata';
import EditIdentityDetails from './Component/Profile/editprofile/editDocuments';
import CertificateList from './Component/Certificate/certificates';
import CertificateRequestList from './Component/Certificate/CertificateRequests';
// import EditProfessionalInfo from './Component/Profile/ProfesstionalData';
import { UserProvider } from './hooks/useUserContext'; // Import your UserProvider
import useAuthStore from './hooks/useAuthStore'; // Import your UserProvider
import ResetPasswordTemplate from './Component/Register/ResetPassword';
import StripeOnboardSuccess from './Component/Payments/StripeSuccess';
import CalendarVisit from './Component/Calender/CalendarVisit';
import AddStudio from './Component/Calender/AddStudio';
import Studio from './Component/Calender/Studio';
import ChooseAvilability from './Component/Calender/ChooseAvilability';
import DateSpecificHours from './Component/Calender/DateSpecificHours';
import AddAvailability from './Component/Calender/AddAvailability';
import AddCertificate from './Component/Certificate/AddCertificate';
import CertificateDetail from './Component/Certificate/ViewCertificate';
import ServicesList from './Component/Calender/ServicesList';
import SettingView from './Component/Calender/SettingView';
import ViewCalander from './Component/Calender/ViewCalander';
import CalendarAgenda from './Component/Calender/CalendarAgenda';
import CalendarAvailability from './Component/Calender/CalendarAvailability';
import SettingVisit from './Component/Calender/SettingVisit';
import ServicesListmodify from './Component/Calender/ServicesListmodify';
import PracticeManagement from './Component/Profile/PracticeManagement';
import PatientList from './Component/Patients/PatientList';
import VideoCall from './Component/Call/VideoCall';
import AudioCall from './Component/Call/AudioCall';
import Notification from './Component/Notification/Notification';
import EmptyNotification from './Component/Notification/EmptyNotification';
import RichiesteSOS from './Component/Notification/RichiesteSOS';
import RichiesteConsulto from './Component/Notification/RichiesteConsulto';
import RichiestePrescrizioni from './Component/Notification/RichiestePrescrizioni';
import RichiesteCertificati from './Component/Notification/RichiesteCertificati';
import AdvanceSearch from './Component/PatientSearch/AdvanceSearch';
import PatientDetails from './Component/PatientSearch/PatientDetails';
import PatientMedicalRecord from './Component/PatientSearch/PatientMedicalRecord';
import EditInfo from './Component/PatientSearch/EditInfo';
import StampSignature from './Component/Profile/StampSignature';
import VideoCallClient from './Component/Calender/VideoCallClient';
import Sts from './Component/Profile/Sts';
import Receipts from './Component/Profile/Receipts';
import Communicationcenter from './Component/Profile/Communicationcenter';
import Chat from './Component/Chat/Chat';
import Sos from './Component/Sos/Sos';
import SosManagement from './Component/Sos/SosManagement';
import SosCaselist from './Component/Sos/SosCaselist';
import BlacklistSos from './Component/Sos/BlacklistSos';
import ChatSos from './Component/Chat/ChatSos';
import ViewCertificateList from './Component/Certificate/ViewCertificateRequest';
import { Constants } from './endPoints';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const ProtectedRoute = ({ element: Element }) => {
  const { token } = useAuthStore();
  return token ? <Element /> : <Navigate to="/login" />;
};

const MySwal = withReactContent(Swal);


const App = () => {

  const { token, resetToken } = useAuthStore();
  const navigate = useNavigate();
  const idPediatrician = localStorage.getItem('userId');
  const [sosRequestList, setRequestList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const fetchSOSRequests = useCallback(async () => {
    try {
      const response = await fetch(`${Constants.inProgressSOS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ idPediatrician: Number(idPediatrician) }),
      });
      const data = await response.json();
      if (data.status === 1) {
        setRequestList(data.requestList || []);
        return data.requestList || [];
      } else {
        setRequestList([]);
        return [];
      }
    } catch (error) {
      console.error('Error fetching SOS requests:', error);
      return [];
    }
  }, [token, idPediatrician]);

  const handleClose = async () => {
    if (selectedRequest) {
      try {
        const response = await fetch(`${Constants.sosStateUpdate}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: selectedRequest.id,
            state: 'Open',
          }),
        });

        const result = await response.json();

        if (response.ok && result.status === 1) {
          console.log('State updated successfully:', result);
          setSelectedRequest(null); // Clear the selected request
          const updatedRequests = await fetchSOSRequests(); // Fetch fresh records after updating
          processNextUnreadRequest(updatedRequests); // Process the next unread request
        } else {
          console.error('Failed to update state:', result);
        }
      } catch (error) {
        console.error('Error updating state:', error);
      }
    }
    setOpen(false);
  };

  const handleLinkClick = async () => {
    if (selectedRequest) {
      try {
        const response = await fetch(`${Constants.sosStateUpdate}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: selectedRequest.id,
            state: 'Open',
          }),
        });
  
        const result = await response.json();
  
        if (response.ok && result.status === 1) {
          console.log('State updated successfully:', result);
          setSelectedRequest(null);
          const updatedRequests = await fetchSOSRequests();
          processNextUnreadRequest(updatedRequests);
        } else {
          console.error('Failed to update state:', result);
        }
      } catch (error) {
        console.error('Error updating state:', error);
      }
    }
    setOpen(false);
    navigate(`/chat-sos/${selectedRequest.id}`); // Pass the request ID dynamically
  };
  

  const processNextUnreadRequest = (requestList) => {
    console.log("top requestList ", requestList);
    if (!open) {
      const nextRequest = requestList.find(
        (request) => request.status === 'New' && request.isRead === false
      );

      console.log("nextRequest ", nextRequest);

      if (nextRequest) {
        setSelectedRequest(nextRequest);
        setOpen(true); // Open modal for the next unread request
      }
    }
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await fetch(`${Constants.checkAccessToken}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({ token }),
        });
        const data = await response.json();

        if (data.status === 0) {
          MySwal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            icon: 'error',
          });

          resetToken();
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking token validity:', error);
        MySwal.fire({
          title: 'Error',
          text: 'An error occurred while verifying your session. Please try again.',
          icon: 'error',
        });
      }
    };

    const fetchAndProcessRequests = async () => {
      // Check token validity before proceeding
      if (token) {
        await checkTokenValidity();
      }

      // Fetch the latest SOS requests
      const requests = await fetchSOSRequests();

      // Process the next unread request if available
      processNextUnreadRequest(requests);
      console.log("processNextUnreadRequest ", requests);
    };

    // Call fetchAndProcessRequests on page load
    fetchAndProcessRequests();

    // Set up the interval to periodically fetch and process requests
    const intervalId = setInterval(fetchAndProcessRequests, 10000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [token, fetchSOSRequests, resetToken, navigate]);

  console.log('sosRequestList ', sosRequestList);




  return (
    <UserProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/register" element={<Register />}></Route>
          <Route exact path="/aboutus" element={<Aboutus />}></Route>
          <Route exact path="/rspassword" element={<ResetPasswordTemplate />}></Route>
          <Route exact path="/pediatrician-register" element={<PediatricianRegister />}></Route>


          {/* Wrap protected routes inside the ProtectedRoute component */}
          <Route exact path="/" element={<DefaultLayout />}>
            <Route exact path="/payments/success" element={<ProtectedRoute element={StripeOnboardSuccess} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
            <Route path="/payments" element={<ProtectedRoute element={Payments} />} />
            <Route path="/banking-info" element={<ProtectedRoute element={Bankinginfo} />} />
            <Route exact path="/profile/child/add" element={<ProtectedRoute element={AddChildren} />} />
            <Route exact path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route exact path="/profile/personal/edit" element={<ProtectedRoute element={EditPersonalDetails} />} />
            <Route exact path="/profile/professional/edit" element={<ProtectedRoute element={EditProfessionalDetails} />} />
            <Route exact path="/certificates" element={<ProtectedRoute element={CertificateList} />} />
            <Route exact path="/certificate-requests" element={<ProtectedRoute element={CertificateRequestList} />} />
            <Route exact path="/add-certificate" element={<ProtectedRoute element={AddCertificate} />} />
            <Route exact path="/edit-certificate/:certId" element={<ProtectedRoute element={AddCertificate} />} />
            <Route exact path="/view-certificate/:certId" element={<ProtectedRoute element={CertificateDetail} />} />
            <Route exact path="/profile/identity/edit" element={<ProtectedRoute element={EditIdentityDetails} />} />
            <Route exact path="/calendar-visit" element={<ProtectedRoute element={CalendarVisit} />} />
            <Route exact path="/studio" element={<ProtectedRoute element={Studio} />} />
            <Route exact path="/add-studio" element={<ProtectedRoute element={AddStudio} />} />
            <Route exact path="/choose-availability" element={<ProtectedRoute element={ChooseAvilability} />} />
            <Route exact path="/add-date-specific-hours" element={<ProtectedRoute element={DateSpecificHours} />} />
            <Route exact path="/add-availability" element={<ProtectedRoute element={AddAvailability} />} />
            <Route exact path="/services-list" element={<ProtectedRoute element={ServicesList} />} />
            <Route exact path="/settings" element={<ProtectedRoute element={SettingView} />} />
            <Route exact path="/view-calander" element={<ProtectedRoute element={ViewCalander} />} />
            <Route exact path="/availability-calander" element={<ProtectedRoute element={CalendarAvailability} />} />
            <Route exact path="/agenda-calander" element={<ProtectedRoute element={CalendarAgenda} />} />
            <Route exact path="/setting-visit" element={<ProtectedRoute element={SettingVisit} />} />
            <Route exact path="/services-list-modify" element={<ProtectedRoute element={ServicesListmodify} />} />
            <Route exact path="/medical-practice-management" element={<ProtectedRoute element={PracticeManagement} />} />
            <Route exact path="/notification" element={<ProtectedRoute element={Notification} />} />
            <Route exact path="/empty-notification" element={<ProtectedRoute element={EmptyNotification} />} />
            <Route exact path="/request-sos" element={<ProtectedRoute element={RichiesteSOS} />} />
            <Route exact path="/request-consultation" element={<ProtectedRoute element={RichiesteConsulto} />} />
            <Route exact path="/request-prescription" element={<ProtectedRoute element={RichiestePrescrizioni} />} />
            <Route exact path="/request-certificate" element={<ProtectedRoute element={RichiesteCertificati} />} />
            <Route exact path="/patients" element={<ProtectedRoute element={PatientList} />} />
            <Route exact path="/advance-search" element={<ProtectedRoute element={AdvanceSearch} />} />
            <Route exact path="/patient-details/:idPatient" element={<ProtectedRoute element={PatientDetails} />} />
            <Route exact path="/patient-medical-record/:idPatient" element={<ProtectedRoute element={PatientMedicalRecord} />} />
            <Route exact path="/edit-patient-info/:idPatient" element={<ProtectedRoute element={EditInfo} />} />
            <Route exact path="/stamp-and-signature" element={<ProtectedRoute element={StampSignature} />} />
            <Route exact path="/sts" element={<ProtectedRoute element={Sts} />} />
            <Route exact path="/receipts" element={<ProtectedRoute element={Receipts} />} />
            <Route exact path="/communication-center" element={<ProtectedRoute element={Communicationcenter} />} />
            <Route exact path="/chat" element={<ProtectedRoute element={Chat} />} />
            <Route exact path="/sos" element={<ProtectedRoute element={Sos} />} />
            <Route exact path="/sos-management" element={<ProtectedRoute element={SosManagement} />} />
            <Route exact path="/sos-case-list" element={<ProtectedRoute element={SosCaselist} />} />
            <Route exact path="/sos-blacklist" element={<ProtectedRoute element={BlacklistSos} />} />
            <Route exact path="/chat-sos/:id" element={<ProtectedRoute element={ChatSos} />} />
            <Route exact path="/view-certificate-request/:id" element={<ProtectedRoute element={ViewCertificateList} />} />
          </Route>

          {/* Routes with MinimalLayout (No Header/Footer) */}
          <Route element={<MinimalLayout />}>
            <Route exact path="/video-call" element={<ProtectedRoute element={VideoCall} />} />
            <Route exact path="/audio-call" element={<ProtectedRoute element={AudioCall} />} />
            <Route exact path="/meet/:channelId" element={<ProtectedRoute element={VideoCallClient} />} />
          </Route>

        </Routes>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-[800px] w-[90%] bg-[#F8FFFC] md:py-8 md:px-16 p-6 rounded-2xl">
            <h2 className="md:text-2xl text-xl font-bold text-center mb-6">🆘 RICHIESTA SOS 🚨</h2>
            {selectedRequest && (
              <>
                <div className="text-center mb-6">
                  <h3 className="text-2xl font-semibold mb-3">{selectedRequest.caseTitle}</h3>
                  <p>{selectedRequest.descriptions}</p>
                </div>
                <div className="text-center mb-6">
                  <p>Paziente</p>
                  <h3 className="text-2xl font-semibold">
                    {selectedRequest.patientFirstName} {selectedRequest.patientLastName}
                  </h3>
                  <p className="font-semibold">
                    Età: {new Date().getFullYear() - new Date(selectedRequest.patientDateOfBirth).getFullYear()}
                  </p>
                </div>
                <div className="text-center mb-6">
                  <p>Richiesto da</p>
                  <p className="font-semibold">
                    {selectedRequest.parentFirstName} {selectedRequest.parentLastName}
                  </p>
                </div>
                <div className="text-center mb-6">
                  <Link to="#" className="font-semibold text-red-500 underline">Vedi cartella clinica</Link>
                </div>
                <div className="text-center mb-1">
                <Link
                  to="/chat-sos"
                  className="inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-white text-base font-semibold bg-red-500 mb-3"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent immediate navigation
                    handleLinkClick(); // Call the reusable function
                  }}
                >
                  Vai alla chat
                </Link>

                </div>
                <div className="text-center mb-1">
                  <Link
                    to="/audio-call"
                    className="inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-red-500 text-base font-semibold bg--red-500 mb-3"
                  >
                    <i className="fa-regular fa-phone"></i> Chiama
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    to="/video-call"
                    className="inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-red-500 text-base font-semibold bg--red-500 mb-3"
                  >
                    <i className="fa-regular fa-video"></i> Videochiamata
                  </Link>
                </div>
              </>
            )}
          </Box>
        </Modal>

      </div>
    </UserProvider>

  );
};

export default App;
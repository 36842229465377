import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Switch } from '@mui/material';
import './Sos.css';
import axios from 'axios';
import { Constants } from '../../endPoints';
import useAuthStore from "../../hooks/useAuthStore";

export default function SosManagement() {
    const navigate = useNavigate();
    const { token } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list
    const [isSOSActive, setIsSOSActive] = useState(false); // Reflect SOS status

    const fetchPediatricianStatus = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);

            setPediatricianDetail(response.data.data);
            setIsSOSActive(response.data.data.isSOSActive); // Set SOS status

            setLoading(false);
        } catch (error) {
            console.error("Error fetching pediatrician data:", error);
            setError("Failed to fetch pediatrician data. Please try again later.");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPediatricianStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPediatrician]);

    const toggleSOS = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${Constants.sosActivationdeactivation}`,
                { idPediatrician },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data && response.data.status === 1) {
                setIsSOSActive(!isSOSActive); // Toggle the SOS state
            } else {
                console.error("Failed to toggle SOS.");
                setError("Failed to update SOS status. Please try again.");
            }
        } catch (error) {
            console.error("Error toggling SOS:", error);
            setError("An error occurred while updating SOS status. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="2xl:container mx-auto">
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className="bg-[#FAFAFA]">
                        <div>
                            <div className="flex gap-3 mb-6 font-semibold">
                                <Link to="/profile">
                                    <i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo
                                </Link>
                                <span>/</span>
                                <span>Gestione SOS</span>
                            </div>
                            <div>
                                <h1 className="text-center font-bold text-3xl my-10">SOS</h1>
                            </div>
                            <Grid container spacing={3} className="justify-center">
                                <Grid item lg={9} md={12} xs={12}>
                                    <div className="mb-6">
                                        <Link
                                            to={isSOSActive ? "/sos-case-list" : "#"}
                                            className={`flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg transition-all ${isSOSActive ? "hover:bg-[#aab5ff]" : "cursor-not-allowed opacity-50"
                                                }`}
                                            onClick={(e) => {
                                                if (!isSOSActive) {
                                                    e.preventDefault(); // Prevent navigation if SOS is inactive
                                                }
                                            }}
                                        >
                                            <div className="flex gap-5 items-center">
                                                <div className="min-w-5 text-center">
                                                    <i className="fa-sharp fa-regular fa-list-check"></i>
                                                </div>
                                                Lista casi SOS
                                            </div>
                                            <div>
                                                <i className="fa-solid fa-angle-right"></i>
                                            </div>
                                        </Link>

                                        <Link
                                            to={isSOSActive ? "/sos-blacklist" : "#"}
                                            className={`flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg transition-all ${isSOSActive ? "hover:bg-[#aab5ff]" : "cursor-not-allowed opacity-50"
                                                }`}
                                            onClick={(e) => {
                                                if (!isSOSActive) {
                                                    e.preventDefault(); // Prevent navigation if SOS is inactive
                                                }
                                            }}
                                        >
                                            <div className="flex gap-5 items-center">
                                                <div className="min-w-5 text-center">
                                                    <i className="fa-regular fa-ban"></i>
                                                </div>
                                                Blacklist SOS
                                            </div>
                                            <div>
                                                <i className="fa-solid fa-angle-right"></i>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="sosSwitch">
                                        <label className="font-semibold">
                                            <Switch
                                                checked={isSOSActive} // Reflect the SOS status
                                                onChange={toggleSOS} // Call API on toggle
                                            />{' '}
                                            Funzionalità SOS attiva
                                        </label>
                                        {error && (
                                            <div className="mt-4 text-center">
                                                <p className="text-red-500 font-semibold">{error}</p>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

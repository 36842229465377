import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import useAuthStore from "../hooks/useAuthStore";
import DoctorProfile from '../Component/Profile/DocterProfile';
import { useUser } from '../hooks/useUserContext'; // Import your custom hook to access UserContext

export default function NavigationBar() {
    const { resetToken } = useAuthStore();
    const [selectedLink, setSelectedLink] = useState('');
    const { user, deleteUser } = useUser(); // Access the user info from the context

    useEffect(() => { }, [])


    // Handler function to set the selected link
    const handleLinkClick = (linkName) => {
        setSelectedLink(linkName);
    };


    return (<>
        <div className='pt-8 pb-14 bg-[#AAB5FF] lg:min-h-screen relative sticky top-0'>
            <div className='absolute w-full left-0 bottom-4'>
                <img src='assets/images/logo-white.png' className='w-[98px] mx-auto' />
            </div>
            <DoctorProfile doctor={user} />

            <div className='xl:mb-14 mb-5'>
                <Link to='/dashboard' className={`flex justify-between py-3 px-6  w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold ${selectedLink === 'home' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('home')}>
                    <div><i class="fa-regular fa-house mr-2"></i> Home</div>
                </Link>
                <Link to='/profile' className={`flex justify-between py-3 px-6  w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold  ${selectedLink === 'profile' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('profile')}>
                    <div><i class="fa-regular fa-user mr-2"></i> Gestisci profilo</div>
                </Link>
                <Link to='/payments' className={`flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold ${selectedLink === 'payments' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('payments')}>
                    <div><i class="fa-regular fa-credit-card mr-2"></i> Payments</div>
                </Link>
                <Link
                    to='/notification'
                    className={`flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold relative ${selectedLink === 'notification' ? 'bg-[#FAFAFA]' : ''
                        }`}
                    onClick={() => handleLinkClick('notification')}
                >
                    <div>
                        <i className="fa-regular fa-bell mr-2"></i> Notifiche
                    </div>
                    {/* Optional Notification Badge */}
                    <span className='w-4 h-4 bg-red-500 inline-block rounded-full'></span>
                </Link>

                <Link to='/calendar-visit' className={`flex justify-between py-3 px-6  w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold ${selectedLink === 'calendar-visit' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('calendar-visit')}>
                    <div><i class="fa-regular fa-calendar-days mr-2"></i> Calendario visite</div>
                </Link>
                <Link to='/certificates' className={`flex justify-between py-3 px-6  w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold ${selectedLink === 'certificates' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('certificates')}>
                    <div><i class="fa-regular fa-calendar-days mr-2"></i> Certificati</div>
                </Link>
                <Link to='#' className='flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold relative'>
                    <div><i class="fa-regular fa-square-question mr-2"></i> Non evase</div>
                </Link>
                <Link to='#' className='flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold relative'>
                    <div><i class="fa-sharp fa-regular fa-circle-exclamation mr-2"></i> Casi aperti</div>
                </Link>
                <Link to='/patients' className={`flex justify-between py-3 px-6  w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black border-b border-[#E3E3E3] font-semibold ${selectedLink === 'patient-list' ? 'bg-[#FAFAFA]' : ''} relative`} onClick={() => handleLinkClick('patient-list')}>
                    <div><i class="fa-regular fa-file mr-2"></i> Ricerca pazienti</div>
                </Link>
                <Link to='#' className='flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black font-semibold' onClick={(e) => {
                    e.preventDefault()
                    resetToken()
                    deleteUser()
                }}>
                    <div><i class="fa-regular fa-power-off mr-2"></i> Esci </div>
                </Link>
            </div>
            <div>
                <Link to='#' className='flex justify-between py-3 px-6 w-full items-center capitalize hover:bg-[#FAFAFA] transition-all hover:text-black'>
                    <div><i class="fa-sharp fa-regular fa-square-exclamation mr-2"></i> Assistenza</div>
                </Link>
            </div>
        </div>
    </>)
}


import React, { useState, useEffect } from 'react'
import { Grid, TextField, CircularProgress } from '@mui/material'
import { Constants } from '../../endPoints';
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useAuthStore from "../../hooks/useAuthStore";
import { Link } from 'react-router-dom';


export const EmailConfirmation = ({ handleNext, meta }) => {
    const [apiError, setApiError] = useState(''); // State to hold API error
    
    const sendEmailOtp = async () => {
        try {
            console.log("META", meta)
            const response = await axios.post(Constants.resendConfirmationEmail, {
                email: meta.email
            })
            if (response.status === 200) {
                console.log('Signup successful')
                handleNext()
            } else {
                console.error('Signup failed')
            }
        } catch (error) {
            console.error('Error in signup:', error)
            if (error.response && error.response.data) {
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error occurred. Please try again later.')
            }
        }

    }


    return (
        <>
            <Grid item xl={6} lg={7} md={8} xs={12}>
                <h2 className='mb-8 text-3xl font-semibold text-center'>
                    Conferma email
                </h2>
                <div className='mb-5'>
                    <p className="text-center">
                        Conferma la tua email cliccando sul link ricevuto nella tua casella email!
                    </p>
                </div>
                <div className='mb-3'>
                    <p className="text-center">
                        Non hai ricevuto l'email? Prova a controllare nello spam.
                    </p>
                </div>
                <div className='text-center'>
                    <a href='/top-entry-page' className='text-blue-500 hover:underline' onClick={(e) => {
                        e.preventDefault();
                        if (meta.update) {
                            sendEmailOtp();
                        }
                        handleNext();
                    }}>
                        Torna alla pagina principale
                    </a>
                </div>
            </Grid>
        </>
    )
};

export const EmailConfirmOTP = ({ handleNext, meta, setMeta }) => {
    const { setToken, token,setId } = useAuthStore();

    const [apiError, setApiError] = useState(''); // State to hold API error
    const [timer, setTimer] = useState(60); // State to manage the countdown timer

    // Start the timer when the component loads
    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
    
            return () => clearInterval(countdown); // Cleanup on unmount or timer change
        }
    }, [timer]); // Re-run the effect whenever the `timer` value changes

    const otpFormik = useFormik({
        initialValues: {
            otp: '',
        },
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('OTP Submitted:', values);
            confirmOTP(values, setMeta);
            // Call the handleNext function when OTP is submitted successfully
        },
    });

    const resendConfirmationEmail = async () => {
        try {
            await axios.post(Constants.resendConfirmationEmail, {
                email: meta.email
            })

            setTimer(60); // Reset the timer to 60 seconds
            setApiError('');
        } catch (error) {
            console.error('Otp sent ERROR :', error)
            if (error.response && error.response.data) {
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error Otp sent. Please try again later.')
            }
        }
    }

    const confirmOTP = async (values,setMeta) => {
        try {

            const body = { otp: values.otp || meta.emailOtp, email: meta.email }
            const response = await axios.post(Constants.VerifyEmailOtpV2, body,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                console.log('-- Otp verified ---')
                setMeta({ ...meta, isEmailVerified: true });
                handleNext();
            } else {
                console.error('----- email OTP verification failed ----')
            }
        } catch (error) {
            console.error('Error in signup:', error)
            if (error.response && error.response.data) {
                console.log("Email is already registered")
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error occurred. Please try again later.')
            }
        }
    }



    return (
        <Grid item xl={6} lg={7} md={8} xs={12}>
            <h2 className="mb-8 text-3xl font-semibold text-center">
                Verifica l'indirizzo email
            </h2>
            <p className="text-center mb-10">Inserisci il codice di 6 cifre che hai ricevuto via email</p>

            <form onSubmit={otpFormik.handleSubmit}>
                <div className="mb-5">
                    <TextField
                        fullWidth
                        label="Inserisci il codice OTP"
                        variant="outlined"
                        placeholder="Codice OTP"
                        name="otp"
                        value={otpFormik.values.otp}
                        onChange={otpFormik.handleChange}
                        onBlur={otpFormik.handleBlur}
                        error={otpFormik.touched.otp && Boolean(otpFormik.errors.otp)}
                        helperText={otpFormik.touched.otp && otpFormik.errors.otp}
                        InputProps={{
                            style: { height: '54px' }, // Adjust the height as needed
                        }}
                    />
                </div>
                <div className="mb-5 text-center">
                    <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>
                        Conferma
                    </button>
                    {/* <div className='mt-3'>
                        <Link to='/top-entry-page' className='underline text-[#828DEE]' onClick={(e) => {
                        e.preventDefault();
                        resendConfirmationEmail();
                        }}>
                            Risentirsi otp
                        </Link>
                    </div> */}

                    <div className="text-center mt-4 mb-10">
                        <a
                            href="#"
                            className="text-[#828DEE] underline text hover:text-black transition-all"
                            style={{
                                display: 'block',
                                textAlign: 'center',
                                marginTop: '10px',
                                cursor: timer > 0 ? 'not-allowed' : 'pointer',
                                pointerEvents: timer > 0 ? 'none' : 'auto', // Disable interactions when timer > 0
                                opacity: timer > 0 ? 0.5 : 1, // Add visual feedback for disabled state
                            }}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default anchor behavior
                                if (timer === 0) {
                                    resendConfirmationEmail(); // Call the resend OTP function only if timer is 0
                                }
                            }}
                        >
                            Non hai ricevuto l’email?
                        </a>

                        {timer > 0 ? (
                            <p>Puoi richiederne l’invio tra {timer}s</p>
                        ) : null}
                    </div>
                    
                    {apiError && <div className="text-red-500 text-sm m-3">{apiError}</div>}
                </div>
            </form>
        </Grid>
    );
};

export const EmailConfirmationComplete = ({ handleNext, meta }) => {
    const [apiError, setApiError] = useState(''); // State to hold API error

    useEffect(() => { })
    return (
        <>
            <Grid item xl={6} lg={7} md={8} xs={12}>
                <h2 className='mb-8 text-3xl font-semibold text-center'>
                    Indirizzo email confermato!
                </h2>
                <div className='mb-5'>
                    <p className="text-center">
                        Congratulazioni! La tua email è stata verificata. Ancora pochi passaggi per completare la registrazione.
                    </p>
                </div>
                <div className='mb-5'>
                    <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all' onClick={(e) => {
                        handleNext();
                    }}>
                        Avanti
                    </button>
                </div>
            </Grid>
        </>
    )
};
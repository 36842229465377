import React from 'react'
import {Link} from 'react-router-dom'
import { Box, Checkbox,  Modal } from '@mui/material'

export default function EventDetails() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
    <div className='eventdetailBox pr-5'>
        <h2 className='text-2xl font-semibold mb-1'>Visita in studio</h2>
        <div className='flex justify-between items-center mb-5'>
            <div className='font-semibold text-base'>19/06/2024</div>
            <div className='text-right text-base'><Link to="#" onClick={handleOpen} className="underline text-[#828DEE] italic">Aggiungi nota del pediatra</Link></div>
        </div>
        <p className='font-semibold mb-3'>Diagnosi pediatra</p>
        <p className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor i</p>
        <div className='mb-3'>
            <p>Documento di identità</p>
            <button className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png'/> Carta di identità</button>
        </div>
    </div>

    <Modal 
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-8 p-6 rounded-2xl">
          
        <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Aggiungi nota</h2>
          <form>
              <div className='mb-5'>
                  <textarea rows="6" className='w-full rounded-lg border border-gray-300 bg-transparent px-4 py-3' placeholder='Note'></textarea>
              </div>
              <div className='text-center'>
                  <label><Checkbox /> Rendi visibile al paziente</label>
              </div>
              <div className='text-center mt-10'>
                  <button className='block text-center leading-[54px] w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all mx-auto'>Salva</button>
                  
              </div>
              <div className='mt-6 text-center'>
                  <button type='button' className='text-[#828DEE] underline' onClick={handleClose}>Chiudi</button>
              </div>
          </form>
        
      </Box>
    </Modal>
    </>
  )
}

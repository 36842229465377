import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Radio, Modal, Box, TextField, Checkbox } from '@mui/material'
import { Link } from 'react-router-dom';
import { Constants } from '../../endPoints';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2

export default function PatientList() {
    const idPediatrician = localStorage.getItem('userId');
    const [taxID, setTaxID] = useState('');
    const [type, setType] = useState('PRIVATE');
    const [error, setError] = useState('');

    const location = useLocation();
    const [searchParams, setSearchParams] = useState({
        keyword: '',
        name: '',
        parent: '',
        ageStart: '',
        ageEnd: '',
        healthCardNumber: '',
        isSSN: '',
        isPrivate: ''
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [patientsData, setPatientsData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isFirstLoad = useRef(true); // Track if it's the first load

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setTaxID(''); // Clear taxID field
        setType('PRIVATE'); // Reset type to default
        setOpen(true); // Open the modal
        setError('');
    };

    const handleTaxIDChange = (e) => {
        setTaxID(e.target.value);
        if (error) setError(''); // Clear error when typing
    };

    const handleClose = () => setOpen(false);

    // Fetch patients data
    const getPatients = async (params = {}) => {
        try {
            setLoading(true);
            setErrorMessage(''); // Clear previous error message
            const response = await axios.get(`${Constants.getPediatricianPatients}${idPediatrician}`, { params });
            if (response.data.status === 1) {
                setPatientsData(Array.isArray(response.data.patient) ? response.data.patient : []);
            } else {
                setPatientsData([]);
                setErrorMessage(response.data.message || 'No patients found.');
            }
        } catch (error) {
            setPatientsData([]);
            setErrorMessage(error.response?.data?.message || error.message || 'Error fetching Patient data.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch saved searches
    const getSaveSearch = async () => {
        try {
            const response = await axios.get(`${Constants.pediatricianSearchSaveList}${idPediatrician}`);
            if (response.data.status === 1) {
                setSearchData(Array.isArray(response.data.list) ? response.data.list : []);
            } else {
                console.error('Failed to fetch result:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching saved searches:', error);
        }
    };

    // Handle small search on the page
    const handleSmallSearch = () => {
        const updatedParams = { ...searchParams, keyword: searchQuery };
        setSearchParams(updatedParams); // This will trigger the `useEffect`
    };

    // Handle navigation-triggered updates
    useEffect(() => {
        if (location.state?.searchParams) {
            setSearchParams(location.state.searchParams);
            setPatientsData([]); // Clear old patient data for a new search
        }
    }, [location.state]);

    // Handle initial load and search parameter changes
    useEffect(() => {
        if (isFirstLoad.current) {
            // Initial load
            getPatients(searchParams);
            getSaveSearch();
            isFirstLoad.current = false;
        } else {
            // Subsequent updates when searchParams change
            getPatients(searchParams);
        }
    }, [searchParams]);

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const submitPatient = async (e) => {
        e.preventDefault();
    
        // Validation for Codice Fiscale
        if (!taxID.trim()) {
            setError('Please enter the Codice Fiscale.');
            return; // Stop execution if validation fails
        }
    
        const payload = {
            idPediatrician,
            taxCode: taxID,
            type
        };
    
        try {
            const response = await axios.post(`${Constants.addPatients}`, payload);
    
            if (response.data.status === 1) {
                // Show success message
                Swal.fire({
                    icon: 'success',
                    title: 'Thank you!',
                    text: 'Patient added successfully!',
                });
    
                // Clear fields and close modal
                setTaxID(''); // Clear taxID field
                setType('PRIVATE'); // Reset type to default
                handleClose(); // Close the modal
    
                // Refresh patient list with the current searchParams
                await getPatients(searchParams); 
            } else {
                throw new Error(response.data.message || 'Failed to add patient');
            }
        } catch (error) {
            // Show error message
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'An error occurred while adding the patient.',
            });
    
            // Reset fields
            setTaxID(''); // Clear taxID field
            setType('PRIVATE'); // Reset type to default
            setError('');
        }
    };
    


    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <Grid container spacing={3} className='justify-center'>
                                <Grid item lg={6} md={6} xs={6}>
                                    <div className='flex gap-3 mb-6 font-semibold'>
                                        <Link to="/calendar-visit">
                                            <i className="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti
                                        </Link>
                                    </div>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} className='text-right'>
                                    <button type='button' onClick={handleOpen} className=' py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-[#828DEE] text-base font-semibold hover:bg-[#828DEE] hover:text-white transition-all'>
                                        + Aggiungi paziente
                                    </button>
                                </Grid>
                                <Grid item lg={7} md={12} xs={12}>
                                    <form>
                                        <div className='relative'>
                                            <div>
                                                <input type="text" className='pl-10 w-full py-2 border border-gray-300 rounded-lg'
                                                    placeholder="Cerca pazienti..."
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)} />
                                                <button type="button" className='absolute top-2 right-3 text-gray-400' onClick={handleSmallSearch}>
                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                            <div className='text-center mt-6'>
                                <Link to="/advance-search" className="text-[#828DEE] underline font-semibold">Ricerca avanzata</Link>

                                {/* Conditionally render "Ricerche salvate" section */}
                                {searchData.length > 0 && (
                                    <>
                                        <p className='mt-3 text-sm'>Ricerche salvate</p>
                                        <div className='flex justify-center mt-3 gap-2 flex-wrap'>
                                            {searchData.map((search, index) => (
                                                <button
                                                    key={index}
                                                    className='py-1 px-6 border text-sm border-[#C5C8C9] rounded-full'
                                                >
                                                    {search.name} - {search.age} {search.parent}
                                                </button>
                                            ))}
                                        </div>
                                    </>
                                )}

                                <h2 className='font-semibold text-2xl mt-6 mb-1'>I miei pazienti</h2>
                                <p>Totale {patientsData.length}</p>
                            </div>
                            <div className='text-right mb-5'>
                                <button type='button' className='text-[#828DEE]'><i className="fa-sharp fa-solid fa-list-check"></i> <span className='underline'>Seleziona</span></button>
                            </div>
                            {loading ? (
                                <div className='text-center'>Loading...</div>
                            ) : patientsData.length === 0 ? (
                                <div className='text-center text-red-500'>{errorMessage || 'No patients found.'}</div>
                            ) : (
                                <Grid container spacing={3}>
                                    {patientsData.map((patient, index) => (
                                        <Grid item lg={4} md={6} xs={12} key={index}>
                                            <div className='flex bg-white rounded-lg shadow-xl overflow-hidden'>
                                                <div className='min-h-full max-w-28 w-28'>
                                                    <Link to={`/patient-details/${patient.id}`}>
                                                        <img src={patient.photo || 'assets/images/default-profile.png'} className='h-full object-cover' alt='Patient' />
                                                    </Link>
                                                </div>
                                                <div className='p-3 grow relative'>
                                                    <span className='absolute right-0 top-0 hidden'><Radio /></span>
                                                    <h2 className='font-semibold text-lg'>{patient.firstName} {patient.lastName}</h2>
                                                    {patient.parents && patient.parents.length > 0 && (
                                                        <p className='text-sm'>{patient.parents[0].firstName} {patient.parents[0].lastName}</p>
                                                    )}
                                                    <p className='py-1 text-base'>{patient.dateOfBirth} - <span className='font-semibold'>{calculateAge(patient.dateOfBirth)} anni</span></p>
                                                    <p className='text-sm'>N° TS: {patient.taxCode}</p>
                                                    <p className='text-sm'>Paziente {patient.type}</p>
                                                    <div className='text-sm text-right'>
                                                        <Link to={`/patient-details/${patient.id}`} className="underline text-[#828DEE]">
                                                            vedi dettagli <i className="fa-regular fa-angle-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                        </div>
                    </Grid>
                </Grid>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
                    <button type='button' className='absolute top-4 right-4 text-2xl' onClick={handleClose}>
                        <i className="fa-solid fa-circle-xmark"></i>
                    </button>
                    <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Inserisci paziente</h2>
                    <form onSubmit={submitPatient}>
                        <div className='mb-5'>
                            <TextField
                                label="Codice Fiscale"
                                variant="outlined"
                                className='w-full rounded-lg'
                                value={taxID}
                                onChange={(e) => setTaxID(e.target.value)}
                            />
                            {error && <p className="text-red-600 text-sm mt-1">{error}</p>} {/* Error message */}
                        </div>
                        <div className='text-center'>
                            <label>
                                <Checkbox
                                    checked={type === "SSN"}
                                    onChange={(e) => setType(e.target.checked ? "SSN" : "PRIVATE")}
                                />
                                Paziente SSN
                            </label>
                        </div>
                        <div className='text-center mt-10'>
                            <button
                                type="submit"
                                className='block text-center leading-[54px] w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                            >
                                Aggiungi
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}

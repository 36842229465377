import React, {useState} from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function MedicalFilter() {
    

    const [isVisible, setIsVisible] = useState(false);
    const handleClick = () => {
        setIsVisible(!isVisible); 
    };
    const dateClose = () => setIsVisible(false);

    const [urgencyVisible, urgencyIsVisible] = useState(false);
    const urgencyClick = () => {
        urgencyIsVisible(!isVisible); 
    };
    const urgencyClose = () => urgencyIsVisible(false);
  return (
    <>
        <div className='flex justify-end gap-4 lg:mt-[-45px] items-center relative'>
            <p className='text-sm'>Filtra per</p>
            <button type='button' onClick={handleClick} className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'>  Date</button>
            <button type='button' onClick={urgencyClick} className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'>Urgenza</button>
            <button type='button' className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'><i class="fa-regular fa-magnifying-glass"></i> Cerca</button>

            {isVisible && (
            <div className='md:w-[396px] w-[280px] lg:px-10 absolute top-14 right-0 bg-white p-6 shadow-lg rounded-lg border'>
                <form>
                    <p className='font-semibold mb-5 text-lg text-center'>Range date</p>
                    <div className='mb-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']} >
                                <DatePicker label="Da gg/mm/aaaa" className="w-full" format="DD/MM/YYYY" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className='mb-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']} >
                                <DatePicker label="A gg/mm/aaaa" className="w-full" format="DD/MM/YYYY" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className='mb-4 mt-8 text-center'>
                        <button className='lg:w-72 w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Mostra</button>
                    </div>
                    <div className='text-center mb-4'>
                        <button onClick={dateClose} type='button' className='text-[#828DEE] underline font-semibold'>Annulla</button>
                    </div>
                </form>
            </div>
            )}

            {urgencyVisible && (
            <div className='md:w-[396px] w-[200px] lg:px-16 absolute top-14 right-0 bg-white p-6 shadow-lg rounded-lg border'>
                <form>
                    <p className='font-semibold mb-5 text-lg text-center'>Urgenza</p>
                    <div className='mb-4 text-center graveBox'>
                        <input type='radio' name="urgency" id='grave' className='hidden'/>
                        <label for="grave" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#F04438] font-semibold text-base'>Grave</label>
                    </div>
                    <div  className='mb-4 text-center mediaBox'>
                        <input type='radio' name="urgency" id='media' className='hidden'/>
                        <label for="media" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#FFC885] font-semibold text-base'>Media</label>
                    </div>
                    <div  className='mb-4 text-center bassaBox'>
                        <input type='radio' name="urgency" id='bassa' className='hidden'/>
                        <label for="bassa" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#86D8B0] font-semibold text-base'>Bassa</label>
                    </div>
                    <div className='mb-4 mt-8 text-center'>
                        <button className=' w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Mostra</button>
                    </div>
                    <div className='text-center mb-4'>
                        <button onClick={urgencyClose} type='button' className='text-[#828DEE] underline font-semibold'>Annulla</button>
                    </div>
                </form>
            </div>
            )}
        </div>
    </>
  )
}

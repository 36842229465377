import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FormControlLabel, Grid, Switch, TextField, CircularProgress, Alert } from '@mui/material'
import { styled } from '@mui/material/styles';
import PatientDetailNav from './PatientDetailNav'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAuthStore from "../../hooks/useAuthStore";
import dayjs from 'dayjs';
import axios from 'axios';
import { Constants } from '../../endPoints'
import Swal from 'sweetalert2'; // Import SweetAlert2

export default function EditInfo() {
    const navigate = useNavigate();
    const { idPatient } = useParams();
    const { setToken, token, setId } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const pediatricianDetails = JSON.parse(localStorage.getItem('user'));

    const [patient, setPatient] = useState(null); // Patient details
    const [parents, setParent] = useState(null); // Parent details
    const [loading, setLoading] = useState(false); // API loading state
    const [error, setError] = useState(''); // API error state
    const [isSSN, setIsSSN] = useState(false); // State for the switch

    // Fetch patient data
    const getPatient = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${Constants.getPediatricianPatient}`,
                {
                    idPatient,
                    idPediatrician,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Correctly positioned headers
                    },
                }
            );
            const patientData = response?.data?.patient || {};
            setPatient(patientData);
            setParent(patientData?.parents); // Assuming `parent` is part of the response
        } catch (err) {
            setError('Failed to load patient details. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatient();
    }, []);

    useEffect(() => {
        // Update the state based on the `type` field
        if (patient?.type === "SSN") {
            setIsSSN(true); // SSN is checked
        } else if (patient?.type === "PRIVATE") {
            setIsSSN(false); // PRIVATE is checked
        }
    }, [patient]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="info">No patient data available.</Alert>
            </div>
        );
    }

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#86D8B0',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#86D8B0',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#828DEE',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#828DEE',
            }),
        },
    }));


    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div className='md:p-8 p-5 h-full'>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/patients"><i class="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti </Link>
                                <span>/</span>
                                <span>{patient?.firstName} {patient?.lastName}</span>
                            </div>
                            <PatientDetailNav patient={patient} />
                            <div className='flex mt-5 mb-5'>
                                <Link to={`/patient-details/${patient.id}`} className="px-6 py-3 text-[#828DEE] border-t rounded-tr border-[#C5C8C9] border-r "><i className="fa-regular fa-user mr-2"></i> Anagrafica</Link>
                                <Link to={`/patient-medical-record/${patient.id}`} className="px-6 py-3 text-[#72777A] border-b rounded-bl border-[#C5C8C9] grow"><i className="fa-regular fa-square-list  mr-2"></i> Cartella clinica</Link>
                            </div>
                            <div className='pt-5'>
                                <Grid container spacing={5}>
                                    <Grid item lg={6} md={6} xs={12} >
                                        <form action={`/patient-details/${patient.id}`}>
                                            <div className='flex justify-between  mb-5'>
                                                <div className='pr-10 order-2'>
                                                    <button to="" className="text-sm underline italic text-[#828DEE]">salva</button>
                                                </div>
                                                <div className="flex gap-3 items-center">
                                                    <div className="font-semibold">SSN</div>
                                                    <FormControlLabel
                                                        style={{ margin: "0" }}
                                                        control={
                                                            <IOSSwitch
                                                                sx={{ m: 0 }}
                                                                checked={!isSSN}
                                                                onChange={(event) => setIsSSN(!event.target.checked)}
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <div className="font-semibold">PRIVATO</div>
                                                </div>
                                            </div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={11}>
                                                    <div className='mb-3'>
                                                        <TextField variant="outlined" label="Nome e Cognome" className='w-full' defaultValue={`${patient?.firstName || ''} ${patient?.lastName || ''}`} />
                                                    </div>
                                                    <div className="mb-4">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker
                                                                    label="Data di nascita"
                                                                    className="w-full"
                                                                    defaultValue={patient?.dateOfBirth ? dayjs(patient.dateOfBirth) : null}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <TextField variant="outlined" label="Luogo di nascita" className='w-full' defaultValue={patient?.placeOfBirth || ''} />
                                                    </div>
                                                    <div className='mb-4'>
                                                        <TextField variant="outlined" label="Numero Tessera Sanitaria" className='w-full' defaultValue="" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <TextField
                                                            variant="outlined"
                                                            label="Indirizzo residenza"
                                                            className="w-full"
                                                            defaultValue={
                                                                patient?.parents?.[0]?.addresses
                                                                    ?.find((address) => address.type === 'Residence')
                                                                    ? `${patient.parents[0].addresses.find((address) => address.type === 'Residence').street || ''}, 
               ${patient.parents[0].addresses.find((address) => address.type === 'Residence').cityName || ''}, 
               ${patient.parents[0].addresses.find((address) => address.type === 'Residence').provinceName || ''}, 
               ${patient.parents[0].addresses.find((address) => address.type === 'Residence').zip || ''}`
                                                                        .replace(/,\s*,/g, ', ')
                                                                    : ''
                                                            }
                                                        />


                                                    </div>
                                                    <div className='mb-4'>
                                                        <p>Documento di identità</p>
                                                        <a href={patient.identityCardFront} className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png' /> Carta di identità</a>
                                                    </div>
                                                    <div className='text-center mt-10'>
                                                        <button type='button' className='underline text-[#828DEE] font-semibold'>Aggiungi esenzione</button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12} className="md:border-l border-[#C5C8C9]">
                                        <p>Dati del Genitore</p>
                                        {parents.length > 0 ? (
                                            parents.map((parent, index) => (
                                                <div key={parent.idParent} className={`my-5 ${parents.length > 1 && index < parents.length - 1 ? 'border-bottom-1 mt-4' : ''
                                                    }`}>
                                                    <div className="flex items-center gap-4 my-5">
                                                        <div>
                                                            <img
                                                                src={parent.photo || '../assets/images/default-profile.png'}
                                                                alt="Parent"
                                                                className="w-20 h-20 object-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h2>Nome e Cognome</h2>
                                                            <p className="text-2xl font-semibold">{`${parent.firstName} ${parent.lastName}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <p>Data e Luogo di nascita</p>
                                                        <h4 className='text-2xl font-semibold'>{parent?.dateOfBirth} - {parent?.placeOfBirth}</h4>
                                                    </div>

                                                    <div className="mb-3">
                                                        <p>Codice Fiscale</p>
                                                        <h4 className="text-2xl font-semibold">{parent.taxCode}</h4>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <p>Documento di identità</p>
                                                        <a href={parent?.identityCardFront} className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png' /> Carta di identità</a>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No parent details available.</p>
                                        )}
                                    </Grid>
                                    {/* <Grid item lg={6} md={6} xs={12} className='md:border-l border-[#C5C8C9]'>
                                <p>Dati del Genitore</p>
                                <div className='flex items-center gap-4 my-5'>
                                    <div>
                                        <img src='../assets/images/user.png' className='w-20 h-20 object-cover rounded-full'/>
                                    </div>
                                    <div>
                                        <h2>Nome e Cognome</h2>
                                        <p className='text-2xl font-semibold '>Maria Bianchi</p>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <p>Data e Luogo di nascita</p>
                                    <h4 className='text-2xl font-semibold'>19/09/1987 - Potenza</h4>
                                </div>
                                <div className='mb-3'>
                                    <p>Codice Fiscale</p>
                                    <h4 className='text-2xl font-semibold'>NSQFJQ76S61B137S</h4>
                                </div>
                                <div className='mb-3'>
                                    <p>Documento di identità</p>
                                    <button className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png'/> Carta di identità</button>
                                </div>
                            </Grid> */}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

import React, { useState, useEffect, useContext } from 'react'
import { Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Constants } from '../../endPoints';
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useAuthStore from "../../hooks/useAuthStore";


const SignUp = ({ handleNext, setMeta }) => {
    const navigate = useNavigate(); // Initialize navigate from useNavigate
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [apiError, setApiError] = useState(''); // State to hold API error
    const [showLogin, setshowLogin] = useState(false)
    const [showInvitationButton, setShowInvitationButton] = useState(false)
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
        privacy: false,
    })

    const { setToken, token, setId } = useAuthStore();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

    const [createAccLoading, setCreateAccLoading] = useState(false);
    const signupFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            termsAccepted: false,
            privacyPolicyAccepted: false,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Indirizzo e-mail non valido')
                .required(`L'e-mail è obbligatoria`)
                .test('validate-email', `L'e-mail è già in uso`, async function (value) {
                    if (value) {
                        const isValid = await validateEmail(value);
                        return isValid; // true if valid, false if not
                    }
                    return false;
                }),
            password: Yup.string()
                .min(8, `La password deve contenere almeno 8 caratteri`)
                .matches(/[A-Z]/, 'La password deve contenere almeno una lettera maiuscola')
                .matches(/[0-9]/, 'La password deve contenere almeno un numero')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'La password deve contenere almeno un carattere speciale')
                .required(`È richiesta la password`),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Le password devono essere identiche')
                .required('È richiesta la conferma della password'),
            termsAccepted: Yup.boolean()
                .oneOf([true], 'È necessario accettare i Termini e Condizioni'),
            privacyPolicyAccepted: Yup.boolean()
                .oneOf([true], `È necessario accettare l'Informativa sulla privacy`),
        }),
        onSubmit: async (values) => {
            // Handle form submission here
            await performSignup(values);

        },
    });


    const validateEmail = async (email) => {
        try {
            const response = await axios.post(Constants.checkEmail, {
                email: email
            })
            if (response.status === 200) {
                if (response.data.message === 'Email is already registered.') {
                    return false; // Email already exists
                }
                return true; // Mobile number is valid
            } else {
                return false; // Mobile number is invalid
            }
        } catch (error) {
            console.log(error)
            return false; // Mobile number is invalid
        }

    }

    const performSignup = async (values) => {
        setCreateAccLoading(true)
        try {
            const body = { ...values, userType: "Pediatrician" }
            const response = await axios.post(Constants.PublicUserRegisterV2, body)
            console.log("Creation response", response)
            if (response.status === 201 || response.status === 200) {
                console.log('Signup successful', response && response.data)
                localStorage.setItem('userId', response.data.id)
                setMeta({
                    ...values, id: response.data.id,
                    emailOtp: response.data.emailOtp
                })
                setToken(response.data.accessToken)
                handleNext()
            } else {
                console.error('Signup failed')
            }
        } catch (error) {
            console.error('Error in signup:', error)
            if (error.response && error.response.data) {
                if (error.response.data.message.includes('Email is already registered')) {
                    setshowLogin(true)
                }
                console.log("MESSAGE", error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error occurred. Please try again later.')
            }
        } finally {
            setCreateAccLoading(false)
        }

    }


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    return (
        <><Grid item xl={6} lg={7} md={8} xs={12}>
            <h2 className='mb-8 text-3xl font-semibold text-center'>Crea Account</h2>
            <form onSubmit={signupFormik.handleSubmit}>
                <div className='mb-5'>
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        className='w-full rounded-lg'
                        name="email"
                        value={signupFormik.values.email}
                        onChange={signupFormik.handleChange}
                        onBlur={signupFormik.handleBlur}
                        error={signupFormik.touched.email && Boolean(signupFormik.errors.email)}
                        // helperText={signupFormik.touched.email && signupFormik.errors.email}
                    />
                    {signupFormik.touched.email && signupFormik.errors.email && (
                        <div className="text-red-500 text-sm">{signupFormik.errors.email}</div>
                    )}
                </div>
                <div className='mb-5'>
                    <FormControl variant="outlined" className='w-full'>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={signupFormik.values.password}
                            onChange={signupFormik.handleChange}
                            onBlur={signupFormik.handleBlur}
                            error={signupFormik.touched.password && Boolean(signupFormik.errors.password)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((prev) => !prev)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {signupFormik.touched.password && signupFormik.errors.password && (
                            <div className="text-red-500 text-sm">{signupFormik.errors.password}</div>
                        )}
                    </FormControl>
                </div>
                <div className='mb-5'>
                    <FormControl variant="outlined" className='w-full'>
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Conferma Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-confirm-password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            value={signupFormik.values.confirmPassword}
                            onChange={signupFormik.handleChange}
                            onBlur={signupFormik.handleBlur}
                            error={signupFormik.touched.confirmPassword && Boolean(signupFormik.errors.confirmPassword)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Conferma password"
                        />
                        {signupFormik.touched.confirmPassword && signupFormik.errors.confirmPassword && (
                            <div className="text-red-500 text-sm">{signupFormik.errors.confirmPassword}</div>
                        )}
                    </FormControl>
                </div>
                <div className='mb-3'>
                    <label className='text-sm flex items-center'>
                        <span>
                            <Checkbox
                                name="termsAccepted"
                                checked={signupFormik.values.termsAccepted}
                                onChange={signupFormik.handleChange}
                                onBlur={signupFormik.handleBlur}
                                sx={{
                                    color: signupFormik.touched.termsAccepted && signupFormik.errors.termsAccepted ? 'red' : '',
                                    '&.Mui-checked': {
                                        color: signupFormik.touched.termsAccepted && signupFormik.errors.termsAccepted ? 'red' : '#828DEE',
                                    },
                                }}
                            />
                        </span>
                        <span>
                            Prendo visione e accetto i <Link to="" className="text-[#828DEE] underline">Termini e Condizioni</Link> del servizio
                        </span>
                    </label>
                </div>
                <div className='mb-5'>
                    <label className='text-sm flex items-center'>
                        <span>
                            <Checkbox
                                name="privacyPolicyAccepted"
                                checked={signupFormik.values.privacyPolicyAccepted}
                                onChange={signupFormik.handleChange}
                                onBlur={signupFormik.handleBlur}
                                sx={{
                                    color: signupFormik.touched.privacyPolicyAccepted && signupFormik.errors.privacyPolicyAccepted ? 'red' : '',
                                    '&.Mui-checked': {
                                        color: signupFormik.touched.privacyPolicyAccepted && signupFormik.errors.privacyPolicyAccepted ? 'red' : '#828DEE',
                                    },
                                }}
                            />
                        </span>
                        <span>
                            Prendo visione e accetto le <Link to="" className="text-[#828DEE] underline">Privacy Policy</Link> del servizio
                        </span>
                    </label>
                    {apiError && <div className="text-red-500 text-sm">{apiError}</div>}
                </div>
                <div className='mb-5'>
                    {
                        createAccLoading ? <CircularProgress /> : <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>
                            Crea account
                        </button>
                    }
                </div>
                <div className='mb-5'>
                    {!showLogin && <div className='mb-5 text-center'>
                        <Link to="/login" className="text-[#828DEE] font-semibold underline">Vai al login</Link>
                    </div>}
                    {showLogin && <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all' onClick={(e) => {
                        navigate('/login')
                    }}>
                        login
                    </button>}
                </div>
            </form>

        </Grid></>
    )

};


export default SignUp;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom';
import CommunityList from './CommunityList'
// import PatientCall from '../Call/PatientCall'
import { Constants } from '../../endPoints';
import axios from 'axios'
import useAuthStore from '../../hooks/useAuthStore';

export default function Dashboard() {
    const { token } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');

    const [apiError, setApiError] = useState(''); // State to hold API error
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [dashboardData, setDashboardData] = useState({
        totalNew: 0,
        totalOpen: 0,
        todayTotalBooking: 0
    });

    
    
    useEffect(() => {
        sessionStorage.removeItem('meta');
        
        const fetchDashboardData = async () => {
            setLoading(true);
            try {
                const response = await axios.post(Constants.pediatricianDashboardCounter, {
                    idPediatrician: idPediatrician
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.data && response.data.status === 1) {
                    const { totalNew, totalOpen, todayTotalBooking } = response.data;
                    setDashboardData({ totalNew, totalOpen, todayTotalBooking });
                } else {
                    setApiError('Failed to fetch dashboard data');
                }
            } catch (error) {
                setApiError('An error occurred while fetching the dashboard data');
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, [token, idPediatrician]);

    return (
        <>
            <section>
                <div className='2xl:container mx-auto'>
                    <Grid container columns={12} spacing={2}>
                        <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                            <div>
                                <div className='mb-10'>
                                    <div className='flex justify-between items-center mb-10'>
                                        <div><img src='assets/images/logo-colored.png' className='w-44' alt='wait...' /></div>
                                        <div>
                                            <Link to="/notification" className='relative text-xl inline-block border py-1 px-4 rounded-lg border border-[#C5C8C9]'>
                                            <i className="fa-regular fa-bell mr-2 text-green-500"></i>
                                                <span className="font-bold">
                                                    {dashboardData.totalNew > 0 || dashboardData.totalOpen > 0 
                                                        ? (dashboardData.totalNew || 0) + (dashboardData.totalOpen || 0) 
                                                        : ''}
                                                </span>

                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <Grid container columns={12} spacing={4}>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white realtive'>
                                                <div className='bg-[#FFC885] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-01.png' className='mx-auto h-16' alt='wait...' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg font-bold'>{dashboardData.todayTotalBooking > 0 &&  dashboardData.todayTotalBooking} Calendario <br /> visite</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                                <div className='bg-[#FFC885] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-02.png' className='mx-auto h-16' alt='wait...' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>{dashboardData.totalNew > 0 &&  dashboardData.totalNew} <br /> Non evase</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                                <div className='bg-[#AAB5FF] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-03.png' className='mx-auto h-16' alt='wait...' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>{dashboardData.totalOpen > 0 &&  dashboardData.totalOpen} <br /> Casi aperti</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <Link to='/patients'>
                                            
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <div className='bg-[#86D8B0] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-04.png' className='mx-auto h-16' alt='wait...' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>Ricerca <br />  pazienti</h2>
                                                </div>
                                            </div>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <h3 className='md:text-3xl text-2xl mb-2 font-bold'>Advice from the community</h3>
                                    <CommunityList />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
          {/* Uncomment to   Turn on Video Call Pop Up */}
            {/* <PatientCall /> */}
        </>
    )
}

import { Checkbox, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Blockedlist from './Blockedlist';
import { useUser } from '../../hooks/useUserContext';
import useAuthStore from '../../hooks/useAuthStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Constants } from '../../endPoints';

export default function BlacklistSos() {
    const { user } = useUser();
    const { token } = useAuthStore();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [guardians, setGuardians] = useState([]);
    const [selectedGuardians, setSelectedGuardians] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const handleSearchChange = async (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        if (!term) {
            setSearchResults([]);
            return;
        }

        try {
            const response = await axios.get(`${Constants.pediatricianSearchParent}/${user.id}?name=${term}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (response.data && response.data.status === 1) {
                setSearchResults(response.data.patients || []);
            } else {
                setSearchResults([]);
            }
        } catch (error) {
            console.error('Error fetching patients:', error);
            setSearchResults([]);
        }
    };
    const handleSelectPatient = async (patient) => {
        setSearchTerm(`${patient.firstName} ${patient.lastName}`);
        setSearchResults([]);
        setSelectedPatient(patient);
        setGuardians([]);
        setSelectedGuardians([]);
    
        // Fetch the guardians for the selected patient
        try {
            const response = await axios.get(`${Constants.patientWiseParent}/${patient.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data && response.data.status === 1) {
                setGuardians(response.data.parentList || []);
            } else {
                setGuardians([]);
            }
        } catch (error) {
            console.error('Error fetching guardians:', error);
            setGuardians([]);
        }
    };
    

    const handleGuardianCheckbox = (parentId) => {
        setSelectedGuardians((prev) => {
            if (prev.includes(parentId)) {
                return prev.filter((id) => id !== parentId);
            } else {
                return [...prev, parentId];
            }
        });
    };

    const handleBlock = async () => {
        if (!selectedPatient || selectedGuardians.length === 0) return;
    
        Swal.fire({
            title: 'Sei sicuro?',
            text: "Vuoi davvero bloccare i genitori selezionati?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, blocca!',
            cancelButtonText: 'Annulla'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const payload = {
                    idPediatrician: user.id,
                    idPatient: selectedPatient.id,
                    idParents: selectedGuardians
                };
    
                try {
                    const response = await axios.post(Constants.blockParent , payload, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
    
                    if (response.data && response.data.status === 1) {
                        Swal.fire(
                            'Bloccati!',
                            'I genitori sono stati bloccati con successo.',
                            'success'
                        );
                        // Clear data after success
                        setSelectedPatient(null);
                        setGuardians([]);
                        setSelectedGuardians([]);
                        setRefreshKey(prevKey => prevKey + 1);
                    } else {
                        // Handle unexpected successful responses with status !== 1
                        Swal.fire(
                            'Errore!',
                            'Si è verificato un errore nel bloccare i genitori.',
                            'error'
                        );
                    }
                } catch (error) {
                    // Check if the error response exists
                    if (error.response && error.response.data) {
                        const { status, message } = error.response.data;
    
                        if (status === 0 && message.includes("already in the blacklist")) {
                            Swal.fire(
                                'Già Bloccato!',
                                'Uno o più genitori selezionati sono già nella lista bloccata.',
                                'info'
                            );
                        } else {
                            Swal.fire(
                                'Errore!',
                                'Si è verificato un errore nel bloccare i genitori.',
                                'error'
                            );
                        }
                    } else {
                        // If there's no response from the server
                        Swal.fire(
                            'Errore!',
                            'Si è verificato un errore nel bloccare i genitori.',
                            'error'
                        );
                    }
    
                    console.error('Error blocking guardians:', error);
                }
            }
        });
    };

    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/sos-management"><i className="fa-solid fa-angle-left mr-2"></i> Notifiche </Link>
                                <span>/</span>
                                <span>Blacklist SOS</span>
                            </div>
                            <div className='mt-10'>
                                <Grid container spacing={0} className='justify-center'>
                                    <Grid item lg={6} md={12} xs={12}>
                                        <div className='lg:px-14 mb-10'>
                                            <div className='text-center text-5xl mb-8'><i className="fa-regular fa-ban"></i></div>
                                            <p>Individua il paziente e seleziona il genitore da bloccare per la funzionalità SOS.</p>
                                            <div className="w-full mt-8 relative">
                                                <div className="mb-0 relative">
                                                    <TextField 
                                                        fullWidth 
                                                        label="Paziente" 
                                                        variant="outlined" 
                                                        value={searchTerm}
                                                        onChange={handleSearchChange} 
                                                    />
                                                    {searchTerm && (
                                                                 <button
                                                                   onClick={() => {
                                                                    setSearchTerm('');
                                                                     setSearchResults([]);
                                                                      setSelectedPatient(null);
                                                                     setGuardians([]);
                                                                     setSelectedGuardians([]);
                                                                      }}
                                                                   className="text-gray-500 hover:text-gray-700 text-lg absolute right-4 top-4"
                                                                  >
                                                             <i className="fa-sharp fa-regular fa-xmark"></i>
                                                               </button>
                                                              )}
                                                </div>
                                                {searchTerm && searchResults.length > 0 && (
                                                    <ul className="space-y-1 border border-[#c0c0c0] rounded p-1 absolute w-full top-15 z-[99] bg-white max-h-[250px] overflow-auto">
                                                        {searchResults.map((patient) => (
                                                            <li
                                                                key={patient.id}
                                                                onClick={() => handleSelectPatient(patient)}
                                                                className="flex items-center px-2 py-1 rounded-lg hover:bg-gray-100 cursor-pointer"
                                                            >
                                                                <img
                                                                    src={patient.photo || 'assets/images/user.png'}
                                                                    alt={`${patient.firstName} ${patient.lastName}`}
                                                                    className="w-8 h-8 rounded-full mr-3 object-cover"
                                                                />
                                                                <span className="text-gray-700">{patient.firstName} {patient.lastName}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            {selectedPatient && (
                                                <div className='mt-5'>
                                                    <div className='flex gap-4 mb-2 py-3 items-center'>
                                                        <div>
                                                            <img src={selectedPatient.photo || "assets/images/user.png"} className='w-[42px] h-[42px] rounded-full object-cover' alt="patient" />
                                                        </div>
                                                        <div className='flex gap-3 justify-between grow'>
                                                            <h3 className='font-semibold'>{selectedPatient.firstName} {selectedPatient.lastName}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedPatient && (
                                                <div>
                                                    <h3 className='text-lg font-semibold'>Tutori</h3>
                                                </div>
                                            )}
                                            {selectedPatient && guardians.length > 0 && (
                                                <div className='mt-5'>
                                                    {guardians.map((guardian) => (
                                                        <div className='flex gap-4 mb-4 items-center' key={guardian.id}>
                                                            <div>
                                                                <img src={guardian.photo} className='w-[42px] h-[42px] rounded-full object-cover' alt="guardian" />
                                                            </div>
                                                            <div className='flex gap-3 justify-between grow items-center'>
                                                                <h3>{guardian.firstName} {guardian.lastName}</h3>
                                                                <div>
                                                                    <Checkbox 
                                                                        checked={selectedGuardians.includes(guardian.id)}
                                                                        onChange={() => handleGuardianCheckbox(guardian.id)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className='mt-10 text-center'>
                                                        <button 
                                                            className='w-48 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                                                            onClick={handleBlock}
                                                        >
                                                            Blocca
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} xs={12} className='lg:border-l'>
                                        <div className='lg:px-14'>
                                            <div className='text-center text-5xl mb-8'><i className="fa-sharp fa-light fa-hand"></i></div>
                                            <div className='text-center mb-8'>
                                                <h3 className='mb-2 font-semibold text-2xl'>Lista Bloccati</h3>
                                                <p>Seleziona gli utenti da sbloccare</p>
                                            </div>
                                            <div className='max-h-[550px] overflow-auto p-2'>
                                                <Blockedlist key={refreshKey} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    TextField,
    CircularProgress,
  } from "@mui/material";
  import { Container, Button } from "react-bootstrap";
  import PaginationComponent from "../../utils/pagination";
  import React, { useEffect, useState, useRef } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import DashboardNav from "../Dashboard/DashboardNav";
  import { FaTrash } from "react-icons/fa";
  import axios from "axios";
  import { Constants } from "../../endPoints";
  import { useUser } from "../../hooks/useUserContext";
  import useAuthStore from "../../hooks/useAuthStore";
  import Swal from "sweetalert2";
  import withReactContent from "sweetalert2-react-content";
  import Pagination from "@mui/material/Pagination";
  import Stack from "@mui/material/Stack";
  
  const MySwal = withReactContent(Swal);
  
  export default function CertificateList() {
    const navigate = useNavigate();
    const { user } = useUser();
    console.log(user.id);
    const { setToken, token, setId } = useAuthStore();
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCertificates, setTotalCertificates] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
  
    const fetchCertificateList = async (userId) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${Constants.UsercertificateList}?page=${currentPage}`, // URL with query params
          { idPediatrician: user.id }, // Payload includes idPediatrician
          {
            headers: {
              Authorization: `Bearer ${token}`, // Correctly positioned headers
            },
          }
        );
        
        const { data } = response.data; // Extract the 'data' object from the response
        console.log(data);
        
        setCertificates(data.items || []);
        setTotalPages(data.totalPages || 1);
        setTotalCertificates(data.totalItems || 0); // Set totalCertificates based on totalItems
        setCurrentPage(data.currentPage || 1); // Set currentPage based on API response
        // itemsPerPage remains fixed at 10
      } catch (error) {
        console.error("Error fetching pediatrician details:", error);
        setApiError("Failed to retrieve pediatrician details.");
      } finally {
        setLoading(false);
      }
    };
  
    const handlePageChange = (event, value) => {
      if (!loading) {
        setCurrentPage(value);
      }
    };
  
    const handleDeleteCertificate = async (certId) => {
      try {
        const response = await fetch(`${Constants.deleteCertificate}/${certId}`, {
          method: "DELETE",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            "X-Accessed-By": "Admin",
          }),
        });
        if (response.ok) {
          // Remove the deleted certificate from the local state
          setCertificates(certificates.filter((cert) => cert.id !== certId));
          setDeleteTrigger((prev) => !prev);
          MySwal.fire({
            icon: "success",
            title: "Certificate Deleted!",
            text: "The certificate has been successfully deleted.",
          });
        } else {
          console.error("Failed to delete certificate");
          MySwal.fire({
            icon: "error",
            title: "Deletion Failed",
            text: "Unable to delete the certificate. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error deleting certificate:", error);
        MySwal.fire({
          icon: "error",
          title: "Deletion Error",
          text: "An error occurred while deleting the certificate.",
        });
      }
    };
    const handleToggleEnabled = async (event, certificate) => {
      const newEnabledFlag = event.target.checked;
  
      const payload = {
        idPediatrician: certificate.pediatricianId,
        categoryId: certificate.categoryId,
        enabledFlag: newEnabledFlag,
      };
  
      try {
        const response = await axios.put(
          `${Constants.certificateList}/${certificate.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Accessed-By': 'Admin',
            },
          }
        );
  
        if (response.status === 200 || response.status === 201) {
          setCertificates((prevCertificates) =>
            prevCertificates.map((cert) =>
              cert.id === certificate.id
                ? { ...cert, enabled: newEnabledFlag }
                : cert
            )
          );
          MySwal.fire({
            icon: 'success',
            title: 'Certificate Status Updated!',
            // text: 'The certificate status has been successfully updated.',
          });
        } else {
          console.error('Failed to update certificate');
          MySwal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'Unable to update the certificate. Please try again.',
          });
        }
      } catch (error) {
        console.error('Error updating certificate:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Update Error',
          text: 'An error occurred while updating the certificate.',
        });
      }
    };
  
  
    useEffect(() => {
      // Ensure that user.id is available before fetching
      if (user && user.id) {
        fetchCertificateList(user.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteTrigger, currentPage, user.id]);
  
    return (
      <>
        <div className="flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs">
          <Link to="/certificates">
            <i className="fa-solid fa-angle-left mr-2"></i> Lista certificati{" "}
          </Link>
        </div>
  
        <div className="mt-10 text-center">
          <div className="mb-8">
            <h2>
              <span className="inline-block border-4 text-4xl w-14 h-14 py-1 border-black rounded-lg">
                <i className="fa-solid fa-bars-sort"></i>
              </span>
            </h2>
            <p className="mt-8 text-base">
              Inserisci la tipologia di certificati.
            </p>
            <button
              className="text-sm text-[#828DEE] underline"
              onClick={() => navigate("/certificate-requests")}
            >
              Richiedi certificato
            </button>
          </div>
         
          <div className="overflow-x-auto">
            <table className="w-full lg:text-base text-sm tablecustom">
              <thead className="bg-[#FFC885]">
                <tr>
                  <th className="text-left">Nome del certificato</th>
                  <th>Tipologia</th>
                  <th>Costo</th>
                 
                  <th>Abilitato</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <CircularProgress />
                    </td>
                  </tr>
                ) : (
                  <>
                    {certificates.length === 0 && (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No certificates found
                        </td>
                      </tr>
                    )}
                    {certificates.map((certificate) => (
                      <tr key={certificate.id}>
                        <td
                          className="text-left font-semibold"
                          onClick={() => navigate(`/view-certificate/${certificate.id}`)}
                          style={{ cursor: "pointer" }}
                        >
                          {certificate.certificateName}
                        </td>
                        <td>{certificate.category}</td>
                        <td>{certificate.cost ? certificate.cost : "0.00"}</td>

                        <td>
                          <Checkbox checked={certificate.enabled} onChange={(event) => handleToggleEnabled(event, certificate)} />
                        </td>
                        <td>
                    
                          
                          <button
                            className="text-sm text-[#828DEE] underline"
                            onClick={() => navigate(`/edit-certificate/${certificate.id}`)}
                          >
                            Modifica
                          </button>
                          <button className="text-red-500 ml-3"
                          title="Delete Certificate"
                          onClick={() => {
                            MySwal.fire({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this certificate!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Yes, delete it!",
                              cancelButtonText: "No, cancel!",
                              reverseButtons: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDeleteCertificate(certificate.id);
                              }
                            });
                          }}><i class="fa-regular fa-trash"></i></button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            <div className="mt-3">
            <Grid container spacing={3} className="items-center">
              <Grid item lg={6}>
                <div>
                  <Stack spacing={2}>
                    <Pagination 
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      disabled={loading} // Disable while loading
                    />
                  </Stack>
                </div>
              </Grid>
              <Grid item lg={6}>
                <div>
                  <div className="dataTables_length text-right text-sm text-gray-600">
                  Mostrando da  {(currentPage - 1) * itemsPerPage + 1} a {" "}
                    {Math.min(currentPage * itemsPerPage, totalCertificates)} di{" "}
                    {totalCertificates} voci
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
            <div className="text-center mt-6 mb-2">
            <button
              className="font-semibold text-gray-500 rounded-lg border border-gray-400 border-dashed w-full py-3"
              onClick={() => navigate("/add-certificate")}
            >
              + Aggiungi certificato
            </button>
          </div>
          </div>
          
          {/* Display API Error */}
          {apiError && (
            <div className="text-center text-red-500 mt-4">
              {apiError}
            </div>
          )}
        </div>
      </>
    );
  }
  
import React, { useState, useEffect, useContext } from 'react'
import { Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom' // Import useNavigate from react-router-dom
import { useFormik } from 'formik';
import { LanguageContext } from '../../LanguageContext'; // Adjust the path if necessary
import useAuthStore from "../../hooks/useAuthStore";
import UserDetailsInfo from "../Profile/UserDetails";
import { MobileOTP, TwoFactorAuth } from "../Profile/MobileVerification";
import SignUp from './Signup';
import { EmailConfirmation, EmailConfirmOTP, EmailConfirmationComplete } from "../Profile/EmailVerification";
import InvitationCode from './InvitationCode';
import { Constants } from '../../endPoints';
import axios from 'axios'


export default function MultiStepRegister() {
  // Query Parameters ------------------------------ 
  // Parse query parameters using URLSearchParams
  const { token } = useAuthStore();
  const navigate = useNavigate() // Initialize navigate from useNavigate
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loginResponse = location.state?.data;
  const [loading, setLoading] = useState(true);


  const { language } = useContext(LanguageContext); // Get the current language from the context
  const languageId = language === 'Italian' ? 1 : 2 // Determine the languageId based on the current language
  const backgroundImageUrl = 'assets/images/leftBg.png';

  const getInitialMeta = () => {
    const savedMeta = sessionStorage.getItem('meta');
    const initialMeta = { isEmailVerified: false, isInvitationCodeCreated: false, isPhoneVerified: false, isProfileCompleted: false, email: '', id: null, phone: '', update: false }
    return savedMeta ? JSON.parse(savedMeta) : initialMeta; // Default to an empty object if no saved value
  };
  // User Login related info Goes here
  const [meta, setMeta] = useState(getInitialMeta)

  // Initialize the step state from localStorage if available
  const getInitialStep = () => {
    const savedStep = sessionStorage.getItem('step');
    return savedStep ? parseInt(savedStep, 10) : 1; // Default to step 1 if no saved value
  }

  const [step, setStep] = useState(getInitialStep)
  useEffect(() => {
    // Set initial values of Step here by fetching USer info
    fetchUserInfo();
  }, [])


  // New function to update step and save to localStorage
  const updateStep = (newStep) => {
    setStep(newStep);
    sessionStorage.setItem('step', newStep); // Save step in localStorage
  };

  // New function to update meta and save to localStorage
  const updateMeta = (newMeta) => {
    setMeta((prevMeta) => {
      const updatedMeta = { ...prevMeta, ...newMeta }; // Merge previous state with newMeta
      sessionStorage.setItem('meta', JSON.stringify(updatedMeta)); // Save meta in sessionStorage
      return updatedMeta; // Return the updated meta state
    });
  };


  const handleNext = async () => {
    // Merge the current step values into formValues
    updateStep(step + 1) // Move to the next step
  }

  const fetchUserInfo = async () => {
    setLoading(true)
    try {
      // console.log("Login Response", loginResponse)
      const { isEmailVerified, isPhoneVerified, isInvitationCodeCreated, isProfileCompleted, email, id, phone, update } = loginResponse || meta
      updateMeta({ ...meta, isEmailVerified, isPhoneVerified, isInvitationCodeCreated, isProfileCompleted, email, id, phone, update });

      console.log("loginResponse = ", loginResponse);

      if (token && !location.state?.fromLogin && loginResponse.isProfileCompleted===true) {
        navigate('/dashboard')
      }

      if (!isProfileCompleted && !update) {
        updateStep(6)
      }

      if (!isPhoneVerified) {
        updateStep(4)
      }

      if (!isEmailVerified) {
        updateStep(2)
      }

      if (!id) {
        updateStep(1)
      }
    } catch (error) {
      console.error('Error :', error)
    } finally {
      setLoading(false)
    }
  }


  return (<>
    <section className='bgleft relative min-h-screen'>
      <div className=' mx-auto min-h-screen align-items-center'>
        <Grid container columns={12} spacing={0} className=' overflow-hidden h-full min-h-screen '>
          <Grid item lg={6} sm={12} xs={12} order={{ lg: 2 }} className='table'>
            <div className='align-middle table-cell py-10  px-6'>
              <Grid container spacing={3} className='justify-center'>
                {loading ? (
                  <CircularProgress />
                ) : (<>
                  {step === 1 && <SignUp handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                  {/* {step === 2 && <EmailConfirmation handleNext={handleNext} meta={meta} setMeta={updateMeta} />} */}
                  {step === 2 && <EmailConfirmOTP handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                  {step === 3 && <EmailConfirmationComplete handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                  {step === 4 && <TwoFactorAuth handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                  {step === 5 && <MobileOTP handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                  {/* {step === 7 && <InvitationCode handleNext={handleNext} meta={meta} setMeta={updateMeta} />} */}
                  {step === 6 && <UserDetailsInfo handleNext={handleNext} meta={meta} setMeta={updateMeta} />}
                </>)}
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6} sm={12} xs={12} style={{ backgroundImage: `url(${backgroundImageUrl})` }} className='bg-cover bg-right-top table'>
            <div className='align-middle table-cell py-10 relative'>
              <Grid container spacing={3} className='justify-center'>
                <Grid item lg={6}>
                  <div className='text-center'>
                    <img src='assets/images/logo-white.png' className='mx-auto h-14' />
                    <p className='my-4 text-white font-semibold'>La linea diretta con i tuoi pazienti</p>
                  </div>
                </Grid>
              </Grid>
              <div className='text-center md:absolute w-full bottom-20'>
                <p>Hai qualche dubbio? </p>
                <p className='font-semibold'>Visita il nostro sito <Link to="#" className="underline">kiddocare.com</Link> </p>
              </div>
            </div>
          </Grid>

        </Grid>
      </div>
    </section>

  </>)
}

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material'
import ChatSidebar from './ChatSidebar'
import useAuthStore from '../../hooks/useAuthStore';
import axios from 'axios';
import { Constants } from '../../endPoints';
import Swal from 'sweetalert2';
import WebIM from 'agora-chat';


export default function ChatSos() {
    const idPediatrician = localStorage.getItem('userId');
    const pediatricianDetails = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [requestData, setRequestData] = useState(null);
    const navigate = useNavigate();
    const { token } = useAuthStore();
    const { id } = useParams(); // Extract request ID from the URL

    const [connection, setConnection] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [messageContent, setMessageContent] = useState('');

    console.log("pediatricianDetails ", pediatricianDetails);

    const sosRequestId = id;
    const username = pediatricianDetails?.firstName;


    const fetchRequestDetails = async () => {
        setLoading(true);
        try {
            setLoading(true);
            const response = await fetch(`${Constants.sosDetails}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            if (data.status === 1) {
                setRequestData(data.info); // Assume API returns data in `info` key
            } else {
                console.error('Failed to fetch request details');
            }
        } catch (error) {
            console.error('Error fetching request details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleButtonClick = async (message, phoneNumber) => {
        // Check if the phone number is null or empty
        if (!phoneNumber) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Phone number is missing. Please provide a valid phone number.',
            });
            return; // Exit the function if phone number is invalid
        }

        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to send the message: "${message}"?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            cancelButtonText: 'Cancel',
        });

        if (confirmResult.isConfirmed) {
            setLoading(true);
            const payload = {
                sender_phone: phoneNumber,
                sender_body: message,
            };

            try {
                const response = await axios.post(`${Constants.SendSMS}`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Message Sent',
                        text: 'Thank you for your action!',
                    });
                } else {
                    throw new Error('Failed to send the message');
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to send the message. Please try again later.',
                });
                console.error('Error sending message:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        const initializeChat = async () => {
            try {
                // Call backend to get token and groupId
                const response = await fetch(`${Constants.enterChat}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ sosRequestId, username, userType: 'doctor' }), // or 'patient'
                });

                const { token, groupId } = await response.json();
                setGroupId(groupId);

                // Initialize Agora Chat connection
                const connection = new WebIM.connection({
                    appKey: '411216900#1406798',
                    url: 'https://msync-api-41.chat.agora.io/ws',
                });

                // Login with the token
                await connection.open({ user: username, accessToken: token });
                setConnection(connection);

                // Join the group
                await connection.joinGroup({ groupId });
                console.log(`Joined group ${groupId}`);

                // Listen for messages
                connection.listen({
                    onTextMessage: (message) => {
                        setMessages((prev) => [...prev, message]);
                    },
                });
            } catch (error) {
                console.error('Failed to initialize chat:', error);
            }
        };

        initializeChat();
    }, [sosRequestId, username]);

    const handleSend = async () => {
        console.log("hit");
        if (connection && groupId && messageContent) {
            try {
                const message = WebIM.message.create({
                    type: 'txt',
                    to: groupId,
                    chatType: 'groupChat',
                    msg: messageContent,
                });

                await connection.send(message);
                console.log('Message sent:', messageContent);
                setMessageContent('');
            } catch (error) {
                console.error('Failed to send message:', error);
            }
        }
    };


    const handleFileUpload = async (event) => {
        console.log("hit file");
        const file = event.target.files[0];
        if (file && connection && groupId) {
            const message = WebIM.message.create({
                type: 'img',
                to: groupId,
                chatType: 'groupChat',
                file,
            });

            try {
                await connection.send(message);
                console.log('File sent:', file.name);
            } catch (error) {
                console.error('Failed to send file:', error);
            }
        }
    };



    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={0}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <Grid container columns={12} spacing={2} className='h-full'>
                            <Grid item md={8} sm={12} xs={12}>
                                <div className='flex gap-3 py-4 px-5 border-b border-[#C5C8C9] items-center justify-between'>
                                    <div className='flex gap-3 items-center'>
                                        <Link to="/request-consultation"> <i class="fa-solid fa-angle-left mr-2"></i></Link>
                                        <div>
                                            <h2 className='text-xl font-semibold'>{requestData?.patientFirstName} {requestData?.patientLastName} </h2>
                                            <p className='text-sm'>({requestData?.parentFirstName} {requestData?.parentLastName})</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-6 text-xl'>
                                        <Link to="/video-call" className='text-[#828DEE]'><i class="fa-regular fa-video"></i></Link>
                                        <Link to="/audio-call" className='text-[#828DEE]'><i class="fa-regular fa-phone"></i></Link>
                                    </div>
                                </div>
                                <div className='py-3 px-5 max-h-[76vh] overflow-x-auto chatscroll min-h-[76vh] relative'>
                                    <div className='text-center mb-4'>
                                        <span className='inline-block py-1 px-4 bg-[#C5C8C9] text-xs rounded-lg text-[#3C3C43]'>Ven, Lug 26</span>
                                    </div>
                                    <h3 className='font-bold text-2xl text-center text-red-500 mb-6 mt-8'>SOS IN CORSO</h3>
                                    <div className='p-6 shadow-lg rounded-xl bg-white mb-8'>
                                        <h4 className='text-2xl font-semibold mb-2'>{requestData?.caseTitle}</h4>
                                        <p>{requestData?.descriptions} </p>
                                    </div>

                                    {/* =========== Output Chat ============= */}
                                    <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                        {messages.map((msg, index) => (
                                            <li key={index}>{msg.data}</li>
                                        ))}
                                        <div className='text-xs text-gray-400'>
                                            10:10
                                        </div>
                                        <div className='max-w-[80%]'>
                                            <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>Invio richiesta al pediatra</span>
                                        </div>
                                        <div className='min-w-[44px]'>
                                            <img
                                                src={pediatricianDetails?.photo || 'assets/images/robot.png'}
                                                alt="Wait..."
                                                className="w-[44px]"
                                            />

                                        </div>
                                    </div>
                                    <div className='text-center w-full absolute bottom-4 left-0'>
                                        <button
                                            onClick={() => handleButtonClick('Andate al pronto soccorso', requestData?.parentPhone)}
                                            className='w-56 px-10 bg-red-500 py-1 align-middle h-20 rounded-lg text-sm font-semibold text-white mx-1'>
                                            Andate al pronto soccorso
                                        </button>
                                        <button
                                            onClick={() => handleButtonClick('Portatelo immediatamente in studio', requestData?.parentPhone)}
                                            className='w-56 px-10 bg-red-500 py-1 align-middle h-20 rounded-lg text-sm font-semibold text-white mx-1'>
                                            Portatelo immediatamente in studio
                                        </button>
                                    </div>
                                </div>
                                <div className='flex gap-5 border-t border-b border-[#C5C8C9] px-5 py-6 items-center'>
                                    <div className='text-2xl text-[#828DEE]'>
                                        <label>
                                            <input
                                                type='file'
                                                className='hidden'
                                                onChange={handleFileUpload}
                                                accept="image/*,application/pdf" // Restrict to images and PDFs
                                            /> <i class="fa-solid fa-paperclip-vertical"></i>
                                        </label>
                                    </div>
                                    <div className='grow'>
                                        <input type='text' value={messageContent} onChange={(e) => setMessageContent(e.target.value)} className='px-4 py-3 rounded-full w-full border border-[#C5C8C9]' />
                                    </div>
                                    <div>
                                        <button onClick={handleSend} className='w-[40px] h-[40px] rounded-full text-white bg-[#828DEE] text-center'><i class="fa-regular fa-paper-plane-top"></i></button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12} className='md:border-l  border-[#C5C8C9]'>
                                <div className='p-4'>
                                    <ChatSidebar requestData={requestData} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

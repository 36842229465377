import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints';

export default function AdvanceSearch() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});
    const idPediatrician = localStorage.getItem('userId');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [formData, setFormData] = useState({
        name: "",
        parent: "",
        yearOfBirth: "",
        age: "",
        ageStart: "",
        ageEnd: "",
        dateStart: "",
        dateEnd: "",
        healthCardNumber: "",
        isSSN: false,
        isPrivate: false,
        ageKeyword: "Year of birth",
        saveSearch: false,
    });

    const handleDropdownChange = (event) => {
        setFormData({
            ...formData,
            ageKeyword: event.target.value,
            yearOfBirth: "",
            age: "",
            ageStart: "",
            ageEnd: "",
            dateStart: "",
            dateEnd: "",
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validationErrors = {};

        // Validate Year of Birth
        if (formData.ageKeyword === "Year of birth" && formData.yearOfBirth.trim()) {
            if (!/^\d{4}$/.test(formData.yearOfBirth)) {
                validationErrors.yearOfBirth = "Year of birth must be a valid 4-digit year.";
            }
        }

        // Validate Age
        if (formData.ageKeyword === "Age" && formData.age.trim()) {
            if (isNaN(formData.age) || formData.age <= 0) {
                validationErrors.age = "Age must be a positive number.";
            }
        }

        // Validate Age Range
        if (formData.ageKeyword === "Age range") {
            if (formData.ageStart.trim() && (isNaN(formData.ageStart) || formData.ageStart <= 0)) {
                validationErrors.ageStart = "Starting age must be a positive number.";
            }
            if (formData.ageEnd.trim() && (isNaN(formData.ageEnd) || formData.ageEnd <= 0)) {
                validationErrors.ageEnd = "Ending age must be a positive number.";
            }
            if (
                formData.ageStart.trim() &&
                formData.ageEnd.trim() &&
                parseInt(formData.ageStart) > parseInt(formData.ageEnd)
            ) {
                validationErrors.ageRange = "Starting age must not exceed ending age.";
            }
        }

        // Validate Range Dates
        if (formData.ageKeyword === "Range dates") {
            if (formData.dateStart.trim() && isNaN(new Date(formData.dateStart).getTime())) {
                validationErrors.dateStart = "Start date is invalid.";
            }
            if (formData.dateEnd.trim() && isNaN(new Date(formData.dateEnd).getTime())) {
                validationErrors.dateEnd = "End date is invalid.";
            }
            if (
                formData.dateStart.trim() &&
                formData.dateEnd.trim() &&
                new Date(formData.dateStart) > new Date(formData.dateEnd)
            ) {
                validationErrors.dateRange = "Start date must be before or the same as the end date.";
            }
        }

        // If there are errors, set them and stop submission
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Clear errors if no validation errors
        setErrors({});

        // Construct search parameters with defaults
        const searchParams = {
            keyword: formData.keyword || "",
            name: formData.name?.trim() || "",
            parent: formData.parent?.trim() || "",
            yearOfBirth: formData.ageKeyword === "Year of birth" ? formData.yearOfBirth?.trim() || "" : "",
            age: formData.ageKeyword === "Age" ? formData.age?.trim() || "" : "",
            ageStart: formData.ageKeyword === "Age range" ? formData.ageStart?.trim() || "" : "",
            ageEnd: formData.ageKeyword === "Age range" ? formData.ageEnd?.trim() || "" : "",
            dateStart: formData.ageKeyword === "Range dates" ? formData.dateStart?.trim() || "" : "",
            dateEnd: formData.ageKeyword === "Range dates" ? formData.dateEnd?.trim() || "" : "",
            healthCardNumber: formData.healthCardNumber?.trim() || "",
            isSSN: formData.isSSN || false,
            isPrivate: formData.isPrivate || false,
        };

        console.log("searchParams", searchParams);

        try {
            const response = await axios.get(`${Constants.getPediatricianPatients}${idPediatrician}`, {
                params: searchParams,
            });

            console.log("Search Results:", response.data);

            if (formData.saveSearch) {
                const saveSearchPayload = {
                    idPediatrician,
                    name: formData.name,
                    parent: formData.parent,
                    age: `${formData.ageStart || ""}-${formData.ageEnd || ""}`,
                    ageKeyword: formData.ageKeyword,
                    healthCardNumber: formData.healthCardNumber,
                    isSSN: formData.isSSN,
                    isPrivate: formData.isPrivate,
                };

                try {
                    const saveSearchResponse = await axios.post(Constants.pediatricianSearchSave, saveSearchPayload);
                    console.log("Save Search Response:", saveSearchResponse.data);
                } catch (error) {
                    console.error("Save Search API Error:", error);
                }
            }

            navigate("/patients", { state: { searchParams } });
        } catch (error) {
            console.error("Search API Error:", error);
        }
    };




    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <Grid container spacing={3} className='justify-center'>
                                <Grid item lg={6} md={6} xs={6}>
                                    <div className='flex gap-3 mb-6 font-semibold'>
                                        <Link to="/patients"><i class="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti </Link>
                                        <span>/</span>
                                        <span>Ricerca avanzata</span>
                                    </div>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} className='text-right'>
                                    {/* <button type='button'  onClick={handleOpen} className=' py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-[#828DEE] text-base font-semibold hover:bg-[#828DEE] hover:text-white transition-all'>+ Aggiungi paziente</button> */}
                                </Grid>
                            </Grid>
                            <div className='text-center mt-6 mb-10'>
                                <h2 className='font-semibold text-2xl mt-6 mb-3'>Ricerca Avanzata</h2>
                                <p>Puoi compilare tutti i campi o solo parte di essi per trovare un paziente specifico o un gruppo di pazienti</p>
                            </div>
                            <Grid container spacing={5} className='justify-center'>
                                <Grid item lg={8} md={12}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            {/* Nome e/o Cognome */}
                                            <Grid item lg={6} md={12} xs={12}>
                                                <TextField
                                                    label="Nome e/o Cognome"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formData.name}
                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                    error={!!errors.name}
                                                    helperText={errors.name || ""}
                                                />
                                            </Grid>

                                            {/* Age Dropdown */}
                                            <Grid item lg={6} md={12} xs={12}>
                                                <FormControl fullWidth error={!!errors.ageKeyword}>
                                                    <InputLabel>Age</InputLabel>
                                                    <Select
                                                        label="Age"
                                                        value={formData.ageKeyword}
                                                        onChange={handleDropdownChange}
                                                    >
                                                        <MenuItem value="Year of birth">Anno di nascita</MenuItem>
                                                        <MenuItem value="Age">Età</MenuItem>
                                                        <MenuItem value="Age range">Range età</MenuItem>
                                                        <MenuItem value="Range dates">Date dell'intervallo</MenuItem>
                                                    </Select>
                                                    <p style={{ color: "red", margin: "4px 0" }}>{errors.ageKeyword || ""}</p>
                                                </FormControl>
                                            </Grid>

                                            {/* Age Keyword Conditional Fields */}
                                            <Grid item lg={6} md={12} xs={12}>
                                                {formData.ageKeyword === "Year of birth" && (
                                                    <TextField
                                                        label="Anno di nascita"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={formData.yearOfBirth}
                                                        onChange={(e) => setFormData({ ...formData, yearOfBirth: e.target.value })}
                                                        error={!!errors.yearOfBirth}
                                                        helperText={errors.yearOfBirth || ""}
                                                    />
                                                )}
                                                {formData.ageKeyword === "Age" && (
                                                    <TextField
                                                        label="Età"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={formData.age}
                                                        onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                                                        error={!!errors.age}
                                                        helperText={errors.age || ""}
                                                    />
                                                )}
                                                {formData.ageKeyword === "Age range" && (
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <TextField
                                                            label="Da"
                                                            variant="outlined"
                                                            value={formData.ageStart}
                                                            onChange={(e) => setFormData({ ...formData, ageStart: e.target.value })}
                                                            error={!!errors.ageStart}
                                                            helperText={errors.ageStart || ""}
                                                        />
                                                        <TextField
                                                            label="A"
                                                            variant="outlined"
                                                            value={formData.ageEnd}
                                                            onChange={(e) => setFormData({ ...formData, ageEnd: e.target.value })}
                                                            error={!!errors.ageEnd}
                                                            helperText={errors.ageEnd || ""}
                                                        />
                                                        {errors.ageRange && (
                                                            <p style={{ color: "red", margin: "4px 0" }}>{errors.ageRange}</p>
                                                        )}
                                                    </div>
                                                )}
                                                {formData.ageKeyword === "Range dates" && (
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <TextField
                                                            label="Da"
                                                            type="date"
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formData.dateStart}
                                                            onChange={(e) => setFormData({ ...formData, dateStart: e.target.value })}
                                                            error={!!errors.dateStart}
                                                            helperText={errors.dateStart || ""}
                                                        />
                                                        <TextField
                                                            label="A"
                                                            type="date"
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formData.dateEnd}
                                                            onChange={(e) => setFormData({ ...formData, dateEnd: e.target.value })}
                                                            error={!!errors.dateEnd}
                                                            helperText={errors.dateEnd || ""}
                                                            inputProps={{ min: formData.dateStart }}
                                                        />
                                                        {errors.dateRange && (
                                                            <p style={{ color: "red", margin: "4px 0" }}>{errors.dateRange}</p>
                                                        )}
                                                    </div>
                                                )}
                                            </Grid>

                                            {/* Parent */}
                                            <Grid item lg={6} md={12} xs={12}>
                                                <TextField
                                                    label="Genitore"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formData.parent}
                                                    onChange={(e) => setFormData({ ...formData, parent: e.target.value })}
                                                    error={!!errors.parent}
                                                    helperText={errors.parent || ""}
                                                />
                                            </Grid>

                                            {/* Health Card Number */}
                                            <Grid item lg={6} md={12} xs={12}>
                                                <TextField
                                                    label="Numero Tessera Sanitaria"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formData.healthCardNumber}
                                                    onChange={(e) => setFormData({ ...formData, healthCardNumber: e.target.value })}
                                                    error={!!errors.healthCardNumber}
                                                    helperText={errors.healthCardNumber || ""}
                                                />
                                            </Grid>

                                            {/* SSN and Private */}
                                            <Grid item lg={6} md={12} xs={12} className="flex gap-5 font-semibold">
                                                <label>
                                                    <Checkbox
                                                        checked={formData.isSSN}
                                                        onChange={(e) => setFormData({ ...formData, isSSN: e.target.checked })}
                                                    />{" "}
                                                    SSN
                                                </label>
                                                <label>
                                                    <Checkbox
                                                        checked={formData.isPrivate}
                                                        onChange={(e) => setFormData({ ...formData, isPrivate: e.target.checked })}
                                                    />{" "}
                                                    Privato
                                                </label>
                                            </Grid>
                                        </Grid>

                                        {/* Save Search */}
                                        <div className="text-center mt-10 mb-4">
                                            <label>
                                                <Checkbox
                                                    checked={formData.saveSearch}
                                                    onChange={(e) => setFormData({ ...formData, saveSearch: e.target.checked })}
                                                />{" "}
                                                Salva ricerca
                                            </label>
                                        </div>

                                        {/* Submit Button */}
                                        <div className="flex justify-center">
                                            <button
                                                type="submit"
                                                className="w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </form>


                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
                    <button type='button' className='absolute top-4 right-4 text-2xl' onClick={handleClose}><i class="fa-solid fa-circle-xmark"></i></button>
                    <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Inserisci paziente</h2>
                    <form>
                        <div className='mb-5'>
                            <TextField label="Codice Fiscale" variant="outlined" className='w-full rounded-lg' />
                        </div>
                        <div className='text-center'>
                            <label><Checkbox /> Paziente SSN</label>
                        </div>
                        <div className='text-center mt-10'>
                            <button className='block text-center leading-[54px] w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Aggiungi</button>
                        </div>
                    </form>

                </Box>
            </Modal>
        </>
    )
}

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import DashboardNav from '../Dashboard/DashboardNav'
import {Link} from 'react-router-dom'

export default function Sts() {
    const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
    <div >
        <Grid container columns={12} spacing={2} className='bg-[#FAFAFA]'>
          
                <Grid item md={12} sm={12} xs={12}>
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                        <span>/</span>
                        <Link to="/medical-practice-management">Gestione studio medico</Link>
                        <span>/</span>
                        <span>Sistema Tessera Sanitaria</span>
                    </div>
                    
                    <Grid container spacing={3} className='justify-center'>
                        <Grid item lg={9} md={12} xs={12}>

                            <div>
                                <h2 className='text-5xl font-bold mt-8 mb-10 text-center'>
                                    <img src='assets/images/sts.png' className='h-[70px] mx-auto'/>
                                </h2>
                                <p className='text-center mb-3'>Inserisci le credenziali che utilizzi per il <span className='font-semibold'>Sistema Tessera Sanitaria.</span> </p>
                               <p className='mb-12 text-center'>Ti permetterà di automatizzare l’invio di prescrizioni, la fatturazione e la sincronizzazione dei pazienti</p>
                                <Grid container spacing={3} className='justify-center'>
                                    <Grid item lg={6} md={8} xs={12}>
                                        <form>
                                            <div className='mb-5'>
                                                <TextField label="Username" variant="outlined" className='w-full rounded-lg' />
                                            </div>
                                            <div className='mb-5'>
                                                <FormControl variant="outlined" className='w-full'>
                                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onMouseUp={handleMouseUpPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='mb-5'>
                                                <TextField label="Pincode" variant="outlined" className='w-full rounded-lg' />
                                            </div>
                                            <div className='mb-5'>
                                                <TextField label="Nickname" variant="outlined" className='w-full rounded-lg' />
                                            </div>
                                            <div className='mb-5 mt-10'>
                                                <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Salva</button>
                                            </div>
                                        </form>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            
            
        </Grid>
    </div>
    </>
  )
}
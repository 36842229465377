import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Alert } from '@mui/material'
import PatientDetailNav from './PatientDetailNav'
import useAuthStore from "../../hooks/useAuthStore";
import axios from 'axios';
import { Constants } from '../../endPoints'
import Swal from 'sweetalert2'; // Import SweetAlert2



export default function PatientDetails() {
    const navigate = useNavigate();
    const { idPatient } = useParams();
    const { setToken, token, setId } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const pediatricianDetails = JSON.parse(localStorage.getItem('user'));

    const [patient, setPatient] = useState(null); // Patient details
    const [parents, setParent] = useState(null); // Parent details
    const [loading, setLoading] = useState(false); // API loading state
    const [error, setError] = useState(''); // API error state

    // Fetch patient data
    const getPatient = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${Constants.getPediatricianPatient}`,
                {
                    idPatient,
                    idPediatrician,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Correctly positioned headers
                    },
                }
            );
            const patientData = response?.data?.patient || {};
            setPatient(patientData);
            setParent(patientData?.parents); // Assuming `parent` is part of the response
        } catch (err) {
            setError('Failed to load patient details. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatient();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="info">No patient data available.</Alert>
            </div>
        );
    }


    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/patients"><i class="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti </Link>
                                <span>/</span>
                                <span>{patient?.firstName} {patient?.lastName}</span>
                            </div>
                            <PatientDetailNav patient={patient} />
                            <div className='flex mt-5 mb-5'>
                                <Link to={`/patient-details/${patient.id}`} className="px-6 py-3 text-[#828DEE] border-t rounded-tr border-[#C5C8C9] border-r "><i className="fa-regular fa-user mr-2"></i> Anagrafica</Link>
                                <Link to={`/patient-medical-record/${patient.id}`} className="px-6 py-3 text-[#72777A] border-b rounded-bl border-[#C5C8C9] grow"><i className="fa-regular fa-square-list  mr-2"></i> Cartella clinica</Link>
                            </div>
                            <div className='pt-5'>
                                <Grid container spacing={5}>
                                    <Grid item lg={6} md={6} xs={12} >
                                        <div className='flex justify-between  mb-4'>
                                            <p className='font-semibold'>Paziente {patient?.type}</p>
                                            <div className='pr-10'>
                                                <Link to={`/edit-patient-info/${patient.id}`} className="text-sm underline italic text-[#828DEE]">modifica</Link>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <p>Nome e Cognome</p>
                                            <h4 className='text-2xl font-semibold'>{patient?.firstName} {patient?.lastName}</h4>
                                        </div>
                                        <div className='mb-3'>
                                            <p>Data e Luogo di nascita</p>
                                            <h4 className='text-2xl font-semibold'>{patient?.dateOfBirth} - {patient?.placeOfBirth}</h4>
                                        </div>
                                        <div className='mb-3'>
                                            <p>Numero Tessera Sanitaria</p>
                                            <h4 className='text-2xl font-semibold'>{patient?.healthCardNumber}</h4>
                                        </div>

                                        <div className="mb-3">
                                            {/* Display First Parent's Phone Number */}
                                            <p>Numero di telefono</p>
                                            <h4 className="text-2xl font-semibold underline text-[#828DEE]">
                                                {patient?.parents?.[0]?.phone || 'N/A'}
                                            </h4>
                                        </div>

                                        {/* Display First Parent's Addresses */}
                                        {patient?.parents?.[0]?.addresses?.length > 0 &&
                                            patient.parents[0].addresses.map((address, index) => (
                                                <div key={index} className="mb-3">
                                                    <p>Indirizzo di {address.type}</p>
                                                    <p>
                                                        {`${address.street || ''}, ${address.cityName || ''}, ${address.provinceName || ''}, ${address.zip || ''}`.replace(
                                                            /,\s*,/g,
                                                            ', '
                                                        )}
                                                    </p>
                                                </div>
                                            ))}


                                        <div className='mb-3'>
                                            <p>Documento di identità</p>
                                            <a href={patient?.identityCardFront} className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png' /> Carta di identità</a>
                                        </div>
                                        {/* <div className='mb-3'>
                                            <p className='font-semibold'>Esenzioni</p>
                                            <p className='text-2xl font-semibold text-[#C5C8C9]'>Non presenti</p>
                                        </div> */}
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12} className="md:border-l border-[#C5C8C9]">
                                        <p>Dati del Genitore</p>
                                        {parents.length > 0 ? (
                                            parents.map((parent, index) => (
                                                <div key={parent.idParent} className={`my-5 ${parents.length > 1 && index < parents.length - 1 ? 'border-bottom-1 mt-4' : ''
                                                    }`}>
                                                    <div className="flex items-center gap-4 my-5">
                                                        <div>
                                                            <img
                                                                src={parent.photo || '../assets/images/default-profile.png'}
                                                                alt="Parent"
                                                                className="w-20 h-20 object-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h2>Nome e Cognome</h2>
                                                            <p className="text-2xl font-semibold">{`${parent.firstName} ${parent.lastName}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <p>Data e Luogo di nascita</p>
                                                        <h4 className='text-2xl font-semibold'>{parent?.dateOfBirth} - {parent?.placeOfBirth}</h4>
                                                    </div>

                                                    <div className="mb-3">
                                                        <p>Codice Fiscale</p>
                                                        <h4 className="text-2xl font-semibold">{parent.taxCode}</h4>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <p>Documento di identità</p>
                                                        <a href={parent?.identityCardFront} className='flex gap-3 my-2 bg-[#7676804D] px-3 py-2 rounded-lg'><img src='../assets/images/fileicon.png' /> Carta di identità</a>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No parent details available.</p>
                                        )}
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}


import React, { useState, useEffect, useContext } from 'react'
import { Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom';
import { Constants } from '../../endPoints';
import { useNavigate, useLocation } from 'react-router-dom' // Import useNavigate from react-router-dom
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, ErrorMessage } from 'formik';
import { LanguageContext } from '../../LanguageContext'; // Adjust the path if necessary
import IdentificationDocument from "../Profile/IdentificationDocument";
import StampSignatureReg from "../Profile/StampSignatureReg";
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce' // **[Change 1]**

const UserDetailsInfo = ({ handleNext, meta, }) => {
  const { user, updateUser, forceUserDataUpdate } = useUser(); // Access the user info from the context
  const [apiError, setApiError] = useState(''); // State to hold API error
  const navigate = useNavigate();
  const LOCAL_STORAGE_KEY = `${meta.id}_userDetailsForm`; // Key for storing form data in localStorage
  const [page, setPage] = useState(1); // Controls page navigation
  // For Region dropdown
  const [regionalCodes, setRegionalCodes] = useState([])
  const [regionCurrentPage, setRegionCurrentPage] = useState(1);
  const [regionTotalPages, setRegionTotalPages] = useState(1);
  const [regionLoading, setRegionLoading] = useState(false);
  const [regionHasMore, setRegionHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  // For City dropdown
  const [cityOptions, setCityOptions] = useState([]);
  const [keyword, setKeyword] = useState(''); // New state for user's input
  const [cityLoading, setCityLoading] = useState(false);

  // For ASL dropdown
  const [aslOptions, setAslOptions] = useState([]);
  const [aslCurrentPage, setAslCurrentPage] = useState(1);
  const [aslTotalPages, setAslTotalPages] = useState(1);
  const [aslLoading, setAslLoading] = useState(false);
  const [aslHasMore, setAslHasMore] = useState(true);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const { t } = useTranslation()
  // Form data state
  const [formData, setFormData] = useState({
    idPediatrician: null,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    placeOfBirth: '',
    taxCode: '',
    registrationNumber: '',
    regionalCode: '',
    aslAgreementCode: '',
    vatNumber: '',
    taxDomicile: '',
    iban: '',
    identityCardBack: '',
    identityCardFront: '',
    stamp: '',
    signature: '',
    sdi: '',
    pec: '',
  });


  // Load form data from localStorage on component mount
  useEffect(() => {

    console.log("META", meta)
    const savedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (savedFormData) {
      setFormData(JSON.parse(savedFormData)); // Restore form data from localStorage
    }
  }, []);

  // Save form data to localStorage on each form update
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData));
  }, [formData]); // Trigger every time formData changes

  // fetch all remote fields for apis
  useEffect(() => {

    fetchRegionalCodes()
  }, [formData]); // Trigger every time formData changes


  const handleFileChange = (event, fieldName) => {
    const file = event.currentTarget.files[0]; // Get the selected file
    if (file) {
      formik.setFieldValue(fieldName, file); // Update formik's value with the file object
    }
  };


  const regNumberValidateWordLength = (word) => {
    const minLength = /^.{2,}$/; // Minimum 2 characters

    // Check if the word meets the minimum length requirement
    if (!minLength.test(word)) {
      return "Registration number must be at least 2 characters long"; // Return error message for length
    }

    // If all conditions pass, return true (indicating valid registration number)
    return true;
  };

  // Formik validation schema for current page
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Il nome è obbligatorio'),
    lastName: Yup.string().required('Il Cognome è obbligatorio'),
    dateOfBirth: Yup.date()
      .required('La data di nascita è obbligatoria')
      .test('è-18', 'Devi avere almeno 18 anni', function (value) {
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
        return age > 18 || (age === 18 && (monthDifference > 0 || (monthDifference === 0 && dayDifference >= 0)));
      }),
    placeOfBirth: Yup.string().required('È richiesto il luogo di nascita'),
    taxCode: Yup.string()
      .matches(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/, 'Formato codice fiscale non valido')
      .required('Codice fiscale obbligatorio'),
    registrationNumber: Yup.string().notRequired(), // Optional
    regionalCode: Yup.string().required('Codice Regione di Convenzione is required'),
    aslAgreementCode: Yup.string().required('Codice ASL di convenzione is required'),
    vatNumber: Yup.string()
      .matches(/^\d{11}$/, 'Partita IVA 11 cifre')
      .required('Partita IVA richiesta'),
    taxDomicile: Yup.string().notRequired(), // Optional
    iban: Yup.string().notRequired(), // Optional
    identityCardBack: Yup.mixed().notRequired(`È richiesto il retro della carta d'identità`),
    identityCardFront: Yup.mixed().notRequired(`È richiesta la carta d'identità frontale`),
    stamp: Yup.mixed().notRequired(`È richiesto il timbro`),
    signature: Yup.mixed().notRequired(`È richiesta la firma`),
    sdi: Yup.string().notRequired(), // Optional
    pec: Yup.string().notRequired(), // Optional
  });


  // Formik initialization
  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true, // Allows formik to reinitialize with updated formData
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setFormData((prevData) => ({
        ...prevData,
        ...values, // Save current form values to formData
      }));

      // Submit form data to API
      values = { ...values, idPediatrician: meta.id }
      await handleSubmit(values);
    },
  });

  const handleNextPage = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...formik.values, // Save current form values to formData
    }));
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...formik.values,
    }));
    setPage((prevPage) => prevPage - 1);
  };

  const handleSubmit = async (originalValues) => {
    // Assuming originalValues is an object, convert it to FormData
    const formData = new FormData();
    Object.entries(originalValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the static field
    formData.append('isProfileCompleted', 'true');

    // Submit final form data to API
    setRegistrationLoading(true);
    try {
      const response = await axios.post(Constants.profilePediatricianAppV2, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201 || response.status === 200) {
        localStorage.removeItem('meta');
        await forceUserDataUpdate(); // Re-fetch data instead of reloading
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        setApiError(error.response.data.message);
      } else {
        setApiError('An error occurred. Please try again later.');
      }
    } finally {
      setRegistrationLoading(false);
    }
  };

  const fetchRegionalCodes = async (page) => {
    if (regionLoading || page > regionTotalPages) return;
    setRegionLoading(true);
    try {
      const response = await fetch(`${Constants.regionList}?&page=1&limit=500`)
      if (response.ok) {
        const data = await response.json()
        setRegionalCodes((prevCodes) => [...prevCodes, ...data.data]);
        setRegionTotalPages(data.pagination.totalPages);
        setRegionCurrentPage(data.pagination.currentPage);
        setRegionHasMore(data.pagination.currentPage < data.pagination.totalPages);
      } else {
        console.error('Failed to Region ')
      }
    } catch (error) {
      console.error('Error in fetching Region:', error)
    }
    finally {
      setRegionLoading(false);
    }
  }

  // Debounced function to fetch cities based on keyword
  const fetchCitiesAutocomplete = debounce(async (input) => {
    if (input.length >= 1) {
      setCityLoading(true);
      try {
        const response = await axios.get(`${Constants.cityList}`, {
          params: {
            page: 1,
            limit: 5,
            keyword: input,
          },
        });
        if (response.data.status === 1 && response.data.data.length > 0) {
          setCityOptions(response.data.data);
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCityOptions([]);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCityOptions([]);
    }
  }, 300);

  // **[Change 4]**
  // Handler for placeOfBirth input change
  const handlePlaceOfBirthChange = (e) => {
    const inputValue = e.target.value;
    setKeyword(inputValue);
    formik.setFieldValue('placeOfBirth', inputValue);
    fetchCitiesAutocomplete(inputValue);
  };


  const fetchAslOptions = async (regionalCode, page) => {
    if (aslLoading || page > aslTotalPages) return;
    setAslLoading(true);
    if (regionalCode) {
      try {
        const response = await fetch(
          `${Constants.regionWiseASL}?idRegion=${regionalCode}&page=1&limit=50`,
        )
        if (response.ok) {
          const result = await response.json()
          setAslOptions((prevAsl) => [...prevAsl, ...result.aslList]);
          setAslTotalPages(result.totalPages);
          setAslCurrentPage(result.currentPage);
          setAslHasMore(result.currentPage < result.totalPages);
        } else {
          console.error('Failed to fetch ASL options')
          setAslOptions([])
        }
      } catch (error) {
        console.error('Error fetching ASL options:', error)
        setAslOptions([])
      } finally {
        setAslLoading(false);
      }
    } else {
      setAslOptions([])
    }
  }

  // Handle region change
  const handleRegionChange = async (event, formik) => {
    const regionalCode = event.target.value;
    formik.setFieldValue('regionalCode', regionalCode);
    setAslOptions([]);  // Reset ASL options on region change
    setAslCurrentPage(1);  // Reset ASL pagination
    await fetchAslOptions(regionalCode, 1);  // Fetch ASL based on new region
  };
  // **[Change 6]**
  // Removed 'fetchCityOptions' function and related state variables

  const handleScroll = (event, type) => {
    const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
    if (bottomReached) {
      if (type === 'region' && regionHasMore) {
        fetchRegionalCodes(regionCurrentPage + 1);
      } else if (type === 'asl' && aslHasMore) {
        fetchAslOptions(formik.values.regionalCode, aslCurrentPage + 1);
      }
      // Removed 'city' type handling
    }
  };

  const today = new Date().toISOString().split('T')[0];



  return (
    <>
      <Grid item xl={6} lg={7} md={8} xs={12}>
        <h2 className="mb-8 text-3xl font-semibold text-center">
          {page === 1
            ? 'Anagrafica'
            : page === 2
              ? 'Dati professionali'
              : page === 3
                ? 'Carica la carta d’identità'
                : 'Carica la foto del timbro e della firma.'}
        </h2>

        {page === 3 && (
          <p className="p1 text-center mb-10">
            Scatta una foto o carica un file contenente una foto fronte e retro della
            tua Carta di Identità. La foto deve essere chiara, leggibile e non
            sgranata per assicurarne la verificabilità.
          </p>
        )}

        {page === 4 && (
          <p className="p2 text-center mb-10">
          La foto deve essere chiara, leggibile e non sgranata per poter essere apposta sui documenti.
          </p>
        )}


        {/* Form rendering based on page */}
        <form onSubmit={formik.handleSubmit}>
          {page === 1 && (
            <>
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='firstName'
                  name='firstName'
                  label='First Name'
                  variant='outlined'
                  placeholder='Enter First Name'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='lastName'
                  name='lastName'
                  label='Last Name'
                  variant='outlined'
                  placeholder='Enter Last Name'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='dateOfBirth'
                  name='dateOfBirth'
                  label='Date of Birth'
                  variant='outlined'
                  type='date'
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                  helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                  InputProps={{ style: { height: '54px' } }}
                  InputLabelProps={{ shrink: true }}  // Ensure label does not overlap
                  inputProps={{  // Note the lowercase i in inputProps for native attributes
                    max: today  // Set the max attribute to today's date
                  }}

                />
              </div>

              {/* place of birth moved here */}
              <div className='mb-5' style={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  id='placeOfBirth'
                  name='placeOfBirth'
                  label='Place of Birth (City)'
                  variant='outlined'
                  placeholder='Enter Place of Birth'
                  value={formik.values.placeOfBirth}
                  onChange={handlePlaceOfBirthChange} // Changed handler
                  onBlur={formik.handleBlur}
                  error={formik.touched.placeOfBirth && Boolean(formik.errors.placeOfBirth)}
                  helperText={formik.touched.placeOfBirth && formik.errors.placeOfBirth}
                  autoComplete="off" // Disable browser autocomplete
                  InputProps={{ style: { height: '54px' } }}
                />
                {cityOptions.length > 0 && (
                  <ul
                    className="autocomplete-suggestions"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      right: 0,
                      zIndex: 1000,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}
                  >
                    {cityOptions.map((city) => (
                      <li
                        key={city.id}
                        onClick={() => {
                          formik.setFieldValue('placeOfBirth', city.denominations);
                          setKeyword(city.denominations);
                          setCityOptions([]);
                        }}
                        style={{ padding: '8px', cursor: 'pointer' }}
                      >
                        {city.denominations}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='taxCode'
                  name='taxCode'
                  label='Tax ID Code'
                  variant='outlined'
                  placeholder='Enter Tax Code'
                  value={formik.values.taxCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.taxCode && Boolean(formik.errors.taxCode)}
                  helperText={formik.touched.taxCode && formik.errors.taxCode}
                  InputProps={{ style: { height: '54px' } }}
                />

              </div>

              <div className='mb-5'>
                <button
                  type='button'
                  className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                  onClick={async () => {
                    // Mark all fields on the first page as touched
                    formik.setTouched({
                      firstName: true,
                      lastName: true,
                      dateOfBirth: true,
                      placeOfBirth: true,
                      taxCode: true,
                    });

                    // Validate the entire form and get errors
                    const errors = await formik.validateForm();

                    // If no errors exist for first page fields, proceed to the next page
                    if (
                      !errors.firstName &&
                      !errors.lastName &&
                      !errors.dateOfBirth &&
                      !errors.placeOfBirth &&
                      !errors.taxCode
                    ) {
                      handleNextPage(); // Navigate to the next page
                    } else {
                      console.log('Validation errors:', errors); // Debugging purpose
                    }
                  }}
                >
                  Avanti
                </button>
              </div>
            </>
          )}

          {page === 2 && (
            <>
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='registrationNumber'
                  name='registrationNumber'
                  label='N° iscrizione all\ (registrationNumber)'
                  variant='outlined'
                  value={formik.values.registrationNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                  helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
                  InputProps={{ style: { height: '54px' } }}
                />

              </div>

              <div className="mb-5">
                <TextField
                  select
                  fullWidth
                  id="regionalCode"
                  name="regionalCode"
                  label="Codice Regione di Convenzione (regionalCode)"
                  variant="outlined"
                  value={formik.values.regionalCode}
                  onChange={(event) => handleRegionChange(event, formik)} // Fetch city and ASL on region change
                  onBlur={formik.handleBlur}
                  error={formik.touched.regionalCode && Boolean(formik.errors.regionalCode)}
                  helperText={formik.touched.regionalCode && formik.errors.regionalCode}
                  InputProps={{ style: { height: '54px' } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                        onScroll: (event) => handleScroll(event, 'region'),
                      },
                    },
                  }}
                >
                  {regionalCodes.map((region, index) => (
                    <MenuItem key={index} value={region.code}>
                      {region.denomination}
                    </MenuItem>
                  ))}
                  {regionLoading && (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  )}
                </TextField>
              </div>

              <div className='mb-5'>
                <TextField
                  select
                  fullWidth
                  id='aslAgreementCode'
                  name='aslAgreementCode'
                  label='Codice ASL di convenzione(aslAgreementCode)'
                  variant='outlined'
                  value={formik.values.aslAgreementCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.aslAgreementCode && Boolean(formik.errors.aslAgreementCode)}
                  helperText={formik.touched.aslAgreementCode && formik.errors.aslAgreementCode}
                  InputProps={{ style: { height: '54px' } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                        onScroll: (event) => handleScroll(event, 'asl'),
                      },
                    },
                  }}
                >
                  {aslOptions.map((asl, index) => (
                    <MenuItem key={index} value={asl.code}>
                      {asl.denomination + '(' + asl.code + ')'}
                    </MenuItem>
                  ))}
                  {aslLoading && (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  )}
                </TextField>
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='vatNumber'
                  name='vatNumber'
                  label='VAT Number'
                  variant='outlined'
                  value={formik.values.vatNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.vatNumber && Boolean(formik.errors.vatNumber)}
                  helperText={formik.touched.vatNumber && formik.errors.vatNumber}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              {/* SDI  */}
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='sdi'
                  name='sdi'
                  label='SDI'
                  variant='outlined'
                  value={formik.values.sdi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sdi && Boolean(formik.errors.sdi)}
                  helperText={formik.touched.sdi && formik.errors.sdi}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              {/* PEC  */}
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='pec'
                  name='pec'
                  label='PEC'
                  variant='outlined'
                  value={formik.values.pec}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pec && Boolean(formik.errors.pec)}
                  helperText={formik.touched.pec && formik.errors.pec}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='taxDomicile'
                  name='taxDomicile'
                  label='Tax Domicilio'
                  variant='outlined'
                  value={formik.values.taxDomicile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.taxDomicile && Boolean(formik.errors.taxDomicile)}
                  helperText={formik.touched.taxDomicile && formik.errors.taxDomicile}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='iban'
                  name='iban'
                  label='IBAN'
                  variant='outlined'
                  value={formik.values.iban}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.iban && Boolean(formik.errors.iban)}
                  helperText={formik.touched.iban && formik.errors.iban}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5 flex justify-between'>
                <button
                  type='button'
                  className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                  onClick={async () => {
                    // Mark all fields on the second page as touched
                    formik.setTouched({
                      regionalCode: true,
                      aslAgreementCode: true,
                      vatNumber: true,
                    });

                    // Validate the entire form and get errors
                    const errors = await formik.validateForm();

                    // Check if there are no errors for required fields on the second page
                    if (
                      !errors.regionalCode &&
                      !errors.aslAgreementCode &&
                      !errors.vatNumber
                    ) {
                      handleNextPage(); // Navigate to the next page
                    } else {
                      console.log('Validation errors:', errors); // Debugging purpose
                    }
                  }}
                >
                  Avanti
                </button>
              </div>

            </>
          )}

          {page === 3 && (
            <>
              <IdentificationDocument navigation="registration" handleFileChange={handleFileChange} formik={formik} submitEnable={false} />
              <div className='mb-5 flex justify-between'>
                <button
                  type='button'
                  className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                  onClick={async () => {
                    handleNextPage(); // Navigate to the next page
                  }}
                >
                  Avanti
                </button>
              </div>
            </>

          )}

          {page === 4 && (
            <>
              <StampSignatureReg navigation="registration" handleFileChange={handleFileChange} formik={formik} submitEnable={false} />
              <div className="mb-6 mt-10">
                <button
                  className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                  type="submit"
                >
                  {
                    registrationLoading ? <CircularProgress size={24} /> : 'Avanti'
                  }
                </button>
              </div>
            </>

          )}


          {/* <div className="flex justify-center items-center h-full">
            {
              !(page === 3) && <button
                type="submit"
                className="w-[90px] h-[35px] bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                onClick={(e) => { navigate('/dashboard'); }}
              >
                Skip
              </button>
            }

          </div> */}

          {
            apiError && <div className="text-red-500 text-sm m-3">{apiError}</div>
          }


        </form>
      </Grid>
    </>
  );
};


export default UserDetailsInfo;
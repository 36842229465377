import React, { useState, useEffect } from "react";
import { Box, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import MedicalFilter from '../PatientSearch/MedicalFilter';
import MedicalRecord from '../PatientSearch/MedicalRecord';
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import useAuthStore from "../../hooks/useAuthStore";
import Swal from 'sweetalert2'; // SweetAlert for alerts


export default function RichiesteCertificati() {

    const [open, setOpen] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const handleOpen = (certificate) => {
        setSelectedCertificate(certificate);
        setOpen(true);
    
        // Only call updateStateCertificate if the certificate is not already in state 2
        if (certificate.stateId !== 2) {
            updateStateCertificate(certificate.instanceId);
        }
    };

    const handleClose = () => {
        setSelectedCertificate(null);
        setOpen(false);
    };

    const [openrecord, setOpenrecord] = React.useState(false);
    const recordOpen = () => setOpenrecord(true);
    const recordClose = () => setOpenrecord(false);

    const [reject, setReject] = React.useState(false);
    // const rejectOpen = () => setReject(true);
    const rejectOpen = (certificate) => {
        setSelectedCertificate(certificate);
        setReject(true);
    };
    const rejectClose = () => setReject(false);
    const [rejectReason, setRejectReason] = useState("");
    const [rejectionError, setRejectionError] = useState(null); // For displaying error messages

    const [certificateModalopen, certificateModal] = React.useState(false);
    const certificateModalShow = () => certificateModal(true);
    const certificateModalClose = () => certificateModal(false);

    const [certificate, certificateName] = React.useState(false);
    const certificateShow = () => certificateName(true);
    const certificateClose = () => certificateName(false);

    const [service, serviceName] = React.useState(false);
    const serviceShow = () => serviceName(true);
    const serviceClose = () => serviceName(false);

    const [sentcertificate, Certificatesent] = React.useState(false);
    const sentcertificateShow = () => Certificatesent(true);
    const sentcertificateClose = () => Certificatesent(false);

    const idPediatrician = localStorage.getItem("userId");
    const { token } = useAuthStore();

    const [certificates, setCertificates] = useState([]);
    const [stateIds, setStateIds] = useState([1, 2]); // Default to "Nuove richieste"
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState("");

    // Utility to calculate time difference
    const getTimeDifference = (dateString) => {
        const now = new Date();
        const requestDate = new Date(dateString);
        const differenceInSeconds = Math.floor((now - requestDate) / 1000);

        if (differenceInSeconds < 60) return "Adesso"; // less than 1 minute
        if (differenceInSeconds < 3600) return `${Math.floor(differenceInSeconds / 60)} min fa`; // less than 1 hour
        if (differenceInSeconds < 86400) return `${Math.floor(differenceInSeconds / 3600)} h fa`; // less than 1 day
        return `${Math.floor(differenceInSeconds / 86400)} giorni fa`; // days ago
    };

    const fetchCertificate = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${Constants.notiCertificateList}`,
                {
                    idPediatrician,
                    stateIds, // Use the dynamic stateIds
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status === 1) {
                setCertificates(response.data.data || []);
            } else {
                setApiError(response.data.message || "Failed to retrieve certificates.");
            }
        } catch (error) {
            console.error("Error fetching certificate:", error);
            setApiError("Failed to retrieve certificates. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const updateStateCertificate = async (instanceId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${Constants.updateStateCertificate}`,
                {
                    instanceId, // Pass the instanceId dynamically
                    stateId: 2, // Move the certificate to "under progress" state
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            if (response.data.status === 1) {
                // Re-fetch the certificate list after a successful update
                await fetchCertificate();
            } else {
                setApiError(response.data.message || "Failed to update certificate state.");
            }
        } catch (error) {
            console.error("Error updating certificate state:", error);
            setApiError("Failed to update certificate state. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCertificate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIds]); // Refetch data whenever stateIds changes


    const handleRejectSubmit = async () => {
        const rejectionPayload = {
            instanceId: selectedCertificate.instanceId,
            idPediatrician: idPediatrician,
            rejectionReason: rejectReason,
        };

        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            const response = await fetch(`${Constants.rejectCertificate}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(rejectionPayload),
            });

            if (!response.ok) {
                // Handle non-200 status codes and throw a custom error with the response message
                const errorData = await response.json();
                throw new Error(
                    errorData.message || "Failed to reject the certificate request."
                );
            }

            const result = await response.json();

            // Check if rejection reason is required
            if (
                result.status === 0 &&
                result.message === "Il motivo del rifiuto è obbligatorio."
            ) {
                // Show the message using SweetAlert
                Swal.fire({
                    icon: "error",
                    title: "Il motivo del rifiuto è obbligatorio",
                    text: "Si prega di fornire un motivo per il rifiuto prima di inviare.",
                });
                return;
            }

            // Handle success: Show success alert and refresh the data
            if (result.status === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Successo!",
                    text: result.message || "Richiesta di certificato rifiutata con successo.",
                });

                // Close the modal and reset the state
                setReject(false);          // Close rejection modal
                setRejectReason("");       // Reset rejection reason field
                setRejectionError(null);   // Reset any errors related to rejection

                setSelectedCertificate(null);
                setOpen(false);

                // Call fetchCertificate to refresh the data
                fetchCertificate();        // Reload the certificate list
            }

        } catch (error) {
            // Handle errors and display the exact message from the API response
            const errorMessage = error.message || "Si è verificato un errore imprevisto.";
            setRejectionError(errorMessage);

            // Show the error message using SweetAlert
            Swal.fire({
                icon: "error",
                title: "errore",
                text: errorMessage,
            });
        }
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };




    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/notification"><i class="fa-solid fa-angle-left mr-2"></i> Notifiche</Link>
                                <span>/</span>
                                <span>Richieste certificati</span>
                            </div>
                            <Grid container spacing={3} className='justify-center'>
                                <Grid item lg={5} md={12} xs={12}>
                                    <h2 className='text-2xl text-center font-semibold mb-8'>Richieste certificati</h2>
                                    <div className='flex gap-4 justify-center mb-8'>
                                        <Link
                                            to="#"
                                            onClick={() => setStateIds([1])} // Pass 1 for "Nuove richieste"
                                            className={`px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all ${stateIds.length === 1 && stateIds[0] === 1 ? "bg-gray-600 text-white" : ""
                                                }`}
                                        >
                                            Nuove richieste
                                        </Link>
                                        <Link
                                            to="#"
                                            onClick={() => setStateIds([2])} // Pass 2 for "Casi aperti"
                                            className={`px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all ${stateIds.length === 1 && stateIds[0] === 2 ? "bg-gray-600 text-white" : ""
                                                }`}
                                        >
                                            Casi aperti
                                        </Link>
                                    </div>
                                    {!loading && certificates.length > 0 ? (
                                        certificates.map((certificate) => (
                                            <div
                                                key={certificate.instanceId}
                                                className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4 relative"
                                            >
                                                <span className="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                                <div className="bg-[#86D8B0] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7">
                                                    <img
                                                        alt="Wait..."
                                                        src="assets/images/a-icon.png"
                                                        className="md:h-14 h-12 mx-auto"
                                                    />
                                                </div>
                                                <div className="grow md:px-4 px-2">
                                                    <div className="flex justify-between mb-2">
                                                        <p className="text-base">{certificate.certificateName}</p>
                                                        <p className="text-sm min-w-14 text-gray-500">{getTimeDifference(certificate.requestDate)}</p>
                                                    </div>
                                                    <h2 className="md:text-xl text-lg font-bold mb-1">
                                                        {certificate.patientFirstName} {certificate.patientLastName}
                                                    </h2>
                                                    <div className="flex justify-between">
                                                        <p className="text-base">
                                                            {certificate.parentFirstName} {certificate.parentLastName}
                                                        </p>
                                                        <Link
                                                            to="#"
                                                            onClick={() => handleOpen(certificate)}
                                                            className="text-sm italic underline text-gray-500"
                                                        >
                                                            Vedi altro <i className="fa-solid fa-angle-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        !loading && (
                                            <div className="text-center text-red-500">
                                                {apiError || "Nessuna nuova richiesta trovata."}
                                            </div>
                                        )
                                    )}


                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[550px] w-[90%] bg-[#FAFAFA] py-6 rounded-2xl">
                    <div className='px-6'>
                        <h2 className='md:text-2xl text-xl font-bold text-center mb-6'>Richiesta certificato</h2>
                    </div>
                    <div className='flex gap-6 lg:pl-8 pl-5'>
                        <div className='py-3 px-4 shadow-lg rounded-lg grow bg-white'>
                            <div className='flex justify-between text-sm'>
                                <div>Paziente</div>
                                <div>Età: <span className='font-bold'>3 anni</span></div>
                            </div>
                            <div className='flex justify-between text-sm items-end'>
                                <div className='text-lg font-bold'>Giuseppe Rossi</div>
                                <div>Esenzioni</div>
                            </div>
                            <div className='flex justify-between text-sm'>
                                <div>Richiesto da Maria Rossi</div>
                                <div>B16, E46</div>
                            </div>
                        </div>
                        <div className='py-5 px-5 shadow-lg rounded-lg bg-white text-5xl'>
                            <Link to="#" onClick={recordOpen} className="text-[#828DEE]"> <i className="fa-sharp fa-regular fa-square-list"></i></Link>
                        </div>
                    </div>
                    <div className='lg:p-8 p-5'>
                        <h2 className='font-bold mb-1'>Richiesta</h2>
                        <h3 className='font-bold text-2xl mb-1'>Nomedelcertificato</h3>
                        <p>€ 99,99</p>
                        <div className='mt-8 text-center'>
                            <button onClick={certificateModalShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Prescrivi</button>
                            <button type='button' className="mt-5 text-red-500 underline font-semibold" onClick={rejectOpen}>Rifiuta</button>
                        </div>
                    </div>
                </Box>
            </Modal> */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {selectedCertificate ? (
                    <Box className="modal md:w-[550px] w-[90%] bg-[#FAFAFA] py-6 rounded-2xl">
                        <div className="px-6">
                            <h2 className="md:text-2xl text-xl font-bold text-center mb-6">{selectedCertificate.certificateName}</h2>
                        </div>
                        <div className="flex gap-6 lg:pl-8 pl-5">
                            <div className="py-3 px-4 shadow-lg rounded-lg grow bg-white">
                                <div className="flex justify-between text-sm">
                                    <div>Paziente</div>
                                    <div>
                                        Età:{" "}
                                        <span className="font-bold">{ calculateAge(selectedCertificate.patientDateOfBirth) || "0"} anni</span>
                                    </div>
                                </div>
                                <div className="flex justify-between text-sm items-end">
                                    <div className="text-lg font-bold">
                                        {selectedCertificate.patientFirstName} {selectedCertificate.patientLastName}
                                    </div>
                                    <div>Luogo di nascita</div>
                                </div>
                                <div className="flex justify-between text-sm">
                                    <div>Richiesto da {selectedCertificate.parentFirstName} {selectedCertificate.parentLastName}</div>
                                    <div>{selectedCertificate?.patientPlaceOfBirth || "N/A"}</div>
                                </div>
                            </div>
                            <div className="py-5 px-5 shadow-lg rounded-lg bg-white text-5xl">
                                <Link to={`/patient-medical-record/${selectedCertificate.patientId}`} className="text-[#828DEE]">
                                    <i className="fa-sharp fa-regular fa-square-list"></i>
                                </Link>

                            </div>
                        </div>
                        <div className="lg:p-8 p-5">
                            <h2 className="font-bold mb-1">Richiesta</h2>
                            <h3 className="font-bold text-2xl mb-1">{selectedCertificate.certificateName}</h3>
                            <p>€ {selectedCertificate.certificateCost || "0.00"}</p>
                            <div className="mt-8 text-center">
                                <Link to={`/view-certificate-request/${selectedCertificate.instanceId}`}
                                    className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                >
                                    Prescrivi
                                </Link>

                                <button
                                    type="button"
                                    className="mt-5 text-red-500 underline font-semibold"
                                    onClick={() => rejectOpen(selectedCertificate)}
                                >
                                    Rifiuta
                                </button>
                            </div>
                        </div>
                    </Box>
                ) : (
                    <Box className="modal md:w-[550px] w-[90%] bg-[#FAFAFA] py-6 rounded-2xl">
                        <p className="text-center text-gray-500">Loading...</p>
                    </Box>
                )}
            </Modal>




            <Modal
                open={openrecord}
                onClose={recordClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal lg:w-[1000px] w-[95%] bg-[#F8FFFC] md:p-6 p-6 rounded-2xl">
                    <div className='flex justify-between mb-2 gap-3'>
                        <h2 className=' text-base text-[#828DEE]'><i class="fa-regular fa-square-list"></i> Cartella clinica</h2>
                        <div><button onClick={recordClose} type='button' className='text-xl '><i class="fa-solid fa-circle-xmark"></i></button></div>
                    </div>
                    <form>
                        <div className='mt-14'>
                            <MedicalFilter />
                        </div>
                        <MedicalRecord />
                    </form>
                </Box>
            </Modal>

            <Modal
                open={reject}
                onClose={rejectClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal w-[75%] bg-transparent">
                    <Grid container spacing={5}>
                        <Grid item lg={5}>
                            <div className='bg-white py-8 rounded-2xl'>
                                <div className="px-6">
                                    <h2 className="md:text-2xl text-xl font-bold text-center mb-6">{selectedCertificate?.certificateName}</h2>
                                </div>
                                <div className="flex gap-6 lg:pl-8 pl-5">
                                    <div className="py-3 px-4 shadow-lg rounded-lg grow bg-white">
                                    <div className="flex justify-between text-sm">
                                        <div>Paziente</div>
                                        <div>
                                            Età:{" "}
                                            <span className="font-bold">{ calculateAge(selectedCertificate?.patientDateOfBirth) || "0"} anni</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between text-sm items-end">
                                        <div className="text-lg font-bold">
                                            {selectedCertificate?.patientFirstName} {selectedCertificate?.patientLastName}
                                        </div>
                                        <div>Luogo di nascita</div>
                                    </div>
                                    <div className="flex justify-between text-sm">
                                        <div>Richiesto da {selectedCertificate?.parentFirstName} {selectedCertificate?.parentLastName}</div>
                                        <div>{selectedCertificate?.patientPlaceOfBirth || "N/A"}</div>
                                    </div>
                                    </div>
                                    <div className="py-5 px-5 shadow-lg rounded-lg bg-white text-5xl">
                                        <Link to={`/patient-medical-record/${selectedCertificate?.patientId}`} className="text-[#828DEE]">
                                            <i className="fa-sharp fa-regular fa-square-list"></i>
                                        </Link>

                                    </div>
                                </div>
                                <div className="lg:p-8 p-5">
                                    <h2 className="font-bold mb-1">Richiesta</h2>
                                    <h3 className="font-bold text-2xl mb-1">{selectedCertificate?.certificateName}</h3>
                                    <p>€ {selectedCertificate?.certificateCost || "0.00"}</p>
                                    <div className="mt-8 text-center">
                                        <Link to={`/view-certificate-request/${selectedCertificate?.instanceId}`}
                                            className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                        >
                                            Prescrivi
                                        </Link>

                                        <button
                                            type="button"
                                            className="mt-5 text-red-500 underline font-semibold"
                                            onClick={() => rejectOpen(selectedCertificate)}
                                        >
                                            Rifiuta
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={7}>
                            <div className='bg-white p-8 rounded-2xl min-h-full relative'>
                                <button onClick={rejectClose} type="button" className="text-xl absolute right-5 top-5"><i class="fa-solid fa-circle-xmark"></i></button>
                                <h2 className="md:text-2xl text-xl font-bold text-center mb-3">Rifiuta richiesta di certificato</h2>
                                <h3 className='text-center font-semibold text-lg mb-5'>Scrivi il motivo del rigetto</h3>
                                {rejectionError && <p className="text-center text-red-500 mb-3">{rejectionError}</p>}
                                <div className='lg:w-[70%] mx-auto mt-5'>
                                    <div className='mb-8'>
                                        <TextField
                                            label="Inserisci motivo"
                                            fullWidth
                                            value={rejectReason}
                                            onChange={(e) => setRejectReason(e.target.value)}
                                            variant="outlined"
                                            multiline
                                            className="bg-white p-4 rounded-md"
                                            rows={4}
                                        />
                                    </div>
                                    <div className='mb-10'>
                                        <button onClick={handleRejectSubmit} className="block text-center leading-[54px] w-full mx-auto h-[54px] shadow-0 bg-red-500 rounded-full text-white font-semibold hover:bg-red-600 transition-all">Rifiuta</button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={certificateModalopen}
                onClose={certificateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[650px] w-[90%] bg-[#F8FFFC]  rounded-2xl">
                    <div className='pt-6 pb-6'>
                        <button type="button" onClick={certificateModalClose} className="absolute top-4 right-4 text-2xl"><i className="fa-solid fa-circle-xmark"></i></button>
                        <h2 className='md:text-2xl text-xl font-bold text-center mb-8'>Seleziona il certificato</h2>
                        <div className='mx-auto md:w-[320px]'>
                            <form>
                                <div className="relative">
                                    <input type="text" className="pl-10 w-full py-2 border border-gray-300 rounded-lg" placeholder="Cerca" />
                                    <button className="absolute top-2 left-3 text-gray-400"><i class="fa-regular fa-magnifying-glass"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                        <FormControl className='w-full'>
                            <RadioGroup name="certificate" className='radiobggroup'>
                                <FormControlLabel style={{ margin: '0' }} value="1" control={<Radio />} label=" Lorem ipsum dolor sit amet, consectetur a	" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="2" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="3" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="4" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="5" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                                <FormControlLabel style={{ margin: '0' }} className='m-0' value="6" control={<Radio />} label="Lorem ipsum dolor sit amet, consectetur a" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className='p-4 text-center border-t'>
                        <button onClick={certificateShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Inserisci</button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={certificate}
                onClose={certificateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal w-[75%] bg-transparent">
                    <Grid container spacing={5}>
                        <Grid item lg={5}>
                            <div className='bg-white py-8 rounded-2xl'>
                                <div className='px-6'>
                                    <h2 className='md:text-2xl text-xl font-bold text-center mb-6'>Richiesta certificato</h2>
                                </div>
                                <div className='flex gap-6 lg:pl-8 pl-5'>
                                    <div className='py-3 px-4 shadow-lg rounded-lg grow bg-white'>
                                        <div className='flex justify-between text-sm'>
                                            <div>Paziente</div>
                                            <div>Età: <span className='font-bold'>3 anni</span></div>
                                        </div>
                                        <div className='flex justify-between text-sm items-end'>
                                            <div className='text-lg font-bold'>Giuseppe Rossi</div>
                                            <div>Esenzioni</div>
                                        </div>
                                        <div className='flex justify-between text-sm'>
                                            <div>Richiesto da Maria Rossi</div>
                                            <div>B16, E46</div>
                                        </div>
                                    </div>
                                    <div className='py-5 px-5 shadow-lg rounded-lg bg-white text-5xl'>
                                        <Link to="#" onClick={recordOpen} className="text-[#828DEE]"> <i className="fa-sharp fa-regular fa-square-list"></i></Link>
                                    </div>
                                </div>
                                <div className='lg:p-8 p-5'>
                                    <h2 className='font-bold mb-1'>Richiesta</h2>
                                    <h3 className='font-bold text-2xl mb-1'>Nomedelcertificato</h3>
                                    <p>€ 99,99</p>
                                    <div className='mt-8 text-center'>
                                        <button onClick={certificateModalShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Prescrivi</button>
                                        <button type='button' className="mt-5 text-red-500 underline font-semibold" onClick={rejectOpen}>Rifiuta</button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={7}>
                            <div className='w-full bg-white min-h-full rounded-2xl p-8'>
                                <h2 class="md:text-2xl text-xl font-bold text-center mb-8">Nomedelcertificato</h2>
                                <button onClick={certificateClose} type="button" className="text-xl absolute right-5 top-5"><i class="fa-solid fa-circle-xmark"></i></button>
                                <div className='mb-8'>
                                    <TextField fullWidth multiline rows={12} />
                                </div>
                                <div>
                                    <button onClick={serviceShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Continua</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>

            <Modal
                open={service}
                onClose={serviceClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal w-[75%] bg-transparent">
                    <Grid container spacing={5}>
                        <Grid item lg={5}>
                            <div className='bg-white py-8 rounded-2xl'>
                                <div className='px-6'>
                                    <h2 className='md:text-2xl text-xl font-bold text-center mb-6'>Richiesta certificato</h2>
                                </div>
                                <div className='flex gap-6 lg:pl-8 pl-5'>
                                    <div className='py-3 px-4 shadow-lg rounded-lg grow bg-white'>
                                        <div className='flex justify-between text-sm'>
                                            <div>Paziente</div>
                                            <div>Età: <span className='font-bold'>3 anni</span></div>
                                        </div>
                                        <div className='flex justify-between text-sm items-end'>
                                            <div className='text-lg font-bold'>Giuseppe Rossi</div>
                                            <div>Esenzioni</div>
                                        </div>
                                        <div className='flex justify-between text-sm'>
                                            <div>Richiesto da Maria Rossi</div>
                                            <div>B16, E46</div>
                                        </div>
                                    </div>
                                    <div className='py-5 px-5 shadow-lg rounded-lg bg-white text-5xl'>
                                        <Link to="#" onClick={recordOpen} className="text-[#828DEE]"> <i className="fa-sharp fa-regular fa-square-list"></i></Link>
                                    </div>
                                </div>
                                <div className='lg:p-8 p-5'>
                                    <h2 className='font-bold mb-1'>Richiesta</h2>
                                    <h3 className='font-bold text-2xl mb-1'>Nomedelcertificato</h3>
                                    <p>€ 99,99</p>
                                    <div className='mt-8 text-center'>
                                        <button onClick={certificateModalShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Prescrivi</button>
                                        <button type='button' className="mt-5 text-red-500 underline font-semibold" onClick={rejectOpen}>Rifiuta</button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={7}>
                            <div className='w-full bg-white min-h-full rounded-2xl p-8'>
                                <h2 class="md:text-2xl text-xl font-bold text-center mb-10">Prestazioni</h2>
                                <button onClick={serviceClose} type="button" className="text-xl absolute right-5 top-5"><i class="fa-solid fa-circle-xmark"></i></button>
                                <div className='mb-10'>
                                    <div className='flex gap-3 justify-center items-center mb-10'>
                                        <div>Vuoi effettuare uno sconto?</div>
                                        <div className='flex gap-2'>
                                            <div>
                                                <select className='border border-[#72777A] rounded px-2 h-10 w-12 text-base'>
                                                    <option>€ </option>
                                                </select>
                                            </div>
                                            <div>
                                                <input type='text' placeholder='00.00' className='border border-[#72777A] rounded px-2 h-10 w-16 text-base' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='md:w-80 mx-auto'>
                                        <h2 className='font-semibold text-lg border-b pb-2'>Prestazioni</h2>
                                        <div className='flex py-3 gap-6 justify-between border-b '>
                                            <div>Nomedecertificato</div>
                                            <div>€ 30,00</div>
                                        </div>
                                        <div className='flex py-3 gap-6 justify-between'>
                                            <div className='font-semibold grow text-right'>Totale</div>
                                            <div className='font-semibold'>€ 30,00</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={sentcertificateShow} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Conferma pagamento</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={sentcertificate}
                onClose={sentcertificateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[650px] w-[90%] bg-[#AAB5FF] p-6 rounded-2xl">
                    <div className='w-full mx-auto'>
                        <Grid container spacing={4} className='items-center'>
                            <Grid item lg={6} md={6} xs={12}>
                                <img alt='Wait...' src='assets/images/certificate-pattern.png' />
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className='text-white text-center'>
                                <h2 class="md:text-2xl text-xl font-bold text-center mb-12">Certificato inviato</h2>
                                <p>Il paziente riceverà una notifica con il certificato.</p>
                                <div className='mt-12'>
                                    <button onClick={sentcertificateShow} className="block text-center leading-[54px] w-60 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Chiudi</button>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

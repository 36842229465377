import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { Constants } from '../../endPoints';
import ReactQuill from 'react-quill';
import useAuthStore from '../../hooks/useAuthStore';
import 'react-quill/dist/quill.snow.css';
import { useUser } from '../../hooks/useUserContext';

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Certificate Name is required'),
  category: Yup.string().required('Category Type is required'),
  cost: Yup.number()
    .required('Cost is required')
    .typeError('Cost must be a number')
    .test(
      'cost-validation',
      'Cost must be greater than zero for paid certificates',
      function(value) {
        const { category } = this.parent;
        if (category === 'Paid') {
          return value > 0;
        }
        return true; // No validation needed if category is not 'Paid'
      }
    ),
  categoryId: Yup.string().required('Select Certificate is required'),
  textToBody: Yup.string().required('Certificate Body is required'),
  enabledFlag: Yup.boolean(),
});

export default function AddCertificate() {
  const navigate = useNavigate();
  const { token } = useAuthStore();
  const { user } = useUser();
  const [apiError, setApiError] = useState(null);
  const [masterCertificates, setMasterCertificates] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false); // Track if we are updating a certificate
  const [initialValues, setInitialValues] = useState({
    name: '',
    category: '',
    cost: '',
    categoryId: '',
    textToBody: '',
    enabledFlag: true,
  });

  // TAG Filtering and Suggestions Methods
  const [editorHtml, setEditorHtml] = useState('');
  const { certId } = useParams(); // Get the certificateId from the URL
  const [suggestions, setSuggestions] = useState([]);
  const [cursorPos, setCursorPos] = useState({ top: 0, left: 0 });
  const editorRef = useRef(null);

  const handleCertificateChange = (e, setFieldValue) => {
    const selectedId = e.target.value;
    const certificate = masterCertificates.find((cert) => cert.id === selectedId);

    setSelectedCertificate(certificate);

    if (certificate) {
      // Update the form fields based on the selected certificate
      setFieldValue('name', certificate.name);
      setFieldValue('category', certificate.type === 'Free' ? 'Free' : 'Paid');
      setFieldValue('cost', certificate.cost ? certificate.cost : '0');
      setFieldValue('textToBody', certificate.textToBody);
      setEditorHtml(certificate.textToBody);
    }
  };

  const fetchMasterCertificates = async () => {
    setCategoryLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure you have the token available here
        },
      };

      const response = await axios.get(`${Constants.certificateCategories}?q=&page=1&limit=1000&sortColumn=&sortOrder=asc`, config);
      const { data } = response;
      setMasterCertificates((data && data.data.categories) || []);
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setMasterCertificates([]);
    } finally {
      setCategoryLoading(false);
    }
  };

  // Define the toolbar options
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
    ],
  };

  // Modified queryTags to accept prefix and return tags
  const queryTags = async (prefix) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure you have the token available here
        },
      };

      const keywordParam = prefix ? `&keyword=${prefix}` : '';
      const response = await axios.get(
        `${Constants.certificateTags}?page=1&limit=100${keywordParam}`,
        config
      );
      const data = response.data;
      const tags = (data && data.tags.map((tag) => tag.name)) || [];
      return tags;
    } catch (error) {
      console.error('Error fetching tags:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchMasterCertificates();
  }, []);

  // for update Mode
  useEffect(() => {
    if (certId) {
      // If certId is present, we're in update mode
      setIsUpdate(true);
      const fetchCertificateDetails = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${Constants.certificateList}/${certId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Accessed-By': 'Generic',
            },
          });
          const certificateData = response && response.data && response.data.data;

          setInitialValues({
            name: certificateData.certificateName,
            category: certificateData.category,
            cost: certificateData.cost || 0,
            categoryId: certificateData.categoryId,
            textToBody: certificateData.textToBody,
            enabledFlag: certificateData.enabled || true,
          });
          setEditorHtml(certificateData.textToBody);

          // Fetch master certificates
          fetchMasterCertificates();
        } catch (error) {
          console.error('Error fetching certificate details:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchCertificateDetails();
    }
  }, [certId, token]);

  // Modified handleTextChange
  const handleTextChange = async (content) => {
    setEditorHtml(content);
    // Get the Quill editor instance
    const quill = editorRef.current.getEditor();
    const range = quill.getSelection(true); // Get current cursor position

    if (range) {
      // Get the plain text up to the cursor position
      const textBeforeCursor = quill.getText(0, range.index); // Get the raw text before the cursor position

      // Find the last occurrence of @ followed by a word (the current tag being typed)
      const match = textBeforeCursor.match(/@(\w*)$/);

      if (match) {
        // Get the prefix for the tag (excluding the @)
        const prefix = match[1];

        // Fetch suggestions from the API
        const foundTags = await queryTags(prefix);
        setSuggestions(foundTags);
      } else {
        // If no @tags are found, clear suggestions
        setSuggestions([]);
      }
    }
  };

  const handleQuillChange = async (content, delta, source, editor, setFieldValue) => {
    await handleTextChange(content);
    setFieldValue('textToBody', content);
    if (editor) {
      const range = editor.getSelection();
      if (range) {
        const bounds = editor.getBounds(range.index);
        setCursorPos({
          top: bounds.bottom,
          left: bounds.left,
        });
      }
    }
  };
  
  // Handle the click on a suggestion
  const handleTagClick = (tag) => {
    const quill = editorRef.current.getEditor(); // Get the Quill editor instance

    // Get the current cursor position
    const range = quill.getSelection(true);
    if (range) {
      const textBeforeCursor = quill.getText(0, range.index); // Get text before the cursor

      // Find the index of the last '@' symbol
      const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');

      if (lastAtSymbolIndex !== -1) {
        // Delete the text from '@' to the current cursor position
        quill.deleteText(lastAtSymbolIndex, range.index - lastAtSymbolIndex);

        // Insert the selected tag at the position of '@'
        quill.insertText(lastAtSymbolIndex, `{${tag}}`);

        // Move the cursor to the right of the inserted tag
        quill.setSelection(lastAtSymbolIndex + tag.length + 2); // +2 accounts for the `{}` braces

        // Clear suggestions after selecting a tag
        setSuggestions([]);
      }
    }
  };

  const createUserCertificate = async (values) => {
    setLoading(true);
    setApiError(null); // Reset any previous errors
    try {
      // Augment the payload with idPediatrician
      const payload = { ...values, idPediatrician: user.id };

      if (isUpdate) {
        const response = await axios.put(`${Constants.certificateList}/${certId}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-accessed-by': 'Generic',
          },
        });
        if (response.status === 200 || response.status === 201) {
          navigate('/certificates');
        } else {
          setApiError(response.data.message || 'Failed to update certificate.');
        }
      } else {
        const response = await axios.post(`${Constants.certificateList}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-accessed-by': 'Generic',
          },
        });

        if (response.status === 200 || response.status === 201) {
          navigate('/certificates');
        } else {
          setApiError(response.data.message || 'Failed to create certificate.');
        }
      }

      // Optionally, refresh the master certificates list
      fetchMasterCertificates();
    } catch (error) {
      console.error('Error creating/updating certificate:', error);
      if (error.response) {
        // Server responded with a status other than 2xx
        setApiError(error.response.data.message || 'An error occurred.');
      } else if (error.request) {
        // Request was made but no response received
        setApiError('No response from server. Please try again later.');
      } else {
        // Something else caused the error
        setApiError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs">
        <Link to="/certificates">
          <i className="fa-solid fa-angle-left mr-2"></i> Lista certificati{' '}
        </Link>
        <span>/</span>
        <span>{isUpdate ? 'Update Certificate' : 'Add Certificate'}</span>
      </div>
      <div className="mt-10">
        <Grid container spacing={3} className="justify-center">
          <Grid item lg={9} md={12} xs={12}>
            <h2 className="text-center mb-8 text-xl font-semibold">
              {isUpdate ? 'Update Certificate' : 'Add Certificate'}
            </h2>

            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                createUserCertificate(values);
                setSubmitting(false);
              }}
            >
              {({ handleChange, handleBlur, values, setFieldValue, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3} className="justify-center">
                    <Grid item lg={9} md={12} sm={12} xs={12}>
                      {!isUpdate && (
                        <div className="mb-5">
                          <FormControl fullWidth>
                            <InputLabel id="certificate-label">Select Certificate</InputLabel>
                            <Select
                              label="Select Certificate"
                              labelId="certificate-label"
                              id="categoryId"
                              name="categoryId"
                              value={values.categoryId}
                              onChange={(e) => {
                                handleCertificateChange(e, setFieldValue);
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="">Select</MenuItem>
                              {categoryLoading || loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                masterCertificates.map((cert) => (
                                  <MenuItem key={cert.id} value={cert.id}>
                                    {cert.name}
                                  </MenuItem>
                                ))
                              )}
                            </Select>
                            <ErrorMessage name="categoryId" component="div" className="error-message" />
                          </FormControl>
                        </div>
                      )}
                      <div className="mb-5">
                        <TextField
                          label="Certificate Name"
                          variant="outlined"
                          name="name"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          helperText={<ErrorMessage name="name" />}
                        />
                      </div>
                      <div className="mb-5">
                        <FormControl fullWidth>
                          <InputLabel id="category-label">Category Type</InputLabel>
                          <Select
                            label="Category Type"
                            labelId="category-label"
                            id="category"
                            name="category"
                            value={values.category}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === 'Paid') {
                                if (values.cost <= 0) { // Automatically update cost if current value is not valid
                                  setFieldValue('cost', '');
                                }
                              } else {
                                setFieldValue('cost', '0'); // Set cost to 0 if category is not Paid
                              }
                            }}
                            onBlur={handleBlur}
                          >
                            <MenuItem value="Paid">Paid</MenuItem>
                            <MenuItem value="Free">Free</MenuItem>
                          </Select>
                          <ErrorMessage name="category" component="div" className="error-message" />
                        </FormControl>
                      </div>

                      {values.category === 'Paid' && (
                        <div className="mb-5">
                          <TextField
                            label="€ Cost"
                            variant="outlined"
                            name="cost"
                            type="number" // Ensure the input type is number
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cost}
                            helperText={<ErrorMessage name="cost" />}
                          />
                        </div>
                      )}

                      <div className="mb-5" style={{ height: '50vh', position: 'relative' }}>
                        <ReactQuill
                          id="textToBody"
                          name="textToBody"
                          value={editorHtml}
                          onChange={(content, delta, source, editor) =>
                            handleQuillChange(content, delta, source, editor, setFieldValue)
                          }
                          placeholder="Write your certificate body here..."
                          modules={modules}
                          style={{ height: '70%', width: '100%' }}
                          ref={editorRef}
                        />
                      </div>
                      <div>
                        {suggestions.length > 0 && (
                          <ul
                            style={{
                              listStyleType: 'none',
                              padding: '0',
                              position: 'absolute',
                              border: '1px solid #ddd',
                              backgroundColor: 'white',
                              zIndex: 1000,
                              maxHeight: '150px',
                              overflowY: 'auto',
                              width: '250px',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                              borderRadius: '4px',
                            }}
                          >
                            {suggestions.map((tag) => (
                              <li
                                key={tag}
                                onClick={() => handleTagClick(tag)}
                                style={{
                                  cursor: 'pointer',
                                  padding: '8px 12px',
                                  borderBottom: '1px solid #eee',
                                  transition: 'background-color 0.2s',
                                }}
                              >
                                {tag}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>

                      <div className="mb-5">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <button
                            type="submit"
                            className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                          >
                            {isUpdate ? 'Update Certificate' : 'Add Certificate'}
                          </button>
                        )}
                      </div>
                      {apiError && <div className="text-red-500">{apiError}</div>}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useUser } from '../../hooks/useUserContext';
import useAuthStore from '../../hooks/useAuthStore';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints';

export default function Blockedlist() {
  const { user } = useUser();
  const { token } = useAuthStore();
  const [blockedItems, setBlockedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Manage loading state
  const [error, setError] = useState(null); // Manage error state

  useEffect(() => {
    const fetchBlockedList = async () => {
      setIsLoading(true); // Start loading
      setError(null); // Reset previous errors
      try {
        const response = await fetch(
            `${Constants.pediatricianBlockList}?idPediatrician=${user.id}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json', // Ensure correct headers
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 1 && data.blocklist) {
          setBlockedItems(data.blocklist);
        } else {
          setError(data.message || 'Failed to fetch blocklist.');
        }
      } catch (err) {
        console.error('Error fetching blocklist:', err);
        setError(err.message || 'An unexpected error occurred.');
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    if (user?.id && token) {
      fetchBlockedList();
    }
  }, [user, token]);

  // Implement handleUnblock inside the component
  const handleUnblock = async (blockId) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Sei sicuro?',
      text: 'Vuoi sbloccare questo utente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sì, sblocca!',
      cancelButtonText: 'Annulla',
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(Constants.unblockParent, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
        
            id: blockId,
          
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 1) {
          // Remove the unblocked user from the blockedItems state
          setBlockedItems((prevItems) =>
            prevItems.filter((item) => item.id !== blockId)
          );

          // Show success alert
          Swal.fire({
            title: 'Sbloccato!',
            text: 'L\'utente è stato sbloccato con successo.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        } else {
          throw new Error(data.message || 'Failed to unblock user.');
        }
      } catch (err) {
        console.error('Error unblocking user:', err);
        Swal.fire({
          title: 'Errore',
          text: err.message || 'Si è verificato un errore durante lo sblocco.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-4">
        <p className="text-gray-500">Loading blocked users...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center py-4">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {blockedItems.length === 0 ? (
        <div className="flex justify-center items-center py-4">
          <p className="text-gray-500">Nessun utente bloccato trovato.</p>
        </div>
      ) : (
        blockedItems.map((blockedUser) => (
          <div
            key={blockedUser.id}
            className="flex gap-4 mb-2 py-3 px-4 shadow-lg rounded-lg items-center border"
          >
            <div>
              {/* Display the parent's photo if available, otherwise a default image */}
              <img
                src={blockedUser.parent?.photo || 'assets/images/user.png'}
                alt={`${blockedUser.parent?.firstName || ''} ${blockedUser.parent?.lastName || ''}`}
                className="w-[42px] h-[42px] rounded-full object-cover"
              />
            </div>
            <div className="flex gap-3 justify-between grow">
              {/* Show the parent's first and last name */}
              <h3 className="text-lg font-semibold">
                {blockedUser.parent?.firstName} {blockedUser.parent?.lastName}
              </h3>
              <div>
                <button
                  className="text-[#828DEE] underline text-sm"
                  onClick={() => handleUnblock(blockedUser.id)}
                >
                  Sblocca
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  Container,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import useAuthStore from "../../hooks/useAuthStore";
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import { useUser } from "../../hooks/useUserContext";



export default function ViewCertificate() {
  const { token } = useAuthStore();
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  // console.log(user)

  const [certificateData, setCertificateData] = useState(null);
  const [patientData, setPatientData] = useState(null); // New state for patient data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [patientLoading, setPatientLoading] = useState(false); // Loading state for patient data
  const [patientError, setPatientError] = useState(null); // Error state for patient data
  const [discountType, setDiscountType] = useState('€'); // Default to fixed discount
  const [discountValue, setDiscountValue] = useState(0);

  // Reference for the editable content
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${Constants.assignedcertificateList}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === 1) {
          setCertificateData(response.data.data);

          // After successfully fetching certificate data, fetch patient data
          if (response.data.data.patientId) {
            fetchPatientData(response.data.data.patientId);
          } else {
            setPatientError("L'ID paziente manca nei dati del certificato.");
          }
        } else {
          setError(
            response.data.message || "Impossibile recuperare i dati del certificato."
          );
        }
      } catch (err) {
        console.error(err);
        setError("Si è verificato un errore durante il recupero del certificato.");
      } finally {
        setLoading(false);
      }
    };

    const fetchPatientData = async (patientId) => {
      setPatientLoading(true);
      setPatientError(null);
      try {
        const response = await axios.get(
          `${Constants.getPatient}/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === 1) {
          setPatientData(response.data.patient);
        } else {
          setPatientError(
            response.data.message || "Impossibile recuperare i dati del paziente."
          );
        }
      } catch (err) {
        console.error(err);
        setPatientError("Si è verificato un errore durante il recupero dei dati del paziente.");
      } finally {
        setPatientLoading(false);
      }
    };

    fetchCertificate();
  }, [id, token]);
  console.log(patientData);

  // Function to process and replace placeholders with actual data
  const processContent = (html) => {
    if (!certificateData || !patientData) return "";

    const replacements = {
      "{Pediatricians_firstName}": user.firstName || "",
      "{Pediatricians_lastName}": user.lastName || "",
      "{PediatricianAddress_street}": user.addresses[0]?.street || "",
      "{PediatricianAddress_region}": user.addresses[0]?.regionName || "",
      "{PediatricianAddress_province}":
        `${user.addresses[0]?.province}, ${user.addresses[0]?.provinceName}` ||
        "",
      "{PediatricianAddress_idCity}": user.addresses[0]?.cityName || "",
      "{PediatricianAddress_zip}": user.addresses[0]?.zip || "",
      "{Users_phone}": user.phone || "",
      "{Patients_firstName}": patientData.firstName || "",
      "{Patients_lastName}": patientData.lastName || "",
      "{Patients_dateOfBirth}": patientData.dateOfBirth
        ? new Date(patientData.dateOfBirth).toLocaleDateString()
        : "",
      "{Extra_noOfDays}": `<input type="text" name="noOfDays" placeholder="No. of days ">`,
      "{noOfDays_Text}": `<input type="text" name="noOfDays" placeholder="No. of days ">`,
      "{Extra_city}": `<input type="text" name="city" placeholder="City Name" }">`,
      "{Extra_certificateIssuanceDate}": `<input type="date" name="date" placeholder="Certificate Issue Date" value="${new Date().toISOString().split("T")[0]
        }" min="${new Date().toISOString().split("T")[0]}">`,
      "{Parents_firstName}": certificateData.parentFirstName || "",
      "{Parents_lastName}": certificateData.parentLastName || "",
      // "{Pediatricians_signature}": "PEDIATRICIANS_SIGNATURE",
      "{Pediatricians_signature}": user.signature
        ? `<img src="${user.signature}" width="200" alt="Pediatrician's Signature">`
        : "",
      "{Pediatricians_stamp}": user.stamp
        ? `<img src="${user.stamp}" width="100" alt="Pediatrician's Stamp">`
        : "",
      "{Patients_placeOfBirth}": patientData.placeOfBirth || "",
      "{Patients_taxCode}": patientData.taxCode || "",
      "{ParentAddress_type}": patientData.parents[0]?.addresses[0]?.type || "",
      "{ParentAddress_street}":
        patientData.parents[0]?.addresses[0]?.street || "",
      "{ParentAddress_province}":
        patientData.parents[0]?.addresses[0]?.province || "",
      "{ParentAddress_region}":
        patientData.parents[0]?.addresses[0]?.regionName || "",
      "{ParentAddress_idCity}":
        patientData.parents[0]?.addresses[0]?.cityName || "",
      "{ParentAddress_zip}": patientData.parents[0]?.addresses[0]?.zip || "",
      "{Extra_visitTime}": `<input type="text" name="time" placeholder="Enter time here" }">`,
      "{Extra_freeText}": `<input type="text" name="text" placeholder="Write here" }">`,
      "{Extra_fromDate}": `<input type="date" name="fromDate" placeholder="Enter from date" >`,
      "{Extra_toDate}": `<input type="date" name="toDate" placeholder="Enter to date" >`,
      // Add other replacements as needed
    };

    let processedHtml = html;
    Object.keys(replacements).forEach((key) => {
      const regex = new RegExp(key, "g");
      processedHtml = processedHtml.replace(regex, replacements[key]);
    });

    return processedHtml;
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form behavior

    const contentEditableDiv = contentRef.current;

    if (!contentEditableDiv) {
      setError("Il contenuto del modulo non è disponibile.");
      return;
    }

    const inputs = contentEditableDiv.querySelectorAll("input");
    const formData = {
      instanceId: certificateData.instanceId,
    };

    let validationErrors = [];
    inputs.forEach((input) => {
      if (!input.value.trim()) {
        const fieldName = input.name
          ? input.name.charAt(0).toUpperCase() + input.name.slice(1)
          : "Field";
        validationErrors.push(`${fieldName} is required.`);
      }
    });

    if (validationErrors.length > 0) {
      setError(validationErrors.join(" "));
      return;
    }

    setError("");

    inputs.forEach((input) => {
      formData[input.name] = input.value;
    });

    let certificateBody = contentEditableDiv.innerHTML;

    inputs.forEach((input) => {
      const inputHTML = input.outerHTML;
      const inputValue = input.value;
      certificateBody = certificateBody.replace(inputHTML, inputValue);
    });

    formData.htmlContent = certificateBody;

    // Add payment-related properties only for Paid certificates
    if (certificateData.certificateCategory === "Paid") {
      const certificateCost = Number(certificateData.certificateCost) || 0;
      const discountAmount =
        discountType === "€"
          ? Number(discountValue || 0)
          : certificateCost * (Number(discountValue || 0) / 100);
      const total = Math.max(certificateCost - discountAmount, 0);

      formData.totalCharges = certificateCost;
      formData.discountType = discountType === "€" ? "Valore" : "Percentuale";
      formData.discountTypeValue = discountValue || "0";
      formData.discountAmount = discountAmount;
      formData.amount = total;
    }

    try {
      const response = await axios.post(
        `${Constants.generateCertificate}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Modulo inviato con successo:", response.data);
        navigate("/certificate-requests");
      } else {
        throw new Error(response.data.message || "Impossibile inviare il modulo.");
      }
    } catch (error) {
      console.error("Errore durante l'invio del modulo:", error);
      setError(error.message);
    }
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a"); // Create a temporary anchor tag
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop(); // Extract the file name from the URL
    link.target = "_blank"; // Open in a new tab if needed
    document.body.appendChild(link); // Append the link to the DOM
    link.click(); // Programmatically click the link to trigger download
    document.body.removeChild(link); // Clean up
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress />
      </Container>
    );
  }


  if (!certificateData) {
    return null; // Or some fallback UI
  }

  // You might also want to handle patient loading and errors
  if (patientLoading) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress />
       
      </Container>
    );
  }

  if (patientError) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{patientError}</Alert>
      </Container>
    );
  }

  // Format the request date
  const formattedRequestDate = new Date(
    certificateData.requestDate
  ).toLocaleDateString();
  const formattedIssueDate = new Date(
    certificateData.issuedDate
  ).toLocaleDateString();

  // Helper function to calculate total services cost, ensuring a numeric result
  const certificateCost = (Number(certificateData.certificateCost) || 0);

  // Calculate discount amount based on type (fixed or percentage)
  const discountAmount = discountType === '€'
    ? Number(discountValue || 0)
    : certificateCost * (Number(discountValue || 0) / 100);

  // Amount after discount
  const total = Math.max(certificateCost - discountAmount, 0);



  return (
    <Container maxWidth="lg">
      {/* Breadcrumb Navigation */}
      <Box className="flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs">
        <Link to="/certificates" className="flex items-center">
          <i className="fa-solid fa-angle-left mr-2"></i> Lista certificati
        </Link>
        <span>/</span>
        <Link to="/certificate-requests">Richiesta di certificato</Link>
        <span>/</span>
        <span>Genera certificati</span>
      </Box>

      {/* Header Icon and Title */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Box sx={{ display: "inline-flex", justifyContent: "center", mb: 2 }}>
          <Paper
            sx={{
              p: 2,
              border: "4px solid black",
              borderRadius: 2,
              width: 56,
              height: 56,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DescriptionIcon fontSize="large" />
          </Paper>
        </Box>
        <Typography variant="h5" gutterBottom>
          {certificateData.certificateName}
        </Typography>
      </Box>

      {/* Certificate Details */}
      <Paper sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Certificate Information */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
            Categoria
            </Typography>
            <Typography variant="body1" gutterBottom>
              {certificateData.certificateCategory}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
            Costo
            </Typography>
            <Typography variant="body1" gutterBottom>
              {certificateData.certificateCost !== null
                ? `€${certificateData.certificateCost}`
                : "Gratuito"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
            Data della richiesta
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formattedRequestDate}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
            Data di emissione
            </Typography>
            <Typography variant="body1" gutterBottom>
              {certificateData.issuedDate ? formattedIssueDate : "Non rilasciato"}
            </Typography>
          </Grid>

          {/* Conditional rendering for payment details */}
          {certificateData.certificateCategory === "Paid" && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                Importo dello sconto
                </Typography>
                <Typography variant="body1" gutterBottom>
                  €{certificateData.discountAmount}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                Importo finale
                </Typography>
                <Typography variant="body1" gutterBottom>
                  €{certificateData.amount}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                ID transazione
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {certificateData.transactionId || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                Stato del pagamento
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {certificateData.paymentStatus || 'Inatteso'}
                </Typography>
              </Grid>
            </>
          )}

          {/* Optional: Display Patient Information */}
          {patientData && (
            <>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" color="text.secondary">
                Informazioni sul paziente
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body1">
                    <strong>Nome:</strong> {patientData.firstName}{" "}
                    {patientData.lastName}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Data di nascita:</strong>{" "}
                    {patientData.dateOfBirth
                      ? new Date(patientData.dateOfBirth).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Codice Fiscale:</strong> {patientData.taxCode}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Luogo di nascita:</strong> {patientData.placeOfBirth}
                  </Typography>
                  {/* Add more patient details as needed */}
                </Box>
              </Grid>
            </>
          )}
          {certificateData.uploadedFile ? (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />

              <button
                className="flex gap-3 border border-[#AAB5FD] text-[#AAB5FD] px-3 py-2 text-base rounded-full"
                variant="warning"
                size="sm"
                title="Scarica il certificato"
                onClick={() => handleDownload(certificateData.uploadedFile)}
              >
                <i className="fa-solid fa-download"></i> Scarica il certificato
              </button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2" color="text.secondary">
              Contenuto del certificato
              </Typography>
              <Box className="border p-6 rounded-lg"

                contentEditable
                suppressContentEditableWarning
                ref={contentRef}
                dangerouslySetInnerHTML={{
                  __html: processContent(certificateData.certificateTextBody),
                }}
              />
            </Grid>
          )}
          {/* Certificate Content */}
        </Grid>

        {certificateData.certificateCategory === 'Paid' && certificateData.stateId !== 4 && !certificateData.uploadedFile && (
          <div className='w-full bg-white min-h-full rounded-lg p-6 border mt-5'>
            <h2 className="md:text-2xl text-xl font-bold text-center mb-10">Prestazioni</h2>
            <div>
              <div className='flex gap-3 justify-center items-center mb-10'>
                <div>Vuoi effettuare uno sconto?</div>
                <div className='flex gap-2'>
                  <div className='flex gap-3 mt-8 mb-8 justify-center items-center'>
                    <p>Vuoi effettuare uno sconto?</p>
                    <div>
                      <select
                        className='h-[40px] border border-[#bfc4c2] rounded px-2'
                        value={discountType}
                        onChange={(e) => setDiscountType(e.target.value)}
                      >
                        <option value="€">€</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <div>
                      <TextField
                        size="small"
                        className="w-20"
                        placeholder="00.00"
                        value={discountValue || ''}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d*\.?\d*$/.test(inputValue)) {  // Allow only numbers and a single dot
                            setDiscountValue(inputValue);
                          }
                        }}
                        onBlur={() => setDiscountValue(parseFloat(discountValue) || 0)} // Convert to number on blur
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='lg:w-[400px] mx-auto'>
                <div className='flex py-3 gap-6 justify-between border-b '>
                  <div>{certificateData.certificateName}</div>
                  <div>€ {certificateCost.toFixed(2)}</div>
                </div>
                <div className='flex py-3 gap-6 justify-between border-b '>
                  <div>Sconto</div>
                  <div>€ {discountAmount.toFixed(2)}</div>
                </div>
                <div className='flex py-3 gap-6 justify-between'>
                  <div className='font-semibold grow text-right'>Totale</div>
                  <div className='font-semibold'>€ {total.toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
        )}


      </Paper>

      {/* Action Buttons */}
      {error && <Alert severity="error">{error}</Alert>}



      {certificateData.stateId !== 4 && !certificateData.uploadedFile && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <button onClick={handleSubmit} className="block text-center leading-[54px] w-72 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">

          Generare
          </button>
        </Box>
      )}
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../../hooks/useAuthStore';
import { Constants } from '../../endPoints';

export default function RichiesteSOS() {
  const navigate = useNavigate();
  const { token } = useAuthStore();
  const idPediatrician = localStorage.getItem('userId');
  const [requestList, setRequestList] = useState([]);
  const [filterType, setFilterType] = useState(null); // "New", "Open", or null for default

  const fetchData = async () => {
    try {
      if (filterType === null) {
        // Fetch the combined list (New and In-progress)
        const response = await fetch(Constants.inProgressSOS, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ idPediatrician: Number(idPediatrician) })
        });
        const data = await response.json();
        if (data.status === 1) {
          setRequestList(data.requestList || []);
        } else {
          setRequestList([]);
        }
      } else {
        // Fetch filtered list based on filterType
        const response = await fetch(`${Constants.SOSBasedOnType}?idPediatrician=${idPediatrician}&type=${filterType}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.status === 1) {
          setRequestList(data.requestList || []);
        } else {
          setRequestList([]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setRequestList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterType]);

  // A simple function to show relative time (e.g., "Adesso", "10 min fa")
  const formatTimeAgo = (dateStr) => {
    const now = new Date();
    const date = new Date(dateStr);
    const diffMs = now - date;
    const diffMin = Math.floor(diffMs / 60000);
    if (diffMin < 1) return 'Adesso';
    if (diffMin < 60) return `${diffMin} min fa`;
    // For simplicity, if it's more than an hour, just show hours
    const diffHrs = Math.floor(diffMin / 60);
    return `${diffHrs} hr fa`;
  };

  const handleFilter = (type) => {
    // If already selected the same type, revert to default (no filter)
    if (filterType === type) {
      setFilterType(null);
    } else {
      setFilterType(type);
    }
  };

  const handleStateUpdateAndRedirect = async (e, item) => {
    e.preventDefault(); // Prevent the default behavior of the link
  
    try {
      if (item.status === 'New') {
        // Call the API to update the state
        const response = await fetch(`${Constants.sosStateUpdate}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: item.id,
            state: 'Open',
          }),
        });
  
        const result = await response.json();
  
        if (response.ok && result.status === 1) {
          console.log('State updated successfully:', result);
        } else {
          console.error('Failed to update state:', result);
        }
      }
  
      // Redirect to the chat page
      navigate(`/chat-sos/${item.id}`); // Pass the request ID dynamically
    } catch (error) {
      console.error('Error updating state or redirecting:', error);
    }
  };


  return (
    <>
      <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
          <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div>
              <div className='flex gap-3 mb-6 font-semibold'>
                <Link to="/notification"><i className="fa-solid fa-angle-left mr-2"></i> Notifiche</Link>
                <span>/</span>
                <span>Richieste SOS</span>
              </div>
              <Grid container spacing={3} className='justify-center'>
                <Grid item lg={5} md={12} xs={12}>
                  <h2 className='text-2xl text-center font-semibold mb-8'>Richieste SOS</h2>
                  <div className='flex gap-4 justify-center mb-8'>
                    <button 
                      onClick={() => handleFilter('New')} 
                      className="px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all"
                      style={{ backgroundColor: filterType === 'New' ? '#999' : 'transparent', color: filterType === 'New' ? '#fff' : 'inherit' }}
                    >
                      Nuove richieste
                    </button>
                    <button 
                      onClick={() => handleFilter('Open')} 
                      className="px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all"
                      style={{ backgroundColor: filterType === 'Open' ? '#999' : 'transparent', color: filterType === 'Open' ? '#fff' : 'inherit' }}
                    >
                      Casi aperti
                    </button>
                  </div>

                  {requestList.map((item, index) => (
                    <div key={item.id} className='flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4 relative'>
                      {item.status === 'New' && <span className="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>}
                      <div className='bg-[#F04438] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7'>
                        <img src='assets/images/chalky-pink-textured-circle.png' alt='Wait...' className='md:h-14 h-12 mx-auto '/>
                      </div>
                      <div className='grow md:px-4 px-2'>
                        <div className='flex justify-between mb-2'>
                          <p className='text-base'>Richiesta SOS</p>
                          <p className='text-sm text-gray-500'>{formatTimeAgo(item.requestDate)}</p>
                        </div>
                        <h2 className='md:text-xl text-lg font-bold mb-1'>{item.patientFirstName} {item.patientLastName}</h2>
                        <div className='flex justify-between'>
                          <p className='text-base'>{item.parentFirstName} {item.parentLastName}</p>
                          <Link to="#" onClick={(e) => handleStateUpdateAndRedirect(e, item)} className="text-sm italic underline text-gray-500">Vedi altro <i className="fa-solid fa-angle-right"></i></Link>
                        </div>
                      </div>
                    </div>
                  ))}

                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React from 'react'
import {Link} from 'react-router-dom'
import DashboardNav from '../Dashboard/DashboardNav'
import { Box, Grid } from '@mui/material'

export default function Receipts() {
  return (
    <>
        <div >
            <Grid container columns={12} spacing={2}>
                <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                    <div >
                        <div className='flex gap-3 mb-12 font-semibold'>
                            <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                            <span>/</span>
                            <span>Receipts</span>
                        </div>
                        <Grid container spacing={3} className='justify-center'>
                            <Grid item lg={5} md={8} xs={12}>
                                <div >
                                    <div className='mb-10'>
                                        <h3 className='mb-2 text-base'>Luglio 2024</h3>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Prescrizione</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Visita</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                    <div className='mb-10'>
                                        <h3 className='mb-2 text-base'>Giugno 2024</h3>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Prescrizione</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Visita</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                    <div className='mb-10'>
                                        <h3 className='mb-2 text-base'>Maggio 2024</h3>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Prescrizione</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                        <Box className="flex items-center py-4 border-b border-gray-300">
                                            <div className='text-2xl min-w-8 mr-2'>
                                                <i class="fa-regular fa-memo-pad"></i>
                                            </div>
                                            <div className='w-full'>
                                                <h3 className='text-xl font-semibold mb-1'>Visita</h3>
                                                <div className='flex justify-between'>
                                                    <p className='text-base'>19/07/2024</p>
                                                    <Link to="#"  className='text-base underline'>Scarica ricevuta</Link>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>

                                    <div class="text-center"><Link to="#" class="underline text-[#828DEE]">Indietro</Link></div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                
            </Grid>
        </div>
    </>
  )
}
import { Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


export default function EmptyNotification() {
    return (
        <div className='2xl:container mx-auto'>
            <Grid container columns={12} spacing={2}>
                <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                    <div>
                        <div className='flex gap-3 mb-6 font-semibold'>
                            <Link to="/notification"><i class="fa-solid fa-angle-left mr-2"></i> Notifiche</Link>
                        </div>
                        <Grid container spacing={3} className='justify-center'>
                            <Grid item lg={9} md={12} xs={12}>
                                <div className='text-center'>
                                    <img src='assets/images/empty-notification.png' alt='Wait...' className='mx-auto' />
                                    <h3 className='text-2xl font-semibold'>Nessuna notifica presente</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

export default function StampSignatureReg({
  navigation,
  handleFileChange,
  formik,
  submitEnable = false,
  previewImage = false,
}) {
  // State to store image previews
  const [previews, setPreviews] = useState({
    stamp: null,
    signature: null,
  });

  useEffect(() => {
    // Function to generate preview URLs
    const generatePreview = (fieldName) => {
      const value = formik.values[fieldName];
      if (value instanceof File) {
        return URL.createObjectURL(value);
      } else if (typeof value === 'string' && value) {
        return value;
      }
      return null;
    };

    // Generate previews for signature and stamp
    const newPreviews = {
      signature: generatePreview('signature'),
      stamp: generatePreview('stamp'),
    };

    setPreviews(newPreviews);

    // Cleanup function to revoke object URLs
    return () => {
      if (
        previews.signature &&
        formik.values.signature instanceof File
      ) {
        URL.revokeObjectURL(previews.signature);
      }
      if (
        previews.stamp &&
        formik.values.stamp instanceof File
      ) {
        URL.revokeObjectURL(previews.stamp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.signature, formik.values.stamp]);

  // Handler to manage file changes and previews
  const handleFileChangeWithPreview = (event, name) => {
    handleFileChange(event, name);
    // The preview is handled in useEffect
  };

  return (
    <>
      {/* Conditionally render Breadcrumb if not in 'registration' navigation */}
      {/* {navigation !== 'registration' && <Breadcumb />} */}

      <Grid container spacing={3} className='justify-center'>
        <Grid item lg={12} md={12} xs={12}>
          {/* Front Side of signature */}
          <div className='mb-5'>
            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
              <div>
                <i className='fa-regular fa-credit-card-front'></i> Firma
              </div>
              <i
                className={`fa-regular fa-circle-${
                  formik.values.signature ? 'check' : 'question'
                }`}
              ></i>
              <input
                type='file'
                name='signature'
                className='hidden'
                onChange={(event) => {
                  handleFileChangeWithPreview(event, 'signature');
                }}
                accept='image/*' // Restrict to image files
              />
            </label>
            {formik.touched.signature && formik.errors.signature ? (
              <div className='text-red-500'>{formik.errors.signature}</div>
            ) : null}

            {/* Image Preview for signature */}
            {previews.signature && (
              <div className='mt-3'>
                <img
                  src={previews.signature}
                  alt='signature Preview'
                  className='w-32 h-32 border object-contain rounded-lg bg-white p-2'
                />
              </div>
            )}
          </div>

          {/* For stamp */}
          <div className='mb-5'>
            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
              <div>
                <i className='fa-regular fa-address-card'></i> Timbro
              </div>
              <i
                className={`fa-regular fa-circle-${
                  formik.values.stamp ? 'check' : 'question'
                }`}
              ></i>
              <input
                type='file'
                name='stamp'
                className='hidden'
                onChange={(event) => {
                  handleFileChangeWithPreview(event, 'stamp');
                }}
                accept='image/*' // Restrict to image files
              />
            </label>
            {formik.touched.stamp && formik.errors.stamp ? (
              <div className='text-red-500'>{formik.errors.stamp}</div>
            ) : null}

            {/* Image Preview for stamp */}
            {previews.stamp && (
              <div className='mt-3'>
                <img
                  src={previews.stamp}
                  alt='stamp Preview'
                  className='w-32 h-32 border object-contain rounded-lg bg-white p-2'
                />
              </div>
            )}
          </div>

          {/* Submit Button (Optional) */}
          {submitEnable && (
            <div className='mb-6 mt-10'>
              <button
                className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                type='submit'
              >
                Avanti
              </button>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import { Grid } from '@mui/material';
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import useAuthStore from "../../hooks/useAuthStore";


export default function Sos() {
    const navigate = useNavigate();
    const { token } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list

    useEffect(() => {
        const fetchPediatricianStatus = async () => {
            setLoading(true);

            try {
                const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);

                setPediatricianDetail(response.data.data);

                if (response.data && response.data.data.isSOSActive !== undefined) {
                    if (response.data.data.isSOSActive === true) {
                        navigate('/sos-management');
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching pediatrician data:", error);
                setError("Failed to fetch pediatrician data.", error);
                setLoading(false);
            }
        };

        fetchPediatricianStatus();
    }, [idPediatrician, navigate]);

    const activateSOS = async () => {
        setLoading(true);

        try {
            const response = await axios.post(`${Constants.sosActivationdeactivation}`,
                { idPediatrician },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data && response.data.status === 1) {
                navigate('/sos-management'); // Redirect if status is 1
            } else {
                console.error("Failed to activate SOS.");
                setError("Activation failed. Please try again.");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error activating SOS:", error);
            setError("An error occurred while activating SOS.", error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Grid container columns={12} spacing={2} className='h-full'>
                <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className="bg-[#FAFAFA]">
                    <div className='h-full relative'>
                        <div className="flex gap-3 mb-6 font-semibold">
                            <Link to="/profile">
                                <i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo
                            </Link>
                            <span>/</span>
                            <span>Gestione SOS</span>
                        </div>
                        <div className="h-5/6 table lg:pl-5">
                            <div className="lg:w-[295px] align-middle table-cell">
                                <h2 className="mb-5 font-bold text-3xl">Cos’è l’SOS?</h2>
                                <p className="mb-3">
                                    La funzione SOS permette ai tuoi pazienti di entrare immediatamente in contatto con te in caso di emergenza.
                                </p>
                                <p className="mb-3">
                                    Non preoccuparti, sarai tu a decidere quali situazioni attivare e potrai gestire eventuali abusi di questa funzionalità, limitandone
                                    l’uso.
                                </p>
                                <div className="mt-10 text-center">
                                    <button
                                        onClick={activateSOS}
                                        className="w-5/6 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all inline-block leading-[54px]"
                                    >
                                        Attiva
                                    </button>
                                </div>
                                {error && <p className="text-red-500 mt-3">{error}</p>}
                            </div>
                        </div>
                        <div
                            className="absolute right-0 top-0 h-full lg:w-[50%] hidden lg:block bg-cover bg-center bg-no-repeat"
                            style={{ backgroundImage: `url('assets/images/sosector.png')` }}
                        >
                            <div className="w-[380px] h-[380px] absolute bottom-0 m-auto top-0 right-0">
                                <img src="assets/images/rocketSos.png" alt="Wait..." />
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

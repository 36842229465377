import React from 'react'
import RecordOption from './RecordOption'
import { Box, Grid, Modal, TextField } from '@mui/material'
import EventList from './EventList'
import EventDetails from './EventDetails'

export default function MedicalRecord() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
        <RecordOption/>
        <Grid container spacing={4}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
                <EventList/>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className='text-right mb-4'>
                    <button onClick={handleOpen} type="button" class="min-w-48 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-white text-base font-semibold bg-[#828DEE] transition-all">Aggiungi evento</button>
                </div>
                <EventDetails/>
            </Grid>
        </Grid>

    <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-8 p-6 rounded-2xl">
            <div className='md:w-[340px] w-full mx-auto'>
                <form>
                    <div className='mb-4'>
                        <TextField label="Data evento" variant='outlined' fullWidth/>
                    </div>
                    <div className='mb-4'>
                        <TextField label="Tipologia evento" variant='outlined' fullWidth/>
                    </div>
                    <div className='mb-4'>
                        <TextField label="Descrizione" multiline rows={3} variant="outlined" fullWidth />
                    </div>
                    <div className='mb-4'>
                        <TextField label="Note del pediatra (non visibili al paziente)" multiline rows={3} variant="outlined" fullWidth />
                    </div>
                    <div className='mb-4 text-center'>
                        <p className='mb-4'>Aggiungi documentazione</p>
                        <label className='text-2xl w-16 h-16 border rounded-full inline-block leading-[62px] text-[#72777A] border-gray-400 cursor-pointer'>
                            <input type='file' className='hidden'/>
                            <i className="fa-solid fa-plus"></i>
                        </label>
                    </div>

                    <div className='text-center mt-10'>
                        <button className='block text-center leading-[54px] w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all mx-auto'>Aggiungi</button>
                    </div>
                </form>
            </div>
        </Box>
    </Modal> 
    </>
  )
}


//const apiURL = 'http://localhost:8000/api/';
const apiURL = 'https://api.kiddocare.eu/api/';


export const Constants = {
  S3_PREFIX: 'https://kiddo-care.s3.eu-central-1.amazonaws.com/',
  adminLogIn: apiURL + 'admin/login',
  userLogin: apiURL + 'security/login',
  userLoginV2: apiURL + 'security/v2/login',
  getAdminDetails: apiURL + 'admin',
  getLoggedInDetails: apiURL + 'admin/me',
  registerOrUpdateAdmin: apiURL + 'admin/register',
  UserRegister: apiURL + 'security/account-creation',
  PublicUserRegister: apiURL + 'security/public-account-creation',
  PublicUserRegisterV2: apiURL + 'security/v2/public-account-creation',
  getAllUsers: apiURL + 'admin/doctors',
  UserInfo: apiURL + 'security/userinfo',
  PublicUserInfo: apiURL + 'security/public-userinfo',
  doctorDetails: apiURL + 'admin/doctor-details/',
  addNewDoctor: apiURL + 'admin/doctor-register',
  deleteDoctor: apiURL + 'admin/doctor-delete',


  accountCreationForPediatrician: apiURL + 'security/account-creation',
  accountUpdateForPediatrician: apiURL + 'security/account-update',
  phoneVerificationorPediatrician: apiURL + 'security/phone-verification',

  profilePediatrician: apiURL + 'registry/pediatrician-update',
  profilePediatricianApp: apiURL + 'registry/pediatrician-update-app',
  profilePediatricianAppV2: apiURL + 'registry/v2/pediatrician-update-app',
  identityCardPediatrician: apiURL + 'registry/pediatrician-uploadidentitycard',
  pediatricianList: apiURL + 'registry/pediatrician-list',
  viewPediatricianProfile: apiURL + 'registry/pediatrician',
  deletePediatricianProfile: apiURL + 'registry/pediatrician-delete',
  importPediatricianCSV: apiURL + 'registry/upload-bulk-pediatrician',
  cityWisePediatricianList: apiURL + 'registry/pediatricians',
  pediatricianAddressList: apiURL + 'registry/pediatrician-address-list/',
  managePediatricianAddress: apiURL + 'registry/pediatrician-address/',
  getPediatricianByID : apiURL+ 'registry/pediatrician/',
  pediatricianStudioFilter: apiURL + 'registry/pediatrician-studio-filter',

  profilePatient: apiURL + 'registry/patient-profile',
  getPatients: apiURL + 'registry/parent-list',
  patientDetails: apiURL + 'registry/parent/',
  parentPatientOnboard: apiURL + 'registry/parent-onboarding',
  deleteParentProfile: apiURL + 'registry/parent-delete/',
  manageParentAddress: apiURL + 'registry/parent-address',
  deleteParentAddress: apiURL + 'registry/parent-address-delete/',
  parentPatientList: apiURL + 'registry/patient-list',
  updateParent: apiURL + 'registry/parent-update',
  addPatient: apiURL + 'registry/patient-create',
  updateParentPatient: apiURL + 'registry/patient-update',
  deletePatient: apiURL + 'registry/patient-delete',
  allPatientList: apiURL + 'registry/all-patient-list',
  addParent: apiURL + 'registry/parent-adding',
  confirmAvailability: apiURL + 'registry/pediatrician-calander-confirm',
  patientList: apiURL + 'registry/patient-list-pediatrician',
  pediatricianSetting: apiURL + 'registry/pediatrician-visit-setting',
  pediatricianSearchSaveList: apiURL + 'registry/pediatrician-search-list/',
  pediatricianSearchSave: apiURL + 'registry/pediatrician-search',
  addPatients: apiURL + 'registry/map-patient-to-pediatrician',
  getPatient: apiURL + 'registry/patient-view',
  getPediatricianPatient: apiURL + 'registry/pediatrician-patient-view',
  sosActivationdeactivation: apiURL + 'registry/activate-deactivate-sos',
  // importPatientCsv: admin_service + 'admin/importPatientCsv',

  getPediatricianPatients: apiURL + 'registry/patient-list-pediatrician/',

  certificateList: apiURL + 'core/certificates',
  UsercertificateList: apiURL + 'core/certificates/user',
  certificateCategories: apiURL + 'core/certificate/categories',
  certificateTags: apiURL + 'core/certificate/tags',
  filePath: apiURL + 'core/files',
  assignedcertificateList: apiURL + 'core/assigned-certificates',
  assignedcertificateGenerate: apiURL + 'core/certificates/requests/gen/',
  deleteCertificate: apiURL + 'core/certificates',
  addStudioAvailability: apiURL + 'core/add-studio-availability',
  availability: apiURL + 'core/availability',
  allAvailability: apiURL + 'core/list-availability',
  updateAvailability: apiURL + 'core/update-studio-availability',
  deleteAvailability: apiURL + 'core/availability',
  createSlotBooking: apiURL + 'core/create-slot-booking',
  slotBooking: apiURL + 'core/slot-bookings',
  cancelSlotBooking: apiURL + 'core/cancel-slot-booking',
  servicesList: apiURL + 'core/services-list',
  servicesAdd: apiURL + 'core/pediatrician-services-add-edit',
  pediatricianServices: apiURL + 'core/all-pediatrician-services',
  pediatricianBulkServices: apiURL + 'core/bulk-pediatrician-services-add',
  bookingServiceSloat: apiURL + 'core/book-service-slot',
  generateCertificate: apiURL + 'core/generate-certificate',
  notifications: apiURL + 'core/notifications',
  pediatricianCertificateList: apiURL + 'core/pediatrician-assigned-certificates',
  notiCertificateList: apiURL + 'core/assigned-newInprogress-certificates',
  rejectCertificate: apiURL + 'core/pediatrician-reject-request-certificate',
  updateStateCertificate: apiURL + 'core/update-state-requested-certificate',
  pediatricianSosEmergenciesList: apiURL + 'core/pediatrician-sos-emergencies-list',
  pediatricianSosBulkUpdate: apiURL + 'core/bulk-activate-deactivate-pediatrician-sos',
  pediatricianSosUpdate: apiURL + 'core/edit-pediatrician-sos-emergencies',
  sosStateUpdate: apiURL + 'core/update-state-sos',
  sosDetails: apiURL + 'core/view-request-sos',

  importProvinceCSV: apiURL + 'geolanguage/bulk-upload-provinces',
  provinceList: apiURL + 'geolanguage/province-list',
  regionWiseProvince: apiURL + 'geolanguage/region-province-list/',
  deleteProvince: apiURL + 'geolanguage/delete-province/',
  addProvince: apiURL + 'geolanguage/add-province',
  getProvinceByID: apiURL + 'geolanguage/province-info/',
  editProvinceByID: apiURL +'geolanguage/update-province/',
  provinceWiseCity: apiURL +'geolanguage/province-city-list/',

  aslList: apiURL + 'geolanguage/asl-list',
  importAslCSV: apiURL + 'geolanguage/upload-bulk-asl',
  deleteASL: apiURL + 'geolanguage/delete-asl/',
  addASL: apiURL + 'geolanguage/add-asl',
  getASLbyID: apiURL + 'geolanguage/ASL-Info/',
  editASL: apiURL + 'geolanguage/update-asl/',
  regionWiseASL: apiURL + 'geolanguage/asl-list-region/',
  regionWiseCity: apiURL + 'geolanguage/region-city-list/',
  cityList:apiURL+ 'geolanguage/city-list/',

  nationList: apiURL + 'geolanguage/nation-list',
  importNationCSV: apiURL + 'geolanguage/upload-bulk-nation',
  deleteNation: apiURL + 'geolanguage/delete-nation/',
  addNation: apiURL + 'geolanguage/add-nation',
  getNationByID: apiURL + 'geolanguage/nation-info/',
  editNation:apiURL + 'geolanguage/update-nation/',

  regionList: apiURL + 'geolanguage/region-list',
  importRegionCSV: apiURL + 'geolanguage/upload-bulk-region',
  deleteRegion: apiURL + 'geolanguage/delete-region/',
  addRegion: apiURL + 'geolanguage/add-region',
  getRegionByID : apiURL + 'geolanguage/region-info/',
  editRegion : apiURL + 'geolanguage/update-region/',

  zipList : apiURL + 'geolanguage/zip-list',
  importZipCSV : apiURL + 'geolanguage/upload-bulk-zip',
  deleteZip : apiURL + 'geolanguage/delete-zip/',
  addZip : apiURL + 'geolanguage/add-zip',
  getZipByID:  apiURL + 'geolanguage/zip-info/',
  editZip : apiURL + 'geolanguage/update-zip',

  importCityCSV : apiURL + 'geolanguage/upload-bulk-city',
  deleteCity : apiURL + 'geolanguage/delete-city/',
  addCity : apiURL + 'geolanguage/add-city',
  getCityByID: apiURL +'geolanguage/city-info/',
  editCity: apiURL + 'geolanguage/update-city/',
  cityWiseZIPCodes: apiURL + 'geolanguage/filter-zip/',

  addLanguage: apiURL + 'geolanguage/add-language',
  languageList : apiURL + 'geolanguage/language-list',
  deleteLanguage : apiURL + 'geolanguage/delete-language/',
  editLanguage: apiURL + 'geolanguage/update-language/',
  getLanguageByID: apiURL + 'geolanguage/language-info/',

  stateList :apiURL + 'geolanguage/state-list',
  deleteState : apiURL + 'geolanguage/delete-state/',
  importStateCSV : apiURL + 'geolanguage/upload-bulk-state',
  addState : apiURL + 'geolanguage/add-state',
  getStateByID :  apiURL + 'geolanguage/state-info/',
  editState :  apiURL + 'geolanguage/update-state/',


  //Dropdown public apis
  dropdownASLList: apiURL + 'geolanguage/public-asl-list/',
  dropdownCityList : apiURL + 'geolanguage/public-city-list/',
  dropdownProvinceList : apiURL + 'geolanguage/public-province-list/',
  dropdownRegionList : apiURL + 'geolanguage/public-region-list/',

  getAllOrders: apiURL + 'order/all-orders',
  getOrderById: apiURL + 'order/',
  changeOrderStatus: apiURL + 'order/update-status/',

  createAndUpdateSocialLinks: apiURL + 'social-links',
  getAllSocialLinks: apiURL + 'social-links',
  stripePayment: apiURL + 'payments/init-payment',
  stripeAccOnboard: apiURL + 'payments/account-onboard',
  stripeAccCreate: apiURL + 'payments/account',
  stripeAccVerify: apiURL + 'registry/stripe-account/verify',

  addOrUpdateAboutUs: apiURL + 'about-us/addOrUpdate',
  getAboutUs: apiURL + 'about-us/get',

  addOrUpdatePolicy: apiURL + 'policy-service',
  getpolicy: apiURL + 'policy-service',
  VerifyEmailOtp: apiURL + 'security/verify-email-otp',
  VerifyEmailOtpV2: apiURL + 'security/v2/verify-email-otp',
  VerifyPhoneOtp: apiURL + 'security/verify-phone-otp',
  VerifyPhoneOtpV2: apiURL + 'security/v2/verify-phone-otp',
  resendConfirmationEmail: apiURL + 'security/resend-confirmation-email',
  resendConfirmationMobile: apiURL + 'security/resend-otp',
  checkEmail: apiURL + 'security/check-email',
  resetPassword: apiURL + 'security/v2/restore-password',
  verifyInvitationCode: apiURL + 'registry/verify-invitation-code',
  resetPasswordLink: apiURL + 'security/verify-restore-password',
  agoraVideoTokenLink: apiURL + 'core/agora-token?',
  checkAccessToken:apiURL+ 'security/check-session',
  paymentTransactionDetails:apiURL+ 'payments/pediatrician-transaction-list/',
  pediatricianBlockList: apiURL + 'core/pediatrician-block-parent-list',
  unblockParent:apiURL + 'core/remove-block-parent',
  pediatricianSearchParent: apiURL + 'registry/pediatrician-search-patients',
  patientWiseParent: apiURL + 'registry/patient-wise-parents',
  blockParent: apiURL + 'core/block-parents',
  pediatricianAddSosEmergencies: apiURL + 'core/pediatrician-add-sos-emergencies',
  deletePediatricianSOSEmergencies: apiURL + 'core/delete-pediatrician-sos-emergencies/',
  pediatricianDashboardCounter:apiURL + 'core/pediatrician-dashboard-notifications',
  inProgressSOS: apiURL + 'core/new-inprogress-sos',
  SOSBasedOnType: apiURL + 'core/get-sos',
  SendSMS: apiURL + 'sms/send-sms',
  closureList: apiURL + 'core/closure-list',
  closeSos: apiURL + 'core/close-sos',
  enterChat: apiURL + 'core/enterChat',

}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedStudios } from '../../redux/studioSlice'; // Import actions
import { Grid, FormControl, Checkbox } from '@mui/material'; // Combined MUI imports
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import { Constants } from '../../endPoints'; // Constants for endpoints
import Swal from 'sweetalert2'; // SweetAlert for alerts

export default function Studio() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [addresses, setAddresses] = useState([]);
    const idPediatrician = localStorage.getItem('userId'); // Fetch userId from localStorage
    const [loadingStudioId, setLoadingStudioId] = useState(null); // Track loading state for specific studio

    // Fetch studio addresses
    const getStudios = async () => {
        try {
            const response = await fetch(`${Constants.pediatricianAddressList}${idPediatrician}`);
            if (response.ok) {
                const data = await response.json(); // Directly parse JSON
                if (data.status === 1) {
                    setAddresses(Array.isArray(data.data) ? data.data : []); // Set fetched addresses
                } else {
                    console.error('Failed to fetch addresses:', data.message);
                }
            } else {
                console.error('Failed to fetch addresses');
            }
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    // Call the API on component mount
    useEffect(() => {
        getStudios();
    }, []);

    // Access selected studios from Redux
    const selectedStudios = useSelector((state) => state.studio.selectedStudios);

    // Handle checkbox change for studio selection
    const handleCheckboxChange = (studioId) => {
        if (selectedStudios.includes(studioId)) {
            // Remove studio if it's already selected
            dispatch(setSelectedStudios(selectedStudios.filter(id => id !== studioId)));
        } else {
            // Add studio if it's not selected
            dispatch(setSelectedStudios([...selectedStudios, studioId]));
        }
    };

     // Handle removing a studio with loader and confirmation
     const handleRemoveStudio = (studioId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoadingStudioId(studioId); // Set loading state for the specific studio
                try {
                    const response = await fetch(`${Constants.managePediatricianAddress}/${studioId}`, {
                        method: 'DELETE',
                    });

                    const result = await response.json();

                    if (response.ok && result.status === 1) {
                        // Remove studio from local state
                        setAddresses(addresses.filter((address) => address.id !== studioId));
                        dispatch(setSelectedStudios(selectedStudios.filter(id => id !== studioId)));

                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: result.message,
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: result.message || 'Failed to delete the studio.',
                        });
                    }
                } catch (error) {
                    console.error('Error deleting studio:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'An error occurred while trying to delete the studio.',
                    });
                } finally {
                    setLoadingStudioId(null); // Reset loading state
                }
            }
        });
    };


    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from submitting and reloading the page

        if (selectedStudios.length === 0) {
            setError('At least one studio must be selected.');
        } else {
            setError(''); // Clear any existing error
            navigate('/choose-availability'); // Navigate to the next component
        }
    };



    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                                <span>/</span>
                                <Link to="/medical-practice-management">Gestione studio medico</Link>
                                <span>/</span>
                                <span>Gestione indirizzi studio</span>
                            </div>
                            <div className='mt-10'>
                                <Grid container spacing={3} className='justify-center'>
                                    <Grid item lg={6} md={12} xs={12}>
                                        <Grid container spacing={3} className='justify-center'>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <form onSubmit={handleSubmit}>
                                                    <div className='mb-5'>
                                                    <h2 className='text-5xl font-bold mt-8 mb-10 text-center'><i class="fa-regular fa-briefcase-blank"></i></h2>
                                                        <FormControl sx={{ m: 1 }} className='w-full'>

                                                            <div className='mb-10 overflow-auto max-h-96'>
                                                               
                                                                {addresses.length > 0 ? (
                                                                    addresses.map((address) => (
                                                                        <div key={address.id} class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg transition-all hover:bg-[#F8FFFC]">
                                                                            <div>
                                                                                <h4 className='font-semibold mb-1'>Studio</h4>
                                                                                {/* <p className='text-base'>{`${address.studio} - ${address.street}, ${address.province}, ${address.zip}`}</p> */}
                                                                                <p className='text-base'>{`${address.studio}, ${address.street}, ${address.provinceName} IT ${address.zip}`}</p>
                                                                            </div>
                                                                            <div>
                                                                                <button
                                                                                        type="button"
                                                                                        className="text-red-500"
                                                                                        onClick={() => handleRemoveStudio(address.id)} // Call the delete function
                                                                                        disabled={loadingStudioId === address.id} // Disable button only for the specific studio
                                                                                    >
                                                                                        {loadingStudioId === address.id ? (
                                                                                            <i className="fa fa-spinner fa-spin"></i> // Show loader for specific studio
                                                                                        ) : (
                                                                                            <i className="fa-regular fa-trash-can"></i> // Show trash icon when not loading
                                                                                        )}
                                                                                    </button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div className="flex gap-2 items-center justify-between bg-white mb-[2px] py-2 px-4 text-lg transition-all">
                                                                        <p>No Studio Available</p>
                                                                    </div>
                                                                )}

                                                            </div>

                                                            {error && <p style={{ color: '#e91010', marginBottom: '10px', fontSize: '14px' }}>{error}</p>} {/* Display the error message */}

                                                        </FormControl>

                                                    </div>

                                                    <div class="text-center mt-40"><Link to="/add-studio" class="underline text-[#828DEE]" >Aggiungi Studio</Link></div>
                                                    
                                                </form>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

import React from 'react'
import {Link} from 'react-router-dom'

export default function RecordOption() {
  return (
    <>
        <div className='pt-5 mb-5'>
            <Link to="#" className="inline-block px-5 rounded-full border-2 border-[#000] text-black mr-2 mb-2">Tutti gli eventi</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">SOS</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Consulti</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Prescrizioni</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Certificati</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Bilanci salute</Link>
            <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">I miei appunti</Link>
        </div>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Constants } from '../../../endPoints';
import PersonalDetails from "../../Profile/PersonalDetails";
import ProfessionalDataForm from "../../Profile/ProfesstionalData";
import Swal from 'sweetalert2';
import { useUser } from '../../../hooks/useUserContext'; // Import your custom hook to access UserContext

// import IdentificationDocument from "../Profile/IdentificationDocument";

export default function EditProfessionalDetails() {
    const navigate = useNavigate();
    const { user, updateUser, forceUserDataUpdate } = useUser(); // Access the user info from the context
    const dateOfBirth = user?.dateOfBirth ? user.dateOfBirth : '';
    const LOCAL_STORAGE_KEY = `userDetailsForm`;
    const [apiError, setApiError] = useState('');
    const [formData, setFormData] = useState({
        regionalCode: '',
        aslAgreementCode: '',
        vatNumber: '',
        // fiscalAddress: '',
        taxDomicile:'',
        iban: '',
        sdi:'',
        pec:'',
        registrationNumber: ''

    });
    const [loading, setLoading] = useState(false);


    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0]; // Get the selected file
        if (file) {
            formik.setFieldValue(fieldName, file); // Update formik's value with the file object
        }
    };


    useEffect(() => {
        // Fetch pediatrician details
        const fetchPediatricianDetails = async () => {
            const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
            if (userId) {
                try {
                    const response = await axios.get(`${Constants.viewPediatricianProfile}/${userId}`);
                    if (response.data.status === 1) {
                        const { data } = response.data;
                        console.log({data})
                        setFormData({
                            regionalCode: data.regionalCode,
                            aslAgreementCode: data.aslAgreementCode,
                            vatNumber: data.vatNumber,
                            // fiscalAddress: data.fiscalAddress,
                            taxDomicile:data.taxDomicile,
                            iban: data.iban,
                            sdi: data.sdi,
                            pec: data.pec,
                            registrationNumber: data.registrationNumber,
                            // identityCardBack: data.identityCardBack,
                            // identityCardFront: data.identityCardFront,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching pediatrician details:', error);
                    setApiError('Failed to retrieve pediatrician details.');
                }
            }
        };
        fetchPediatricianDetails();
    }, []);

    const handleSubmit = async (formDataToSend) => {
        setLoading(true);
        try {
            const response = await axios.post(Constants.profilePediatrician, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 201 || response.status === 200) {
                updateUser({
                    ...user,
                    regionalCode: formDataToSend.get('regionalCode'),
                    aslAgreementCode: formDataToSend.get('aslAgreementCode'),
                    vatNumber: formDataToSend.get('vatNumber'),
                    // fiscalAddress: formDataToSend.get('fiscalAddress'),
                    taxDomicile: formDataToSend.get('taxDomicile'),
                    iban: formDataToSend.get('iban'),
                    sdi: formDataToSend.get('sdi'),
                    pec: formDataToSend.get('pec'),
                    registrationNumber: formDataToSend.get('registrationNumber'),
                    // identityCardBack: formDataToSend.get('identityCardBack'),
                    // identityCardFront: formDataToSend.get('identityCardFront'),
                    dateOfBirth: formDataToSend.get('dateOfBirth'), // Update user with dateOfBirth
                });
    
                forceUserDataUpdate();
                Swal.fire({
                    title: 'Success',
                    text: 'Pediatrician data updated successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                navigate('/profile');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setApiError(error.response.data.message);
            } else {
                setApiError('An error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };
    const taxCodeRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    const validationSchema = Yup.object({
        regionalCode: Yup.string().required('regionalCode is required'),
        vatNumber: Yup.string().required('vatNumber is required'),
    });

    const formik = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {
            const userId = localStorage.getItem('userId');
            
            if (!userId) {
                console.error("userId not found in localStorage");
                return;
            }
    
            setFormData((prevData) => ({
                ...prevData,
                ...values,
            }));
    
            const formDataToSend = new FormData();
    
            Object.keys(values).forEach((key) => {
                formDataToSend.append(key, values[key]);
            });
    
            formDataToSend.append('idPediatrician', userId);
            formDataToSend.append('dateOfBirth', dateOfBirth); // Append dateOfBirth
    
            await handleSubmit(formDataToSend);
        },
    });

    return (
        <>
            <div className='flex gap-3 mb-12 font-semibold'>
                <Link to="/profile"><i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link>
                <span>/</span>
                {/* <span>Anagrafica</span> */}
                <span>Dati Professionali</span>
            </div>
            <Grid container spacing={3} className='justify-center'>
                <Grid item xl={6} lg={6} md={8} xs={12}>

                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <ProfessionalDataForm formik={formik} />
                        </>

                       

                        <div className="mb-6 mt-10">
                            <button
                                className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                type="submit"
                            >
                                 {loading ? <CircularProgress /> : 'Salva'}
                            </button>
                        </div>

                    </form>
                </Grid>
            </Grid>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

export default function IdentificationDocument({
  navigation,
  handleFileChange,
  formik,
  submitEnable = false,
  previewImage = false,
}) {
  // State to store image previews
  const [previews, setPreviews] = useState({
    identityCardBack: null,
    identityCardFront: null,
  });

  useEffect(() => {
    // Function to generate preview URLs
    const generatePreview = (fieldName) => {
      const value = formik.values[fieldName];
      if (value instanceof File) {
        return URL.createObjectURL(value);
      } else if (typeof value === 'string' && value) {
        return value;
      }
      return null;
    };

    // Generate previews for identityCardFront and identityCardBack
    const newPreviews = {
      identityCardFront: generatePreview('identityCardFront'),
      identityCardBack: generatePreview('identityCardBack'),
    };

    setPreviews(newPreviews);

    // Cleanup function to revoke object URLs
    return () => {
      if (
        previews.identityCardFront &&
        formik.values.identityCardFront instanceof File
      ) {
        URL.revokeObjectURL(previews.identityCardFront);
      }
      if (
        previews.identityCardBack &&
        formik.values.identityCardBack instanceof File
      ) {
        URL.revokeObjectURL(previews.identityCardBack);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.identityCardFront, formik.values.identityCardBack]);

  // Handler to manage file changes and previews
  const handleFileChangeWithPreview = (event, name) => {
    handleFileChange(event, name);
    // The preview is handled in useEffect
  };

  return (
    <>
      {/* Conditionally render Breadcrumb if not in 'registration' navigation */}
      {/* {navigation !== 'registration' && <Breadcumb />} */}

      <Grid container spacing={3} className='justify-center'>
        <Grid item lg={12} md={12} xs={12}>
          {/* Front Side of Identity Card */}
          <div className='mb-5'>
            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
              <div>
                <i className='fa-regular fa-credit-card-front'></i> Fronte
              </div>
              <i
                className={`fa-regular fa-circle-${
                  formik.values.identityCardFront ? 'check' : 'question'
                }`}
              ></i>
              <input
                type='file'
                name='identityCardFront'
                className='hidden'
                onChange={(event) => {
                  handleFileChangeWithPreview(event, 'identityCardFront');
                }}
                accept='image/*' // Restrict to image files
              />
            </label>
            {formik.touched.identityCardFront && formik.errors.identityCardFront ? (
              <div className='text-red-500'>{formik.errors.identityCardFront}</div>
            ) : null}

            {/* Image Preview for Identity Card Front */}
            {previews.identityCardFront && (
              <div className='mt-3'>
                <img
                  src={previews.identityCardFront}
                  alt='Identity Card Front Preview'
                  className='w-32 h-32 border object-contain rounded-lg bg-white p-2'
                />
              </div>
            )}
          </div>

          {/* Back Side of Identity Card */}
          <div className='mb-5'>
            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
              <div>
                <i className='fa-regular fa-address-card'></i> Retro
              </div>
              <i
                className={`fa-regular fa-circle-${
                  formik.values.identityCardBack ? 'check' : 'question'
                }`}
              ></i>
              <input
                type='file'
                name='identityCardBack'
                className='hidden'
                onChange={(event) => {
                  handleFileChangeWithPreview(event, 'identityCardBack');
                }}
                accept='image/*' // Restrict to image files
              />
            </label>
            {formik.touched.identityCardBack && formik.errors.identityCardBack ? (
              <div className='text-red-500'>{formik.errors.identityCardBack}</div>
            ) : null}

            {/* Image Preview for Identity Card Back */}
            {previews.identityCardBack && (
              <div className='mt-3'>
                <img
                  src={previews.identityCardBack}
                  alt='Identity Card Back Preview'
                  className='w-32 h-32 border object-contain rounded-lg bg-white p-2'
                />
              </div>
            )}
          </div>

          {/* Submit Button (Optional) */}
          {submitEnable && (
            <div className='mb-6 mt-10'>
              <button
                className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                type='submit'
              >
                Salva
              </button>
            </div>
          )}

          {/* Back Link */}
          {/* <div className='text-center'>
            <Link to='#' className='underline text-[#828DEE]'>
              Indietro
            </Link>
          </div> */}
        </Grid>
      </Grid>
    </>
  );
}

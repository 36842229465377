import React, { useState, useEffect } from "react";
import VideoCallComponent from "../VideoCall/VideoCallComponent";
import { useParams } from "react-router-dom";
import { Constants } from "../../endPoints"; // Constants for endpoints

export const VideoCallPopup = () => {
  const [agoraVideoToken, setAgoraVideoToken] = useState(); // State without an initial null value
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { channelId } = useParams();

  // Decode channelId safely
  const decodeChannelId = () => {
    try {
      const getAppointmentId = atob(channelId); // Prevent atob error if channelId is null/undefined
      // const splitString = decodeString.split("-");
      // const getAppointmentId = splitString[splitString.length - 1] || "";
      return { getAppointmentId };
    } catch (err) {
      console.error("Error decoding channelId:", err);
      return { getAppointmentId: "" };
    }
  };

  const { getAppointmentId } = decodeChannelId();

  useEffect(() => {
    const fetchToken = async () => {
      if (!getAppointmentId) {
        setError("Invalid or missing appointment ID.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${Constants.agoraVideoTokenLink}bookingId=${getAppointmentId}`);
        if (response.ok) {
          const data = await response.json();
          console.log("API Response:", data);

          if (data.status === 1 && data.data) {
            console.log("Setting Agora token:", data.data);
            setAgoraVideoToken(data.data); // Directly set token from API
          } else {
            setError(data.message || "Failed to fetch Agora token.");
          }
        } else {
          setError("Failed to fetch Agora token. Server responded with an error.");
        }
      } catch (err) {
        console.error("Error fetching Agora token:", err);
        setError("Error fetching Agora token. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [getAppointmentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Fallback to handle missing data
  const appId = "165faa0223714e92adf43ea8fb127e22"; // App ID from Agora
  const firstName = agoraVideoToken?.patientInfo?.firstName || "Default";

  return (
    <VideoCallComponent
      appId={appId}
      channel={channelId}
      token= {agoraVideoToken.agoraToken}//{"006165faa0223714e92adf43ea8fb127e22IAAR+XGI3zRzu/js3Z1nBups5kMmS9EJOPM8Lho1yNjSZpbggKwAAAAAIgDZ0hXmavg+ZwQAAQBq+D5nAgBq+D5nAwBq+D5nBABq+D5n"}//{agoraVideoToken?.agoraToken} // Dynamically use the fetched token
      userName={firstName}
      appointmentTime={agoraVideoToken?.appointmentTime || 30} // Handle missing appointment time gracefully
    />
  );
};

export default VideoCallPopup;

import React from 'react'
import { Grid } from '@mui/material'
import {Link} from 'react-router-dom'


export default function RichiestePrescrizioni() {

  return (
    <>
    <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
            <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <div>
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/notification"><i class="fa-solid fa-angle-left mr-2"></i> Notifiche</Link> 
                        <span>/</span>
                        <span>Richieste prescrizioni</span>
                    </div>
                    <Grid container spacing={3} className='justify-center'>
                        <Grid item lg={5} md={12} xs={12}>
                            <h2 className='text-2xl text-center font-semibold mb-8'>Richieste prescrizioni</h2>
                            <div className='flex gap-4 justify-center mb-8'>
                                <Link to="#" className="px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all">Nuove richieste</Link>
                                <Link to="#" className="px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all">Casi aperti</Link>
                            </div>
                            <div className='flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4 relative'>
                                <span class="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                <div className='bg-[#FFC885] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7'>
                                    <img src='assets/images/pencile.png'  alt='Wait...' className='md:h-14 h-12 mx-auto '/>
                                </div>
                                <div className='grow md:px-4 px-2'>
                                    <div className='flex justify-between mb-2'>
                                        <p className='text-base'>Richieste prescrizioni</p>
                                        <p className='text-sm text-gray-500'>Adesso</p>
                                    </div>
                                    <h2 className='md:text-xl text-lg font-bold mb-1'>Giuseppe Rossi</h2>
                                    <div className='flex justify-between'>
                                        <p className='text-base'>Maria Rossi</p>
                                        <Link to="#" className="text-sm italic underline text-gray-500">Vedi altro <i class="fa-solid fa-angle-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4 relative'>
                                <div className='bg-[#FFC885] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7'>
                                    <img src='assets/images/pencile.png'  alt='Wait...' className='md:h-14 h-12 mx-auto '/>
                                </div>
                                <div className='grow md:px-4 px-2'>
                                    <div className='flex justify-between mb-2'>
                                        <p className='text-base'>Richieste prescrizioni</p>
                                        <p className='text-sm text-gray-500'>10 min fa</p>
                                    </div>
                                    <h2 className='md:text-xl text-lg font-bold mb-1'>Giuseppe Rossi</h2>
                                    <div className='flex justify-between'>
                                        <p className='text-base'>Maria Rossi</p>
                                        <Link to="#" className="text-sm italic underline text-gray-500">Vedi altro <i class="fa-solid fa-angle-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </div>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Constants } from '../../../endPoints';
import Swal from 'sweetalert2';
import { useUser } from '../../../hooks/useUserContext'; // Import your custom hook to access UserContext
import IdentificationDocument from "../../Profile/IdentificationDocument";

export default function EditIdentityDetails() {
  const navigate = useNavigate();
  const { user, updateUser, forceUserDataUpdate } = useUser(); // Access the user info from the context
  const LOCAL_STORAGE_KEY = `userDetailsForm`;
  const [apiError, setApiError] = useState('');
  const [formData, setFormData] = useState({
    identityCardBack: '',
    identityCardFront: '',
  });
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event, fieldName) => {
    const file = event.currentTarget.files[0]; // Get the selected file
    if (file) {
      formik.setFieldValue(fieldName, file); // Update Formik's value with the file object
    }
  };

  const fetchPediatricianDetails = async () => {
    const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
    if (userId) {
      setLoading(true);
      try {
        const response = await axios.get(`${Constants.viewPediatricianProfile}/${userId}`);
        if (response.data.status === 1) {
          const { data } = response.data;
          setFormData({
            identityCardBack: data.identityCardBack, // Assuming this is a URL string
            identityCardFront: data.identityCardFront, // Assuming this is a URL string
          });
        }
        // Avoid logging formData here as setFormData is asynchronous
      } catch (error) {
        console.error('Error fetching pediatrician details:', error);
        setApiError('Failed to retrieve pediatrician details.');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPediatricianDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Optional: Log updated formData for debugging
  useEffect(() => {
    console.log('Updated formData:', formData);
  }, [formData]);

  const handleSubmit = async (formDataToSend) => {
    setLoading(true);
    try {
      const response = await axios.post(Constants.profilePediatrician, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201 || response.status === 200) {
        // Assuming the API returns the updated pediatrician data under response.data.pediatrician
        const updatedPediatrician = response.data.pediatrician;

        // Update user context with the new image URLs and dateOfBirth
        updateUser({
          ...user,
          identityCardBack: updatedPediatrician.identityCardBack, // Updated URL
          identityCardFront: updatedPediatrician.identityCardFront, // Updated URL
          dateOfBirth: updatedPediatrician.dateOfBirth, // Ensure this field exists in the response
        });
        forceUserDataUpdate();

        Swal.fire({
          title: 'Success',
          text: 'Pediatrician data updated successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        navigate('/profile');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setApiError(error.response.data.message);
      } else {
        setApiError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    identityCardBack: Yup.mixed().required('Identity Card Back is required'),
    identityCardFront: Yup.mixed().required('Identity Card Front is required'),
    // dateOfBirth is handled separately and does not require validation here
  });

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true, // Allows Formik to update initialValues when formData changes
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("userId not found in localStorage");
        setApiError('User ID not found. Please log in again.');
        return;
      }
      console.log("values incoming ", values);
      setFormData((prevData) => ({
        ...prevData,
        ...values,
      }));

      const formDataToSend = new FormData();

      Object.keys(values).forEach((key) => {
        formDataToSend.append(key, values[key]);
      });

      formDataToSend.append('idPediatrician', userId);
      formDataToSend.append('dateOfBirth', user.dateOfBirth); // Append dateOfBirth

      await handleSubmit(formDataToSend);
    },
  });

  return (
    <>
      <div className='flex gap-3 mb-12 font-semibold'>
        <Link to="/profile">
          <i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo
        </Link>
        <span>/</span>
        {/* <span>Anagrafica</span> */}
        <span>Documento di riconoscimento</span>
      </div>
      <Grid container spacing={3} className='justify-center'>
        <Grid item xl={5} lg={5} md={8} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <IdentificationDocument
                  handleFileChange={handleFileChange}
                  formik={formik}
                  submitEnable={false}
                />
              )}

              <div className="mb-6 mt-10">
                <button
                  className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : 'Salva'}
                </button>
              </div>
            </>
          </form>
        </Grid>
      </Grid>
      {apiError && <div className="text-red-500 mt-4">{apiError}</div>}
    </>
  );
}

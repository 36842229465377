import { Grid } from '@mui/material';
import React from 'react'
import {BrowserRouter as Router,
    Link } from "react-router-dom";


export default function Footer(props) {
  return (
    <>
    <footer>
        <div className='bg-[#0c1b33] py-16 text-white'>
          <div className='container mx-auto px-4'>
              <Grid container columns={12} spacing={4}>
                <Grid item lg={3} sm={12}>
                <a href="#" className="mb-5 inline-block">
                    <img src="/assets/images/logo-white.png" className="w-48" alt="Logo" />
                </a>

                  <p className='text-gray-200 mb-5 '>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                  <ul className='flex gap-2'>
                    <li><a href='#' className='block w-9 h-9 rounded-full bg-gray-800 text-center leading-[36px] hover:bg-green-500 transition-all'><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li><a href='#' className='block w-9 h-9 rounded-full bg-gray-800 text-center leading-[36px] hover:bg-green-500 transition-all'><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href='#' className='block w-9 h-9 rounded-full bg-gray-800 text-center leading-[36px] hover:bg-green-500 transition-all'><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a href='#' className='block w-9 h-9 rounded-full bg-gray-800 text-center leading-[36px] hover:bg-green-500 transition-all'><i class="fa-brands fa-linkedin-in"></i></a></li>
                  </ul>
                </Grid>
                <Grid item lg={3} sm={6} xs={6}>
                  <h3 className='text-xl font-semibold mb-5 text-amber-400'>About</h3>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>About Us</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Services</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Contact Us</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Login</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Register</a>
                </Grid>
                <Grid item lg={3}  sm={6} xs={6}>
                  <h3 className='text-xl font-semibold mb-5  text-amber-400'>Quick Links</h3>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'> Stories</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Community</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Terms & Conditions</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Privacy Policy</a>
                  <a href='#' className='block mb-2 hover:text-green-400 transition-all'>Cancelation Policy</a>
                </Grid>
                <Grid item lg={3} sm={12}>
                  <h3 className='text-xl font-semibold mb-5  text-amber-400'>Download App</h3>
                  <p>Download the Kidocare App today!</p>
                  <div className='flex gap-3 mt-5'>
                    <a href='#'><img src='assets/images/appstore.png' className='h-12 w-auto object-contain'/></a>
                    <a href='#'><img src='assets/images/google-play.png' className='h-12 w-auto object-contain'/></a>
                  </div>
                </Grid>
              </Grid>
          </div>
        </div>
        <div className='py-5 bg-[#031127] text-white'>
          <div className='container mx-auto px-4'>
            <Grid container columns={12} spacing={2}>
              <Grid item lg={6}>
                <p className='text-[15px]'>Copyright &copy; 2024 Kidocare. All Rights Reserved.</p>
              </Grid>
              <Grid item lg={6} className='lg:text-right'>
                <p  className='text-[15px]'>Developed By: <a href='https://www.goigi.com/' target='_blank'>Goigi</a>.</p>
              </Grid>
            </Grid>
            
          </div>
        </div>
    </footer>
       
    </>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'

export default function EventList() {
  return (
    <>
    <h3 className='text-center font-semibold mb-5 text-lg'>Tutti gli eventi</h3>
    <div className='pr-5 evntlistscroll'>
        <div className='mb-5'>
            <div className='text-center'>
                <div><span className='bg-gray-100 inline-block py-1 px-3 min-w-20 text-center text-xs text-[#A1A2A2] rounded-full'>2024</span></div>
            </div>
            <div className='flex gap-3 items-center mb-2'>
                <div className='min-w-20 font-semibold text-center'><h2>Febbraio</h2></div>
                <div className='border-b h-full grow inline-block'></div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                    Mer <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#5EAF88]'></span>
                            <h2 className='font-semibold text-base'>Consulto da remoto</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                Ven <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#828DEE]'></span>
                            <h2 className='font-semibold text-base'>Consulto in studio</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mb-5'>
            <div className='flex gap-3 items-center mb-2'>
                <div className='min-w-20 font-semibold text-center'><h2>Gennaio</h2></div>
                <div className='border-b h-full grow inline-block'></div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                    Mer <br/> 1
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#FFBF00]'></span>
                            <h2 className='font-semibold text-base'>Prescrizione</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mb-5'>
            <div className='text-center'>
                <div><span className='bg-gray-100 inline-block py-1 px-3 min-w-20 text-center text-xs text-[#A1A2A2] rounded-full'>2023</span></div>
            </div>
            <div className='flex gap-3 items-center mb-2'>
                <div className='min-w-20 font-semibold text-center'><h2>Novembre</h2></div>
                <div className='border-b h-full grow inline-block'></div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                    Mer <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#86D8B0]'></span>
                            <h2 className='font-semibold text-base'>Certificato</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                Ven <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#828DEE]'></span>
                            <h2 className='font-semibold text-base'>Consulto in studio</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mb-5'>
            <div className='flex gap-3 items-center mb-2'>
                <div className='min-w-20 font-semibold text-center'><h2>Novembre</h2></div>
                <div className='border-b h-full grow inline-block'></div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                    Mer <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#86D8B0]'></span>
                            <h2 className='font-semibold text-base'>Certificato</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className='flex gap-3 items-center mb-3'>
                <div className='min-w-20 text-center'>
                Ven <br/> 15
                </div>
                <div className='grow'>
                    <div className='flex gap-3 justify-between shadow-lg bg-white rounded-lg p-3 items-center'>
                        <div className='flex gap-3 items-center'>
                            <span className='inline-block w-4 h-4 rounded-full bg-[#828DEE]'></span>
                            <h2 className='font-semibold text-base'>Consulto in studio</h2>
                        </div>
                        
                        <div className='text-sm'><Link to="#" className="text-[#828DEE] underline">vedi <i className="fa-solid fa-angle-right"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

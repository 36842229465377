import React from 'react'
import DashboardNav from '../Dashboard/DashboardNav'
import { Grid, TextField } from '@mui/material'
import {Link} from 'react-router-dom'
import './ChatUI.css'
import ChatSidebar from './ChatSidebar'

export default function Chat() {
  return (
    <>
    <div >
        <Grid container columns={12} spacing={0}>
            <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <Grid container columns={12} spacing={2} className='h-full'>
                    <Grid item md={8} sm={12} xs={12}>
                        <div className='flex gap-3 py-4 px-5 border-b border-[#C5C8C9] items-center justify-between'>
                            <div className='flex gap-3 items-center'>
                               <Link to="/request-consultation"> <i class="fa-solid fa-angle-left mr-2"></i></Link>
                                <div>
                                    <h2 className='text-xl font-semibold'>Giuseppe Rossi </h2>
                                    <p className='text-sm'>(Maria Rossi)</p>
                                </div>
                            </div>
                            <div className='flex gap-6 text-xl'>
                                <Link to="/video-call"  className='text-[#828DEE]'><i class="fa-regular fa-video"></i></Link>
                                <Link to="/audio-call" className='text-[#828DEE]'><i class="fa-regular fa-phone"></i></Link>
                            </div>
                        </div>
                        <div className='py-3 px-5 max-h-[76vh] overflow-x-auto chatscroll'>
                            <div className='text-center mb-4'>
                                <span className='inline-block py-1 px-4 bg-[#C5C8C9] text-xs rounded-lg text-[#3C3C43]'>Ven, Lug 26</span>
                            </div>
                            {/* =========== Output Chat ============= */}
                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>Ciao, iniziamo con alcune domande: tuo figlio ha la febbre?</span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>
                            {/* =========== Input Chat ============= */}
                            <div className='flex gap-3 justify-start items-center text-base mb-4'>
                                <div className='text-xs text-gray-400 order-3'>
                                    10:10
                                </div>
                                <div className='max-w-[80%] order-2'>
                                    <span className='inline-block bg-[#C0E9D5] rounded-lg p-3'>Si</span>
                                </div>
                                <div className='min-w-[44px] order-1'>
                                    <img src='assets/images/user.png' className='w-[44px] h-[44px] object-cover rounded-full'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>Da quanto tempo ha la febbre?</span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-start items-center text-base mb-4'>
                                <div className='text-xs text-gray-400 order-3'>
                                    10:10
                                </div>
                                <div className='max-w-[80%] order-2'>
                                    <span className='inline-block bg-[#C0E9D5] rounded-lg p-3'>Da più di 7 giorni</span>
                                </div>
                                <div className='min-w-[44px] order-1'>
                                    <img src='assets/images/user.png' className='w-[44px] h-[44px] object-cover rounded-full'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>La richiesta è stata inoltrata al pediatra, con codice urgenza: GIALLO </span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>
                            {/* =========== Output Chat ============= */}
                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>Ciao, iniziamo con alcune domande: tuo figlio ha la febbre?</span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>
                            {/* =========== Input Chat ============= */}
                            <div className='flex gap-3 justify-start items-center text-base mb-4'>
                                <div className='text-xs text-gray-400 order-3'>
                                    10:10
                                </div>
                                <div className='max-w-[80%] order-2'>
                                    <span className='inline-block bg-[#C0E9D5] rounded-lg p-3'>Si</span>
                                </div>
                                <div className='min-w-[44px] order-1'>
                                    <img src='assets/images/user.png' className='w-[44px] h-[44px] object-cover rounded-full'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>Da quanto tempo ha la febbre?</span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-start items-center text-base mb-4'>
                                <div className='text-xs text-gray-400 order-3'>
                                    10:10
                                </div>
                                <div className='max-w-[80%] order-2'>
                                    <span className='inline-block bg-[#C0E9D5] rounded-lg p-3'>Da più di 7 giorni</span>
                                </div>
                                <div className='min-w-[44px] order-1'>
                                    <img src='assets/images/user.png' className='w-[44px] h-[44px] object-cover rounded-full'/>
                                </div>
                            </div>

                            <div className='flex gap-3 justify-end items-center text-base mb-4'>
                                <div className='text-xs text-gray-400'>
                                    10:10
                                </div>
                                <div className='max-w-[80%]'>
                                    <span className='inline-block bg-[#D8DDFF] rounded-lg p-3'>La richiesta è stata inoltrata al pediatra, con codice urgenza: GIALLO </span>
                                </div>
                                <div className='min-w-[44px]'>
                                    <img src='assets/images/robot.png' className='w-[44px]'/>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-5 border-t border-b border-[#C5C8C9] px-5 py-6 items-center'>
                            <div className='text-2xl text-[#828DEE]'>
                                <label><input type='file' className='hidden'/> <i class="fa-solid fa-paperclip-vertical"></i></label>
                            </div>
                            <div className='grow'>
                                <input type='text' className='px-4 py-3 rounded-full w-full border border-[#C5C8C9]'/>
                            </div>
                            <div>
                                <button className='w-[40px] h-[40px] rounded-full text-white bg-[#828DEE] text-center'><i class="fa-regular fa-paper-plane-top"></i></button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className='md:border-l  border-[#C5C8C9]'>
                        <div className='p-4'>
                            <ChatSidebar/>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </div> 
    </>
  )
}

import { Grid } from '@mui/material'
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import useAuthStore from "../../hooks/useAuthStore";

export default function Notification() {

    const idPediatrician = localStorage.getItem('userId');
    const { token } = useAuthStore();

    const [notification, setNotification] = useState({});
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState("");

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${Constants.notifications}`,
                { idPediatrician },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const { notification } = response.data;
            setNotification(notification || {});
        } catch (error) {
            console.error("Error fetching notifications:", error);
            setApiError("Failed to retrieve notifications.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    console.log("notification ", notification);



    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/notification"><i class="fa-solid fa-angle-left mr-2"></i> Notifiche</Link>
                            </div>
                            {loading ? (
                                <p>Loading...</p>
                            ) : apiError ? (
                                <p>{apiError}</p>
                            ) : (
                                <Grid container spacing={3} className='justify-center'>
                                    <Grid item lg={6} md={12} xs={12}>
                                        <Link
                                            to="/request-sos"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="bg-[#F04438] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7">
                                                <img
                                                    src="assets/images/chalky-pink-textured-circle.png"
                                                    alt="Wait..."
                                                    className="md:h-14 h-12 mx-auto"
                                                />
                                            </div>
                                            <div className="grow md:px-4 px-2">
                                                <p className="text-base mb-1">Richieste</p>
                                                <h2 className="md:text-2xl text-lg font-bold">SOS</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Nuove <br /> richieste</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.SOS?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Casi <br /> aperti</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.SOS?.open ?? 0}</h3>
                                            </div>
                                        </Link>

                                        {/* Prescriptions */}
                                        <Link
                                            to="/request-prescription"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="bg-[#FFC885] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7">
                                                <img
                                                    src="assets/images/pencile.png"
                                                    alt="Wait..."
                                                    className="md:h-14 h-12 mx-auto"
                                                />
                                            </div>
                                            <div className="grow md:px-4 px-2">
                                                <p className="text-base mb-1">Richieste</p>
                                                <h2 className="md:text-2xl text-lg font-bold">Prescrizioni</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Nuove <br /> richieste</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.prescriptions?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Casi <br /> aperti</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.prescriptions?.open ?? 0}</h3>
                                            </div>
                                        </Link>

                                        {/* Certificates */}
                                        <Link
                                            to="/request-certificate"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="bg-[#86D8B0] rounded-lg text-center md:min-w-28 min-w-16 h-28 py-7">
                                                <img
                                                    src="assets/images/a-icon.png"
                                                    alt="Wait..."
                                                    className="md:h-14 h-12 mx-auto"
                                                />
                                            </div>
                                            <div className="grow md:px-4 px-2">
                                                <p className="text-base mb-1">Richieste</p>
                                                <h2 className="md:text-2xl text-lg font-bold">Certificati</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Nuove <br /> richieste</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.certificates?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <p className="text-sm mb-1">Casi <br /> aperti</p>
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.certificates?.open ?? 0}</h3>
                                            </div>
                                        </Link>

                                        <Link to="/request-consultation" className='mt-8 flex w-full  items-center mb-3'>
                                            <div className='flex bg-white rounded-r-lg relative shadow-lg grow'>
                                                <div className='bg-[#FFC885] rounded-lg text-center w-16 h-14 py-3 md:absolute md:left-[-60px]'>
                                                    <img src='assets/images/uneven.png' alt='Wait...' className='h-8 mx-auto ' />
                                                </div>
                                                <div className='grow md:px-4 px-2 md:flex gap-3 items-center h-14'>
                                                    <p className='text-base'>Richieste</p>
                                                    <h2 className='md:text-2xl text-lg font-bold'>Consulto</h2>
                                                </div>
                                            </div>
                                            <div className='md:px-4 px-1 text-center  md:w-24 md:min-w-24 min-w-16  py-4'>
                                                <p className='text-sm mb-1'>Nuove <br /> richieste</p>
                                            </div>
                                            <div className='md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16  py-4'>
                                                <p className='text-sm mb-1'>Casi <br /> aperti</p>
                                            </div>
                                        </Link>
                                        {/* Consultations - High Priority */}
                                        <Link
                                            to="/request-consultation"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="grow md:px-4 px-2 bg-[#F04438] py-4 rounded-lg text-center">
                                                <h2 className="md:text-2xl text-lg font-bold text-white">Urgenza grave</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.high?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.high?.open ?? 0}</h3>
                                            </div>
                                        </Link>

                                        {/* Consultations - Medium Priority */}
                                        <Link
                                            to="/request-consultation"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="grow md:px-4 px-2 bg-[#FFC885] py-4 rounded-lg text-center">
                                                <h2 className="md:text-2xl text-lg font-bold">Urgenza media</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.medium?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.medium?.open ?? 0}</h3>
                                            </div>
                                        </Link>

                                        {/* Consultations - Low Priority */}
                                        <Link
                                            to="/request-consultation"
                                            className="flex w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-4"
                                        >
                                            <div className="grow md:px-4 px-2 bg-[#86D8B0] py-4 rounded-lg text-center">
                                                <h2 className="md:text-2xl text-lg font-bold">Urgenza bassa</h2>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.low?.new ?? 0}</h3>
                                            </div>
                                            <div className="md:px-4 px-1 text-center md:w-24 md:min-w-24 min-w-16 border-l py-4">
                                                <h3 className="md:text-2xl text-lg font-bold">{notification?.consultations?.priority?.low?.open ?? 0}</h3>
                                            </div>
                                        </Link>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Stack,
  Pagination,
  TextField,
  Button,
  Box,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../../hooks/useUserContext";
import useAuthStore from "../../hooks/useAuthStore";
import { FaDownload } from "react-icons/fa";
import Swal from "sweetalert2";
import { Constants } from "../../endPoints";

export default function CertificateRequestList() {
  const { user } = useUser();
  const { token } = useAuthStore();

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const itemsPerPage = 10; // Since the API limit is 10
  const [currentPage, setCurrentPage] = useState(1);
  // Date Filters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Renamed searchTerm
  const [searchBy, setSearchBy] = useState(""); // Renamed searchBy -> stateId

  // State for Modal and Reject Reason
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [rejectionError, setRejectionError] = useState(null); // For displaying error messages
  // Handler for pagination changes
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     setError(null);

  //     // Construct the API URL with search parameters
  //     const url = `${Constants.pediatricianCertificateList}?page=${currentPage}&limit=${itemsPerPage}&keyword=${searchTerm}&stateId=${searchBy}&startDate=${startDate}&endDate=${endDate}`;

  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     };

  //     const body = {
  //       idUser: user.id,
  //     };

  //     try {
  //       const response = await fetch(url, {
  //         method: "POST", // Use POST since the API expects idUser in the body
  //         headers: headers,
  //         body: JSON.stringify(body),
  //       });

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const result = await response.json();
  //       setData(result.data);
  //       setPagination(result.pagination);
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error.message || "Something went wrong");
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [currentPage, searchTerm, startDate, endDate, searchBy]); // Fetch data when currentPage changes

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    const url = `${Constants.pediatricianCertificateList}?page=${currentPage}&limit=${itemsPerPage}&keyword=${searchTerm}&stateId=${searchBy}&startDate=${startDate}&endDate=${endDate}`;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const body = {
      idUser: user.id,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("La risposta della rete non è stata ok");
      }

      const result = await response.json();
      setData(result.data);
      setPagination(result.pagination);
      setLoading(false);
    } catch (error) {
      setError(error.message || "Qualcosa è andato storto");
      setLoading(false);
    }
  }, [currentPage, searchTerm, startDate, endDate, searchBy, token, user.id, itemsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  // Calculate total certificates and total pages
  const totalCertificates = pagination.total || 0;
  const totalPages = Math.ceil(totalCertificates / itemsPerPage);

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a"); // Create a temporary anchor tag
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop(); // Extract the file name from the URL
    link.target = "_blank"; // Open in a new tab if needed
    document.body.appendChild(link); // Append the link to the DOM
    link.click(); // Programmatically click the link to trigger download
    document.body.removeChild(link); // Clean up
  };
  const handleSearch = () => {
    // Triggering a search here is not strictly necessary since the useEffect
    // will handle the search API call when any state changes.
    // However, you can optionally call fetchData() here if needed.
    console.log("Cercando con", { searchTerm, startDate, endDate, searchBy });
  };
  const handleRejectClick = (request) => {
    setSelectedRequest(request); // Set the selected request for rejection
    setOpenRejectModal(true); // Open the reject modal
  };

  const handleRejectSubmit = async () => {
    const rejectionPayload = {
      instanceId: selectedRequest.instanceId,
      idPediatrician: selectedRequest.pediatricianId,
      rejectionReason: rejectReason,
    };

    const url = Constants.rejectCertificate;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(rejectionPayload),
      });

      if (!response.ok) {
        // Handle non-200 status codes and throw a custom error with the response message
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Impossibile rifiutare la richiesta di certificato."
        );
      }

      const result = await response.json();

      // Check if rejection reason is required
      if (
        result.status === 0 &&
        result.message === "Il motivo del rifiuto è obbligatorio."
      ) {
        // Show the message using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Il motivo del rifiuto è obbligatorio",
          text: "Si prega di fornire un motivo per il rifiuto prima di inviare.",
        });
        return;
      }

      // Handle success
      Swal.fire({
        icon: "success",
        title: "Successo!",
        text: "Richiesta di certificato rifiutata con successo.",
      });

      // Close the modal and reset the state
      setOpenRejectModal(false);
      setRejectReason("");
      setRejectionError(null);
      fetchData();
    } catch (error) {
      // Display the exact error message from the API response
      const errorMessage = error.message || "Si è verificato un errore imprevisto.";
      setRejectionError(errorMessage);

      // Show the exact error using SweetAlert
    }
  };

  return (
    <>
      <div className="flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs">
        <Link to="/certificates">
          <i className="fa-solid fa-angle-left mr-2"></i> Lista certificati{" "}
        </Link>
        <span>/</span>
        <span>Richiesta di certificato</span>
      </div>
      <div className="mt-10 text-center">
        <div className="mb-8">
          <h2>
            <span className="inline-block border-4 text-4xl w-14 h-14 py-1 border-black rounded-lg">
              <i className="fa-solid fa-bars-sort"></i>
            </span>
          </h2>
          <p className="mt-8 text-base">
            Inserisci la tipologia di certificati.
          </p>
        </div>
        {/* Static Search Bar and Filters */}
        <Box mb={4}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Ricerca"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Data di inizio"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Data di fine"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Stato</InputLabel>
                <Select
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                  label="Search By"
                >
                  <MenuItem value={''}>Tutto</MenuItem>
                  <MenuItem value={1}>Richiesta creata</MenuItem>
                  <MenuItem value={2}>In corso</MenuItem>
                  <MenuItem value={3}>Completato</MenuItem>
                  <MenuItem value={4}>Respinto</MenuItem>
                  <MenuItem value={5}>Annullato</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <button className="w-32 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                variant="contained"
                color="primary"
                onClick={handleSearch}
                fullWidth
              >
                <i className="fas fa-search mx-2"></i>
                Search
              </button>
            </Grid> */}
          </Grid>
        </Box>

        {/* Loading and Error Messages */}
        {loading &&  <CircularProgress />}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && data.length === 0 && <p>Nessun dato trovato.</p>}

        {/* Data Table */}
        {!loading && !error && data.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="w-full lg:text-base text-sm tablecustom mb-6">
                <thead className="bg-[#FFC885] text-sm">
                  <tr>
                    <th className="text-left">S No.</th>
                    <th>Nome del paziente</th>
                    <th>Nome del genitore</th>
                    <th> Tipo</th>
                    <th>Titolo del certificato</th>
                    <th>Data richiesta</th>
                    <th>Data di emissione</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {data.map((item, index) => (
                    <tr key={item.instanceId}>
                      <td className="text-left">{(currentPage - 1) * itemsPerPage + index + 1} </td>
                      <td>
                        {" "}
                        {item.patientFirstName} {item.patientLastName}
                      </td>
                      <td>
                        {item.parentFirstName} {item.parentLastName}
                      </td>
                      <td>
                        <span className="inline-block bg-[#aab5ff] px-5 rounded-full font-semibold text-white text-sm py-1">
                          {item.certificateCategory}
                        </span>
                      </td>
                      <td>{item.certificateName}</td>
                      <td>{new Date(item.requestDate).toLocaleDateString()}</td>
                      <td>
                        {item.issuedDate
                          ? new Date(item.issuedDate).toLocaleDateString()
                          : "Non rilasciato"}
                      </td>
                      <td>
                        <div className="flex gap-1 justify-end">
                          {item.uploadedFile ? (
                            <button
                              className="text-[#aab5ff] px-2 rounded-full font-semibold text-xs py-1"
                              variant="warning"
                              size="sm"
                              title="Scarica il certificato"
                              onClick={() => handleDownload(item.uploadedFile)}
                            >
                              <FaDownload />
                            </button>
                          ) : null}

                          <Link
                            to={`/view-certificate-request/${item.instanceId}`}
                          >
                            {item.uploadedFile || item?.stateId === 4 ? (
                              <button className="text-[#86D8B0] border border-[#86D8B0] px-4 rounded-full font-semibold text-sm py-2">
                                <i className="fa-regular fa-eye"></i> Visualizzazione
                              </button>
                            ) : (
                              <button className="text-[#86D8B0] border border-[#86D8B0] px-2 rounded-full font-semibold text-xs py-1">
                                <i className="fa-regular fa-eye"></i> Visualizzazione &
                                Generare
                              </button>
                            )}
                          </Link>

                          {!item.uploadedFile &&
                            (item?.stateId === 4 ? (
                              <span className="text-[#FF5959] font-semibold text-sm px-4 py-1">
                                Respinto
                              </span>
                            ) : (
                              <button
                                onClick={() => handleRejectClick(item)}
                                className="text-[#FF5959] border border-[#FF5959] px-4 rounded-full font-semibold text-sm py-1"
                              >
                                Rifiutare
                              </button>
                            ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <Grid container spacing={3} className="items-center mt-2">
              <Grid item lg={6}>
                <div>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      disabled={loading} // Disable while loading
                    />
                  </Stack>
                </div>
              </Grid>
              <Grid item lg={6}>
                <div>
                  <div className="dataTables_length text-right text-sm text-gray-600">
                  Mostrando da {(currentPage - 1) * itemsPerPage + 1} a{" "}
                    {Math.min(currentPage * itemsPerPage, totalCertificates)} di{" "}
                    {totalCertificates} voci
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      {/* Reject Modal */}
      <Modal
        open={openRejectModal}
        onClose={() => {
          setOpenRejectModal(false);
          setRejectReason(""); // Clear the rejection reason
          setRejectionError(null); // Clear any rejection error
        }}
      >
        <Box className="modal md:w-[450px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
          <h2 className="md:text-2xl text-xl font-bold text-center mb-8">
            Reject Certificate Request
          </h2>
          {rejectionError && <p className="text-red-500">{rejectionError}</p>}
          <TextField
            label="Reason for Rejection *"
            fullWidth
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            variant="outlined"
            multiline
            className="bg-white p-4 rounded-md"
            rows={4}
          />
          <div className="mb-6 mt-4 text-center">
            <button
              className="w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
              onClick={handleRejectSubmit}
              color="primary"
              variant="contained"
            >
              Reject
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

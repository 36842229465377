import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Checkbox, Grid, Modal, TextField, CircularProgress, Alert } from '@mui/material'
import PatientDetailNav from './PatientDetailNav'
import './PatientSearch.css';
import EventList from './EventList'
import EventDetails from './EventDetails'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAuthStore from "../../hooks/useAuthStore";
import axios from 'axios';
import { Constants } from '../../endPoints'


export default function PatientMedicalRecord() {
    const navigate = useNavigate();
    const { idPatient } = useParams();
    const { setToken, token, setId } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const pediatricianDetails = JSON.parse(localStorage.getItem('user'));

    const [patient, setPatient] = useState(null); // Patient details
    const [parents, setParent] = useState(null); // Parent details
    const [loading, setLoading] = useState(false); // API loading state
    const [error, setError] = useState(''); // API error state

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isVisible, setIsVisible] = useState(false);
    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const dateClose = () => setIsVisible(false);

    const [urgencyVisible, urgencyIsVisible] = useState(false);
    const urgencyClick = () => {
        urgencyIsVisible(!isVisible);
    };
    const urgencyClose = () => urgencyIsVisible(false);

    // Fetch patient data
    const getPatient = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${Constants.getPediatricianPatient}`,
                {
                    idPatient,
                    idPediatrician,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Correctly positioned headers
                    },
                }
            );
            const patientData = response?.data?.patient || {};
            setPatient(patientData);
            setParent(patientData?.parents); // Assuming `parent` is part of the response
        } catch (err) {
            setError('Failed to load patient details. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatient();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert severity="info">No patient data available.</Alert>
            </div>
        );
    }



    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/patients"><i class="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti </Link>
                                <span>/</span>
                                <span>Giovanni Bianchi</span>
                            </div>
                            <PatientDetailNav patient={patient} />
                            <div className='flex mt-5 mb-5'>
                                <Link to={`/patient-details/${patient?.id}`} className="px-6 py-3 text-[#72777A] border-b rounded-br border-[#C5C8C9] border-r "><i className="fa-regular fa-user mr-2"></i> Anagrafica</Link>
                                <Link to={`/patient-medical-record/${patient?.id}`} className="px-6 py-3 text-[#828DEE] border-t rounded-tl border-[#C5C8C9] grow"><i className="fa-regular fa-square-list  mr-2"></i> Cartella clinica</Link>
                            </div>
                            <div className='flex justify-end gap-4 lg:mt-[-45px] items-center relative'>
                                <p className='text-sm'>Filtra per</p>
                                <button onClick={handleClick} className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'>  Date</button>
                                <button onClick={urgencyClick} className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'>Urgenza</button>
                                <button className='h-[42px] min-w-24 px-5 border border-[#D0D5DD] rounded-lg bg-white'><i class="fa-regular fa-magnifying-glass"></i> Cerca</button>

                                {isVisible && (
                                    <div className='md:w-[396px] w-[280px] lg:px-10 absolute top-14 right-0 bg-white p-6 shadow-lg rounded-lg border'>
                                        <form>
                                            <p className='font-semibold mb-5 text-lg text-center'>Range date</p>
                                            <div className='mb-4'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >
                                                        <DatePicker label="Da gg/mm/aaaa" className="w-full" format="DD/MM/YYYY" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className='mb-4'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >
                                                        <DatePicker label="A gg/mm/aaaa" className="w-full" format="DD/MM/YYYY" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className='mb-4 mt-8 text-center'>
                                                <button className='lg:w-72 w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Mostra</button>
                                            </div>
                                            <div className='text-center mb-4'>
                                                <button onClick={dateClose} type='button' className='text-[#828DEE] underline font-semibold'>Annulla</button>
                                            </div>
                                        </form>
                                    </div>
                                )}

                                {urgencyVisible && (
                                    <div className='md:w-[396px] w-[200px] lg:px-16 absolute top-14 right-0 bg-white p-6 shadow-lg rounded-lg border'>
                                        <form>
                                            <p className='font-semibold mb-5 text-lg text-center'>Urgenza</p>
                                            <div className='mb-4 text-center graveBox'>
                                                <input type='radio' name="urgency" id='grave' className='hidden' />
                                                <label for="grave" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#F04438] font-semibold text-base'>Grave</label>
                                            </div>
                                            <div className='mb-4 text-center mediaBox'>
                                                <input type='radio' name="urgency" id='media' className='hidden' />
                                                <label for="media" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#FFC885] font-semibold text-base'>Media</label>
                                            </div>
                                            <div className='mb-4 text-center bassaBox'>
                                                <input type='radio' name="urgency" id='bassa' className='hidden' />
                                                <label for="bassa" className='cursor-pointer inline-block rounded-lg border px-4 py-2 w-full border-[#86D8B0] font-semibold text-base'>Bassa</label>
                                            </div>
                                            <div className='mb-4 mt-8 text-center'>
                                                <button className=' w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Mostra</button>
                                            </div>
                                            <div className='text-center mb-4'>
                                                <button onClick={urgencyClose} type='button' className='text-[#828DEE] underline font-semibold'>Annulla</button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>

                            <div className='pt-5 mb-5'>
                                <Link to="#" className="inline-block px-5 rounded-full border-2 border-[#000] text-black mr-2 mb-2">Tutti gli eventi</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">SOS</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Consulti</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Prescrizioni</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Certificati</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">Bilanci salute</Link>
                                <Link to="#" className="inline-block px-5 rounded-full border border-[#72777A] text-[#72777A] mr-2 mb-2">I miei appunti</Link>
                            </div>
                            <Grid container spacing={4}>
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <EventList />
                                </Grid>
                                <Grid item lg={7} md={7} sm={12} xs={12}>
                                    <div className='text-right mb-4'>
                                        <button onClick={handleOpen} type="button" class="min-w-48 py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-white text-base font-semibold bg-[#828DEE] transition-all">Aggiungi evento</button>
                                    </div>
                                    <EventDetails />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                </Grid>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-8 p-6 rounded-2xl">
                    <div className='md:w-[340px] w-full mx-auto'>
                        <form>
                            <div className='mb-4'>
                                <TextField label="Data evento" variant='outlined' fullWidth />
                            </div>
                            <div className='mb-4'>
                                <TextField label="Tipologia evento" variant='outlined' fullWidth />
                            </div>
                            <div className='mb-4'>
                                <TextField label="Descrizione" multiline rows={3} variant="outlined" fullWidth />
                            </div>
                            <div className='mb-4'>
                                <TextField label="Note del pediatra (non visibili al paziente)" multiline rows={3} variant="outlined" fullWidth />
                            </div>
                            <div className='mb-4 text-center'>
                                <p className='mb-4'>Aggiungi documentazione</p>
                                <label className='text-2xl w-16 h-16 border rounded-full inline-block leading-[62px] text-[#72777A] border-gray-400 cursor-pointer'>
                                    <input type='file' className='hidden' />
                                    <i className="fa-solid fa-plus"></i>
                                </label>
                            </div>

                            <div className='text-center mt-10'>
                                <button className='block text-center leading-[54px] w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all mx-auto'>Aggiungi</button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

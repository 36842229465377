import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import { Box, FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material';
import MedicalRecord from '../PatientSearch/MedicalRecord';
import MedicalFilter from '../PatientSearch/MedicalFilter';
import useAuthStore from '../../hooks/useAuthStore';
import axios from 'axios';
import { Constants } from '../../endPoints';
import Swal from 'sweetalert2';



export default function ChatSidebar({ requestData }) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [openrecord, setOpenrecord] = React.useState(false);
    const recordOpen = () => setOpenrecord(true);
    const recordClose = () => setOpenrecord(false);
    const [closureReasons, setClosureReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState('');
    const { token } = useAuthStore();
    const navigate = useNavigate();

    const handleOpen = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.closureList}?keyword=&page=1&limit=100`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                setClosureReasons(response.data.data); // Assuming the API returns results in `results` key
                setOpen(true);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch closure reasons',
                });
                console.error('Error fetching closure reasons:');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch closure reasons. Please try again later.',
            });
            console.error('Error fetching closure reasons:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedReason) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Please select a closure reason.',
            });
            return;
        }

        setLoading(true);

        const payload = {
            id: requestData?.id,
            closureReason: selectedReason,
        };

        try {
            const response = await axios.post(`${Constants.closeSos}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'SOS Request has been successfully closed!',
                });
                setOpen(false);
                navigate('/request-sos'); // Redirect if status is 1
            } else {
                throw new Error('Failed to close SOS');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to close SOS. Please try again later.',
            });
            console.error('Error closing SOS:', error);
        } finally {
            setLoading(false);
        }
    };


    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    if (!requestData) {
        return <div>Loading...</div>; // Handle the case where requestData is not yet available
    }


    return (
        <>
            <div className='md:pt-14'>
                <div className='text-center mb-10'>
                    <img src={requestData?.patientProfileImage} alt='Wait...' className='w-[100px] h-[100px] rounded-full object-cover mx-auto' />
                    <h3 className='mt-5 font-semibold text-xl'>{requestData?.patientFirstName} {requestData?.patientLastName}</h3>
                    <h4 className='font-semibold text-base'>{calculateAge(requestData?.patientDateOfBirth)} anni</h4>
                </div>
                <div className='text-center'>
                    <button onClick={recordOpen} type='button' className="block text-center leading-[54px] px-8 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Vedi cartella clinica</button>
                </div>
                <div className='text-center md:mt-48'>
                    <button type='button' onClick={handleOpen} className='text-red-500 underline font-semibold'>Chiudi Consulto</button>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[480px] w-[90%] bg-[#F8FFFC] md:p-10 p-6 rounded-2xl">
                    <h2 className='md:text-2xl text-xl font-bold text-center mb-4'>Chiusura Consulto</h2>
                    <p className='text-center mb-6'>Seleziona il motivo della chiusura</p>
                    <form onSubmit={handleSubmit}>
                        <RadioGroup
                            name="seleziona"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                        >
                            {closureReasons.map((reason) => (
                                <FormControlLabel
                                    key={reason.id}
                                    value={reason.closureReason}
                                    control={<Radio />}
                                    className='border-b py-1'
                                    label={reason.closureReason}
                                />
                            ))}
                        </RadioGroup>
                        <div className='text-center mt-10'>
                            <button
                                type='submit'
                                className='block text-center leading-[54px] w-48 mx-auto h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold transition-all'
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Salva e chiudi'}
                            </button>
                        </div>
                        <div className='text-center mt-6'>
                            <button type='button' className=' text-[#828DEE] underline' onClick={handleClose}>Chiudi</button>
                        </div>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={openrecord}
                onClose={recordClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal lg:w-[1000px] w-[95%] bg-[#F8FFFC] md:p-6 p-6 rounded-2xl">
                    <div className='flex justify-between mb-2 gap-3'>
                        <h2 className=' text-base text-[#828DEE]'><i class="fa-regular fa-square-list"></i> Cartella clinica</h2>
                        <div><button onClick={recordClose} type='button' className='text-xl '><i class="fa-solid fa-circle-xmark"></i></button></div>
                    </div>
                    <form>
                        <div className='mt-14'>
                            <MedicalFilter />
                        </div>
                        <MedicalRecord />
                    </form>
                </Box>
            </Modal>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Checkbox, Grid, Modal, TextField } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Constants } from '../../endPoints';
import useAuthStore from "../../hooks/useAuthStore";

export default function SosCaselist() {
    const navigate = useNavigate();
    const { token } = useAuthStore();
    const idPediatrician = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sosList, setSosList] = useState([]);

    // Modal states
    const [open, setOpen] = useState(false);
    const [add, setAdd] = useState(false);

    // Form states for adding SOS case
    const [caseTitle, setCaseTitle] = useState('');
    const [caseDescription, setCaseDescription] = useState('');
    const [childrenUnderOne, setChildrenUnderOne] = useState(false);
    const [childrenOverOne, setChildrenOverOne] = useState(false);

    // Validation states
    const [titleError, setTitleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);

    const [selectedSosCase, setSelectedSosCase] = useState(null);
    const [modifiedRecords, setModifiedRecords] = useState({});

    const [editModalOpen, setEditModalOpen] = useState(false);

    // Modal handlers
    const handleOpen = (sosCase) => {
        setSelectedSosCase(sosCase);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    // const itemOpen = () => setAdd(true);
    const itemOpen = () => {
        setCaseTitle('');
        setCaseDescription('');
        setChildrenUnderOne(false);
        setChildrenOverOne(false);
        setCheckboxError(false);
        setAdd(true); // Open the modal
    };

    const itemClose = () => setAdd(false);

    const handleEditOpen = (sosCase) => {
        setSelectedSosCase(sosCase);
        setCaseTitle(sosCase.caseTitle);
        setCaseDescription(sosCase.descriptions);
        setChildrenUnderOne(sosCase.childrenUnderOne);
        setChildrenOverOne(sosCase.childrenOverOne);
        setEditModalOpen(true);
    };
    
    const handleEditClose = () => {
        setEditModalOpen(false);
    };

    // Fetch SOS case list
    const sosCaseList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${Constants.pediatricianSosEmergenciesList}`,
                {
                    params: {
                        idPediatrician,
                        keyword: '',
                        page: 1,
                        limit: 100,
                    },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.data && response.data.soslist) {
                setSosList(response.data.soslist);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Errore',
                    text: 'Errore imprevisto durante il recupero dei casi SOS. Riprova.'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile recuperare i casi SOS. Riprova più tardi.'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (id, status) => {
        setModifiedRecords((prev) => ({
            ...prev,
            [id]: status,
        }));
    };

    const saveChanges = async () => {
        const records = sosList.map((sosCase) => {
            const updatedStatus = modifiedRecords[sosCase.id];
            return {
                id: sosCase.id,
                status: updatedStatus !== undefined ? updatedStatus : sosCase.isActive,
            };
        });

        try {
            const response = await axios.post(
                `${Constants.pediatricianSosBulkUpdate}`,
                {
                    idPediatrician,
                    records,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Successo',
                    text: 'Le modifiche sono state salvate con successo.',
                });
                sosCaseList(); // Refresh SOS list
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile salvare le modifiche. Riprova più tardi.',
            });
        }
    };

    // Add SOS Case
    const addSosCase = async () => {
        // Validate form
        let isValid = true;

        if (!caseTitle.trim()) {
            setTitleError(true);
            isValid = false;
        } else {
            setTitleError(false);
        }

        if (!caseDescription.trim()) {
            setDescriptionError(true);
            isValid = false;
        } else {
            setDescriptionError(false);
        }

        if (!childrenUnderOne && !childrenOverOne) {
            setCheckboxError(true);
            isValid = false;
        } else {
            setCheckboxError(false);
        }

        if (!isValid) return;

        try {
            const response = await axios.post(
                `${Constants.pediatricianAddSosEmergencies}`,
                {
                    idPediatrician,
                    caseTitle,
                    descriptions: caseDescription,
                    childrenUnderOne,
                    childrenOverOne,
                    languageId: "1"
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Success alert
            Swal.fire({
                icon: 'success',
                title: 'Successo',
                text: 'Caso SOS aggiunto con successo!'
            });

            // Reset form and close modal
            setCaseTitle('');
            setCaseDescription('');
            setChildrenUnderOne(false);
            setChildrenOverOne(false);
            itemClose();

            // Refresh the list
            sosCaseList();
        } catch (error) {
            // Error alert
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile aggiungere il caso SOS. Riprova più tardi.'
            });
        }
    };

    // Checkbox handler for modal (Add SOS Case)
    const handleCheckboxToggle = (type) => {
        if (type === 'underOne') {
            setChildrenUnderOne((prev) => !prev);
            setCheckboxError(false);
        } else if (type === 'overOne') {
            setChildrenOverOne((prev) => !prev);
            setCheckboxError(false);
        }
    };


    useEffect(() => {
        sosCaseList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPediatrician]);

    const deleteSosCase = async () => {
        if (!selectedSosCase) return;

        try {
            const response = await axios.delete(
                `${Constants.deletePediatricianSOSEmergencies}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        id: selectedSosCase.id,
                        idPediatrician
                    }
                }
            );

            // Success alert
            Swal.fire({
                icon: 'success',
                title: 'Successo',
                text: 'Caso SOS eliminato con successo!'
            });

            // Close the modal and refresh the list
            setOpen(false);
            setSelectedSosCase(null);
            sosCaseList(); // Refresh SOS list
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile eliminare il caso SOS. Riprova più tardi.'
            });
        }
    };

    // Edit SOS Case
    const editSosCase = async () => {
        if (!selectedSosCase) return;

        try {
            const response = await axios.post(
                `${Constants.pediatricianSosUpdate}`,
                {
                    id: selectedSosCase.id,
                    idPediatrician,
                    caseTitle,
                    descriptions: caseDescription,
                    childrenUnderOne,
                    childrenOverOne,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Successo',
                    text: 'Il caso SOS è stato aggiornato con successo!',
                });
                setEditModalOpen(false);
                sosCaseList(); // Refresh SOS list
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile aggiornare il caso SOS. Riprova più tardi.',
            });
        }
    };

    if (loading) {
        return <div>Caricamento...</div>;
    }



    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/profile">
                                    <i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo
                                </Link>
                                <Link to="/sos-management">
                                    <span>/</span> Gestione SOS
                                </Link>
                                <span>/</span>
                                <span>Lista casi SOS</span>
                            </div>
                            <div>
                                <div className='flex pb-8 border-b justify-between'>
                                    <h1 className='font-semibold text-2xl '>Seleziona i casi SOS che vorrai gestire</h1>
                                    <div>
                                        <button onClick={saveChanges} className="block text-center leading-[54px] px-5 lg:min-w-60 min-w-32 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Salva</button>
                                    </div>
                                </div>


                                {/* Bambini < 1 anno Section */}
                                <div className="pt-5">
                                    <div className="flex justify-between align-center mb-6">
                                        <h2 className="text-xl font-semibold">Bambini <i className="fa-regular fa-angle-left"></i>1 anno</h2>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={itemOpen}
                                                className="underline text-[#828DEE] italic"
                                            >
                                                Aggiungi voce
                                            </button>
                                        </div>
                                    </div>
                                    {sosList
                                        .filter((item) => item.childrenUnderOne)
                                        .map((sosCase) => (
                                            <div
                                                key={sosCase.id}
                                                className="flex gap-4 items-center p-3 shadow-lg rounded-lg bg-white mb-4"
                                            >
                                                <div>
                                                    <Checkbox
                                                        defaultChecked={sosCase.isActive}
                                                        onChange={(e) =>
                                                            handleCheckboxChange(sosCase.id, e.target.checked)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <p className="font-semibold mb-1">{sosCase.caseTitle}</p>
                                                    <p>{sosCase.descriptions}</p>
                                                </div>
                                                <div className="px-2 min-w-14">
                                                    <button
                                                        onClick={() => handleOpen(sosCase)}
                                                        className="text-red-500"
                                                    >
                                                        <i className="fa-regular fa-trash-can"></i>
                                                    </button>

                                                    <button
                                                        onClick={() => handleEditOpen(sosCase)}
                                                        className="text-blue-500 ml-2"
                                                    >
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                {/* Bambini > 1 anno Section */}
                                <div className="pt-5">
                                    <div className="flex justify-between align-center mb-6">
                                        <h2 className="text-xl font-semibold">Bambini <i className="fa-regular fa-angle-right"></i>1 anno</h2>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={itemOpen}
                                                className="underline text-[#828DEE] italic"
                                            >
                                                Aggiungi voce
                                            </button>
                                        </div>
                                    </div>
                                    {sosList
                                        .filter((item) => item.childrenOverOne)
                                        .map((sosCase) => (
                                            <div
                                                key={sosCase.id}
                                                className="flex gap-4 items-center p-3 shadow-lg rounded-lg bg-white mb-4"
                                            >
                                                <div>
                                                    <Checkbox
                                                        defaultChecked={sosCase.isActive}
                                                        onChange={(e) =>
                                                            handleCheckboxChange(sosCase.id, e.target.checked)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <p className="font-semibold mb-1">{sosCase.caseTitle}</p>
                                                    <p>{sosCase.descriptions}</p>
                                                </div>
                                                <div className="px-2 min-w-14">
                                                    <button
                                                        onClick={() => handleOpen(sosCase)}
                                                        className="text-red-500"
                                                    >
                                                        <i className="fa-regular fa-trash-can"></i>
                                                    </button>
                                                    <button
                                                        onClick={() => handleEditOpen(sosCase)}
                                                        className="text-blue-500  ml-2"
                                                    >
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>


                            </div>

                        </div>
                    </Grid>
                </Grid>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[370px] w-[280px] bg-[#F8FFFC] md:p-10 p-6 rounded-2xl">
                    <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Vuoi eliminare questa voce dal menu?</h2>
                    <form>
                        <div className='text-center'>
                            <p>Se desideri solo disabilitare la funzione, deseleziona l'opzione; in caso contrario, procedi con l'eliminazione.</p>
                        </div>
                        <div className='text-center mt-10'>
                            <button
                                type="button"
                                className='block text-center leading-[54px] w-full h-[54px] shadow-0 bg-[#F04438] rounded-full text-white font-semibold hover:bg-red-600 transition-all'
                                onClick={deleteSosCase}
                            >
                                Elimina
                            </button>
                        </div>
                        <div className='text-center mt-6'>
                            <button
                                type='button'
                                className='text-red-600 underline'
                                onClick={handleClose}
                            >
                                Annulla
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>


            <Modal
                open={add}
                onClose={itemClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[550px] w-[90%] bg-[#F8FFFC] md:p-10 p-6 rounded-2xl">
                    <h2 className="md:text-2xl text-xl font-bold text-center mb-10">Aggiungi voce SOS</h2>
                    <form>
                        <div className="mb-4">
                            <TextField
                                label="Titolo caso SOS"
                                fullWidth
                                variant="outlined"
                                value={caseTitle}
                                onChange={(e) => {
                                    setCaseTitle(e.target.value);
                                    setTitleError(false);
                                }}
                                error={titleError}
                                helperText={titleError ? "Titolo è obbligatorio" : ""}
                            />
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Descrizione del caso (aiuta a definire meglio l'emergenza)"
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                value={caseDescription}
                                onChange={(e) => {
                                    setCaseDescription(e.target.value);
                                    setDescriptionError(false);
                                }}
                                error={descriptionError}
                                helperText={descriptionError ? "Descrizione è obbligatoria" : ""}
                            />
                        </div>
                        <div className="text-center mb-0">
                            <label>
                                <Checkbox
                                    checked={childrenUnderOne}
                                    onChange={() => handleCheckboxToggle('underOne')}
                                    style={{
                                        color: checkboxError ? 'red' : undefined,
                                    }}
                                />
                                Bambini <i className="fa-regular fa-angle-left"></i>1 anno
                            </label>
                        </div>
                        <div className="text-center mb-4">
                            <label>
                                <Checkbox
                                    checked={childrenOverOne}
                                    onChange={() => handleCheckboxToggle('overOne')}
                                    style={{
                                        color: checkboxError ? 'red' : undefined,
                                    }}
                                />
                                Bambini <i className="fa-regular fa-angle-right"></i>1 anno
                            </label>
                        </div>
                        <div className="text-center mt-10">
                            <button
                                type="button"
                                className={`block text-center leading-[54px] w-48 mx-auto h-[54px] shadow-0 rounded-full text-white font-semibold transition-all ${!titleError && !descriptionError && (childrenUnderOne || childrenOverOne)
                                    ? 'bg-[#828DEE] hover:bg-blue-700'
                                    : 'bg-gray-400 cursor-not-allowed'
                                    }`}
                                onClick={addSosCase}
                                disabled={titleError || descriptionError || (!childrenUnderOne && !childrenOverOne)}
                            >
                                Aggiungi
                            </button>
                        </div>
                        <div className="text-center mt-6">
                            <button
                                type="button"
                                className="text-[#828DEE] underline"
                                onClick={itemClose}
                            >
                                Chiudi
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={editModalOpen}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[550px] w-[90%] bg-[#F8FFFC] md:p-10 p-6 rounded-2xl">
                    <h2 className="md:text-2xl text-xl font-bold text-center mb-10">Modifica voce SOS</h2>
                    <form>
                        <div className="mb-4">
                            <TextField
                                label="Titolo caso SOS"
                                fullWidth
                                variant="outlined"
                                value={caseTitle}
                                onChange={(e) => {
                                    setCaseTitle(e.target.value);
                                    setTitleError(false);
                                }}
                                error={titleError}
                                helperText={titleError ? "Titolo è obbligatorio" : ""}
                            />
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Descrizione del caso"
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                value={caseDescription}
                                onChange={(e) => {
                                    setCaseDescription(e.target.value);
                                    setDescriptionError(false);
                                }}
                                error={descriptionError}
                                helperText={descriptionError ? "Descrizione è obbligatoria" : ""}
                            />
                        </div>
                        <div className="text-center mb-0">
                            <label>
                                <Checkbox
                                    checked={childrenUnderOne}
                                    onChange={() => setChildrenUnderOne(!childrenUnderOne)}
                                />
                                Bambini <i className="fa-regular fa-angle-left"></i>1 anno
                            </label>
                        </div>
                        <div className="text-center mb-4">
                            <label>
                                <Checkbox
                                    checked={childrenOverOne}
                                    onChange={() => setChildrenOverOne(!childrenOverOne)}
                                />
                                Bambini <i className="fa-regular fa-angle-right"></i>1 anno
                            </label>
                        </div>
                        <div className="text-center mt-10">
                            <button
                                type="button"
                                className="block text-center leading-[54px] w-48 mx-auto h-[54px] shadow-0 rounded-full bg-[#828DEE] text-white font-semibold hover:bg-blue-700"
                                onClick={editSosCase}
                            >
                                Salva modifiche
                            </button>
                        </div>
                        <div className="text-center mt-6">
                            <button
                                type="button"
                                className="text-[#828DEE] underline"
                                onClick={handleEditClose}
                            >
                                Chiudi
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>


        </>
    )
}

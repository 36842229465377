import { Box, Grid, Modal, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext
import axios from 'axios';
import { Constants } from '../../endPoints';
import Swal from 'sweetalert2';



export default function Profile() {
    const { user ,updateUser,forceUserDataUpdate} = useUser()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('photo', file);
          formData.append('idPediatrician', user.id);
          formData.append('dateOfBirth', user.dateOfBirth);
    
          try {
            const response = await axios.post(Constants.profilePediatrician, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
    
            if (response.status === 200 || response.status === 201) {
              updateUser({
                ...user,
                photo:  response.data.pediatrician.photo, // Adjust based on your API response
              });
              forceUserDataUpdate();
              Swal.fire({
                title: 'Success',
                text: 'Profile photo updated successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
              });
            }
          } catch (error) {
            console.error('Error uploading the photo:', error);
            Swal.fire({
              title: 'Error',
              text: 'Failed to upload the profile photo. Please try again.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        }
      };
      const [openlang, setOpenlang] = React.useState(false);
      const langOpen = () => setOpenlang(true);
      const langClose = () => setOpenlang(false);
  
      const [selectedLanguage, setSelectedLanguage] = useState('Italian');
      const handleLanguageChange = (language) => {
          setSelectedLanguage(language);
      };

    return (
        <>
            <div className='flex gap-3 mb-6 font-semibold'>
                <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link>
            </div>
            <Grid container spacing={3} className='justify-center'>
                <Grid item lg={9} md={12} xs={12}>
                    <div className='mb-6 text-center'>
                        <label className='w-20 inline-block mx-auto relative'>
                            <img  src={user?.photo || '/assets/images/default-profile.png'}  alt={user?.photo || 'User Image'} className='w-full border aspect-square rounded-full object-cover' />
                            <input type='file' className='hidden' onChange={handleFileChange} />
                            <span className='w-7 h-7 bg-white rounded-full absolute bottom-[-3px] right-0 text-xs border border-gray-500 leading-7 cursor-pointer'><i class="fa-regular fa-pen-to-square"></i></span>
                        </label>
                    </div>

                    <div className='mb-6'>
                        <Link to="/profile/personal/edit" class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-square-user"></i>
                                </div>
                                Anagrafica
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="/profile/professional/edit" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-id-card-clip"></i>
                                </div>
                                Dati Professionali
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="/profile/identity/edit" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-id-card"></i>
                                </div>
                                Documento di riconoscimento
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="/medical-practice-management" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-briefcase-blank"></i>
                                </div>
                                Gestione studio medico
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="#" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-credit-card"></i>
                                </div>
                                Account pagamenti
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="/receipts" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-memo-pad"></i>
                                </div>
                                Ricevute
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="/sos" class="flex gap-3 items-center justify-between bg-white  mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-light-emergency-on"></i>
                                </div>
                                Gestione SOS
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <h2 className='text-xl font-bold mb-5'>Impostazioni</h2>
                        <Link to="/communication-center" class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-bullhorn"></i>
                                </div>
                                Centro comunicazioni
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link onClick={langOpen} class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-globe"></i>
                                </div>
                                Lingua
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                        <Link to="#" onClick={handleOpen} class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all text-red-500">
                            <div className='flex gap-5 items-center'>
                                <div className='min-w-5 text-center'>
                                    <i class="fa-regular fa-trash-can"></i>
                                </div>
                                Elimina Account
                            </div>
                            <div>
                                <i class="fa-solid fa-angle-right"></i>
                            </div>
                        </Link>
                    </div>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
                    <h2 className='md:text-2xl text-xl font-bold text-center mb-5'>Vuoi davvero eliminare l’account?</h2>
                    <p className='text-center mb-10'>Cancelleremo tutti i tuoi dati e non sarà possibile recuperarerli</p>
                    <div className='mb-6'>
                        <button className='w-full h-[54px] shadow-0 bg-red-500 rounded-full text-white font-semibold hover:bg-red-600 transition-all'>Cancella</button>
                    </div>
                    <div className='text-center'>
                        <Link to="#" onClick={handleClose} className="underline text-[#828DEE]">Chiudi</Link>
                    </div>
                </Box>
            </Modal>
            <Modal 
            open={openlang}
            onClose={langClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal md:w-[450px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
            <h2 className='md:text-2xl text-xl font-bold text-center mb-8'>Seleziona lingua</h2>
            <div className='mb-8'>
                <List>
                    <ListItem button onClick={() => handleLanguageChange('Italian')}>
                        <div className='flex gap-3 items-center grow'>
                            <div>
                                <img src='assets/images/italian.png' className='w-[42px] h-[42px] rounded-full object-cover'/>
                            </div>
                            <ListItemText primary="Italiano" />
                        </div>
                        
                        {selectedLanguage === 'Italian' && (
                        <ListItemIcon className='justify-center'>
                            <i className="fa-solid fa-check text-[#5EAF88] text-2xl"></i>
                        </ListItemIcon>
                        )}
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => handleLanguageChange('English-UK')}>
                        <div className='flex gap-3 items-center grow'>
                            <div>
                                <img src='assets/images/english.png' className='w-[42px] h-[42px] rounded-full object-cover'/>
                            </div>
                            <ListItemText primary="Inglese" />
                        </div>
                        {selectedLanguage === 'English-UK' && (
                        <ListItemIcon className='justify-center'>
                            <i className="fa-solid fa-check text-[#5EAF88] text-2xl"></i>
                        </ListItemIcon>
                        )}
                    </ListItem>
                </List>
            </div>
            <div className='mb-6 text-center'>
                <button className='w-56 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold transition-all'>Cancella</button>
            </div>
            <div className='text-center'>
                <Link to="#" onClick={langClose} className="underline text-[#828DEE]">Chiudi</Link>
            </div>
            </Box>
        </Modal>
        </>
    )
}
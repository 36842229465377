import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import { Constants } from '../../endPoints';
import './Studio.css'

const MySwal = withReactContent(Swal);

export default function AddStudio() {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const idPediatrician = localStorage.getItem('userId');

    const [regionalCodes, setRegionalCodes] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [zipOptions, setZipOptions] = useState([]);

    const [loadingProvinces, setLoadingProvinces] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const [loadingZips, setLoadingZips] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const select2Initialized = useRef(false);
    const formikRef = useRef(); // To access Formik's methods

    // Fetch regions on component mount
    useEffect(() => {
        const fetchRegionalCodes = async () => {
            try {
                const response = await fetch(`${Constants.regionList}?&page=1&limit=500`);
                if (response.ok) {
                    const data = await response.json();
                    setRegionalCodes(data.data);
                } else {
                    console.error('Failed to fetch regions');
                }
            } catch (error) {
                console.error('Error in fetching regions:', error);
            }
        };

        fetchRegionalCodes();
    }, []);

    // Initialize select2 only once and bind events to update Formik values
    useEffect(() => {
        if (!select2Initialized.current) {
            $('#region').select2().on('change', function () {
                const regionId = $(this).val();
                // Update Formik state
                formikRef.current.setFieldValue('selectedRegion', regionId);
                // Fetch provinces
                fetchProvinceCodes(regionId);
                // Reset province, city, and zip selections
                formikRef.current.setFieldValue('selectedProvince', '');
                formikRef.current.setFieldValue('selectedCity', '');
                formikRef.current.setFieldValue('selectedZip', '');
                // Clear province options
                setProvinceOptions([]);
                setCityOptions([]);
                setZipOptions([]);
                // Reset province, city, and zip selects
                $('#province').val(null).trigger('change');
                $('#city').val(null).trigger('change');
                $('#zip').val(null).trigger('change');
            });

            $('#province').select2({
                disabled: true // Initially disabled
            }).on('change', function () {
                const provinceId = $(this).val();
                formikRef.current.setFieldValue('selectedProvince', provinceId);
                // Fetch cities
                fetchCities(provinceId);
                // Reset city and zip selections
                formikRef.current.setFieldValue('selectedCity', '');
                formikRef.current.setFieldValue('selectedZip', '');
                // Clear city and zip options
                setCityOptions([]);
                setZipOptions([]);
                // Reset city and zip selects
                $('#city').val(null).trigger('change');
                $('#zip').val(null).trigger('change');
            });

            $('#city').select2({
                disabled: true // Initially disabled
            }).on('change', function () {
                const cityId = $(this).val();
                formikRef.current.setFieldValue('selectedCity', cityId);
                // Fetch zip codes
                fetchZipCodes(cityId);
                // Reset zip selection
                formikRef.current.setFieldValue('selectedZip', '');
                // Clear zip options
                setZipOptions([]);
                // Reset zip select
                $('#zip').val(null).trigger('change');
            });

            $('#zip').select2({
                disabled: true // Initially disabled
            }).on('change', function () {
                const zipCode = $(this).val();
                formikRef.current.setFieldValue('selectedZip', zipCode);
            });

            select2Initialized.current = true;
        }
    }, []);

    const fetchProvinceCodes = async (regionCode) => {
        setLoadingProvinces(true);
        try {
            const response = await fetch(`${Constants.regionWiseProvince + regionCode}?page=1&limit=500`);
            if (response.ok) {
                const data = await response.json();
                setProvinceOptions(data.data);
                $('#province').removeAttr('disabled');
                $('#province').select2('destroy').select2(); // Re-initialize select2 to pick up new options
            } else {
                console.error('Failed to fetch provinces');
            }
        } catch (error) {
            console.error('Error in fetching provinces:', error);
        } finally {
            setLoadingProvinces(false);
        }
    };

    const fetchCities = async (provinceAcronym) => {
        setLoadingCities(true);
        try {
            const response = await fetch(`${Constants.provinceWiseCity}?idProvince=${provinceAcronym}&page=1&limit=250`);
            if (response.ok) {
                const data = await response.json();
                setCityOptions(data.data);
                $('#city').removeAttr('disabled');
                $('#city').select2('destroy').select2();
            } else {
                console.error('Failed to fetch cities');
            }
        } catch (error) {
            console.error('Error in fetching cities:', error);
        } finally {
            setLoadingCities(false);
        }
    };

    const fetchZipCodes = async (idCity) => {
        setLoadingZips(true);
        try {
            const response = await fetch(`${Constants.cityWiseZIPCodes}${idCity}`);
            if (response.ok) {
                const data = await response.json();
                setZipOptions(data.ziplist || []);
                $('#zip').removeAttr('disabled');
                $('#zip').select2('destroy').select2();
            } else {
                console.error('Failed to fetch zip codes', response.statusText);
            }
        } catch (error) {
            console.error('Error in fetching zip codes:', error);
        } finally {
            setLoadingZips(false);
        }
    };

    const handleSubmit = async (values, { resetForm }) => {
        setIsSubmitting(true);

        try {
            const newAddress = {
                idPediatrician,
                studio: values.studio,
                street: values.street,
                region: values.selectedRegion,
                province: values.selectedProvince,
                idCity: values.selectedCity,
                zip: values.selectedZip,
            };

            const url = `${Constants.managePediatricianAddress}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newAddress),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 1) {
                    resetForm();
                    MySwal.fire({
                        icon: 'success',
                        title: 'Studio added successfully!',
                    }).then(() => {
                        navigate("/studio"); // Redirect to the "studio" page
                    });
                } else {
                    console.error('Failed to save address:', data.message);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Failed to save address: ${data.message}`,
                    });
                }
            } else {
                const errorText = await response.text();
                console.error('Failed to save address', errorText);
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        } finally {
            setIsSubmitting(false);
        }
    };



    return (
        <div >
            <Grid container columns={12} spacing={2}>
                <Grid item md={12} sm={12} xs={12} className="bg-[#FAFAFA]">
                    <div>
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                        <span>/</span>
                        <Link to="/medical-practice-management">Gestione studio medico</Link>
                        <span>/</span>
                        <Link to="/studio">Imposta Calendario Viste</Link>
                        <span>/</span>
                        <span>Aggiungi Studio</span>
                    </div>
                        <div className="mt-10">
                            <Grid container spacing={3} className="justify-center">
                                <Grid item lg={6} md={12} xs={12}>
                                <h2 className='text-center mb-8 text-xl font-semibold'>Inserisci l’indirizzo dello Studio</h2>
                                    <Formik
                                        innerRef={formikRef}
                                        initialValues={{
                                            studio: '',
                                            street: '',
                                            selectedRegion: '',
                                            selectedProvince: '',
                                            selectedCity: '',
                                            selectedZip: '',
                                        }}
                                        validationSchema={Yup.object({
                                            studio: Yup.string().required('Studio Name is required'),
                                            street: Yup.string().required('Street is required'),
                                            selectedRegion: Yup.string().required('Region is required'),
                                            selectedProvince: Yup.string().required('Province is required'),
                                            selectedCity: Yup.string().required('City is required'),
                                            selectedZip: Yup.string().required('Postal Code is required'),
                                        })}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ values, errors, touched, handleChange, handleSubmit }) => (
                                            <form onSubmit={handleSubmit} className="address mb-3">
                                                {/* Studio Name */}
                                                <div className="mb-5">
                                                    <TextField
                                                        fullWidth
                                                        label="Studio Name"
                                                        variant="outlined"
                                                        name="studio"
                                                        value={values.studio}
                                                        onChange={handleChange}
                                                        error={touched.studio && !!errors.studio}
                                                        helperText={touched.studio && errors.studio}
                                                    />
                                                </div>

                                                {/* Street */}
                                                <div className="mb-5">
                                                    <TextField
                                                        fullWidth
                                                        label="Street"
                                                        variant="outlined"
                                                        name="street"
                                                        value={values.street}
                                                        onChange={handleChange}
                                                        error={touched.street && !!errors.street}
                                                        helperText={touched.street && errors.street}
                                                    />
                                                </div>

                                                {/* Region */}
                                                <div className="mb-5">
                                                    <select
                                                        id="region"
                                                        name="selectedRegion"
                                                        className="form-control"
                                                        value={values.selectedRegion}
                                                        onChange={handleChange} // For initial load
                                                    >
                                                        <option value="">Select Region</option>
                                                        {regionalCodes.map((region) => (
                                                            <option key={region.id} value={region.code}>
                                                              {region.denomination}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched.selectedRegion && errors.selectedRegion && (
                                                        <FormHelperText error>{errors.selectedRegion}</FormHelperText>
                                                    )}
                                                </div>

                                                {/* Province */}
                                                <div className="mb-5">
                                                    <select
                                                        id="province"
                                                        name="selectedProvince"
                                                        className="form-control"
                                                        value={values.selectedProvince}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Province</option>
                                                        {provinceOptions.map((province) => (
                                                            <option key={province.id} value={province.acronym}>
                                                                {province.denomination}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched.selectedProvince && errors.selectedProvince && (
                                                        <FormHelperText error>{errors.selectedProvince}</FormHelperText>
                                                    )}
                                                </div>

                                                {/* City */}
                                                <div className="mb-5">
                                                    <select
                                                        id="city"
                                                        name="selectedCity"
                                                        className="form-control"
                                                        value={values.selectedCity}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select City</option>
                                                        {cityOptions.map((city) => (
                                                            <option key={city.id} value={city.istat}>
                                                                {city.denominations}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched.selectedCity && errors.selectedCity && (
                                                        <FormHelperText error>{errors.selectedCity}</FormHelperText>
                                                    )}
                                                </div>

                                                {/* ZIP */}
                                                <div className="mb-5">
                                                    <select
                                                        id="zip"
                                                        name="selectedZip"
                                                        className="form-control"
                                                        value={values.selectedZip}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select ZIP Code</option>
                                                        {zipOptions.map((zip) => (
                                                            <option key={zip.id} value={zip.code}>
                                                                {zip.code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched.selectedZip && errors.selectedZip && (
                                                        <FormHelperText error>{errors.selectedZip}</FormHelperText>
                                                    )}
                                                </div>

                                                <div className="mb-5">
                                                    {/* <Button
                                                        fullWidth
                                                        className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? 'Submitting...' : 'Add Studio'}
                                                    </Button> */}
                                                    <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all' type="submit">
                                                    {isSubmitting ? 'Submitting...' : 'Add Studio'}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

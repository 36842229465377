import React, { useEffect, useState } from 'react'
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { Constants } from '../../endPoints';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useAuthStore from "../../hooks/useAuthStore";
import { useUser } from "../../hooks/useUserContext";
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import axios from 'axios'


export default function Login() {
  const [apiError, setApiError] = useState(null);
  const { setToken, token, setId } = useAuthStore();
  const navigate = useNavigate();
  const backgroundImageUrl = 'assets/images/leftBg.png';
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [resetmodal, setresetModal] = useState(false);
  const resetOpen = () => setresetModal(true);
  const handleClose = () => setOpen(false);
  const { updateUser } = useUser(); // Use the updateUser function from context
  const [loading, setLoading] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const [resetLoading, setResetLoading] = useState(false);

  const isProfile = localStorage.getItem('isProfile');

  console.log('isProfile ', isProfile);

  useEffect(() => {
    if (token) {
      navigate('/dashboard')
    }
    // if (isProfile) {
    //   navigate('/dashboard')
    // }
  }, [])


  const fetchUserData = async () => {
    const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
    if (userId) {
      try {
        const response = await axios.get(`${Constants.getPediatricianByID}${userId}`);
        const fetchedUser = response.data.data;

        if (fetchedUser) {
          updateUser(fetchedUser);
          localStorage.setItem('user', JSON.stringify(fetchedUser)); // Save user data to localStorage
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const resendConfirmationEmail = async (email) => {
    try {
        await axios.post(Constants.resendConfirmationEmail, {
            email
        })
        console.error('Otp sent');
    } catch (error) {
        console.error('Otp sent ERROR :', error);
    }
  }

  const handleResendOtp = async (id, phone) => {
    try {
        const body = {
            phone: phone,
            userId: id,
        };
        const response = await axios.post(Constants.resendConfirmationMobile, body);

        if (response.status === 200) {
            console.log('-- mobile OTP resent ---');
        }
    } catch (error) {
        console.error('Error in OTP resend:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(`L'e-mail è obbligatoria`),
      password: Yup.string().required(`È richiesta la password`),
    }),
    onSubmit: async (values) => {
      console.log(values)
      setLoading(true);
      try {
        const response = await fetch(Constants.userLoginV2, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        let data = await response.json();

        if (response.ok && data.accessToken) {

          localStorage.setItem('userId', data.id);
          localStorage.setItem('isProfile', data.isProfileCompleted);
          setToken(data.accessToken);

          const { isEmailVerified, isPhoneVerified, isProfileCompleted } = data;
          setId(data.id);
          data = { ...data, update: true }
          setErrorMessage(''); // Clear error message on successful login
          fetchUserData()

          if (isEmailVerified && isPhoneVerified && isProfileCompleted) {
            navigate('/dashboard')
          } else {
            console.log('Login successful Navigating to Register');

            if(data.isEmailVerified===false) {
              resendConfirmationEmail(data.email);
            }

            if(data.isPhoneVerified===false && data.isEmailVerified===true && data.phone) {
              handleResendOtp(data.id, data.phone);
            }
            
            navigate(`/register`, { state: { fromLogin: true, data }, replace: true }); // Navigate to the Dashboard component
          }


        } else {
          console.log(`Login failed with status: ${response.status}`);
          if (response.status === 400) {
            setErrorMessage('Credenziali non valide. Per favore riprova.');
          } else if (response.status === 401) {
            setErrorMessage(data.message || 'Password non valida. Per favore riprova.');
          } else if (response.status === 404) {
            setErrorMessage('e-mail non trovata. Per favore riprova.');
          } else {
            setErrorMessage(`Si è verificato un errore durante l'accesso. Per favore riprova più tardi.`);
          }
        }
      } catch (err) {
        console.log(`Login error: ${err}`);
        navigate('/login');
        setErrorMessage(`Si è verificato un errore durante l'accesso. Per favore riprova più tardi.`);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const validationSchema = Yup.object({
    email: Yup.string().email('Indirizzo e-mail non valido').required(`L'e-mail è obbligatoria`),
  });

  const resetPasswordformik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission (API call or any action)
      await sendResetPasswordLink(values)
    },
  });

  const resetClose = () => {
    setApiError(null);
    setResetMessage(null);
    setresetModal(false);
  };


  const sendResetPasswordLink = async (values) => {
    setResetLoading(true);
    setApiError(null); // Clear previous errors
    setResetMessage(null); // Clear previous success messages

    try {
      const response = await axios.post(Constants.resetPasswordLink, values);

      if (response.status === 201) {
        setResetMessage(
          `Link per reimpostare la password inviato con successo. Per favore controlla la tua email.`
        );
      } else {
        setApiError(`Si è verificato un errore sconosciuto.`);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(error.response.data.message);
        setApiError(error.response.data.message || `Si è verificato un errore.`);
      } else {
        setApiError(`Si è verificato un errore. Per favore riprova più tardi.`);
      }
    } finally {
      setResetLoading(false);
    }
  };




  return (
    <>
      <section className='bgleft relative min-h-screen'>
        <div className='mx-auto min-h-screen align-items-center'>
          <Grid container columns={12} spacing={0} className='overflow-hidden h-full min-h-screen'>
            <Grid item lg={6} sm={12} xs={12} order={{ lg: 2 }} className='table'>
              <div className='align-middle table-cell py-10 px-6'>
                <Grid container spacing={3} className='justify-center'>
                  <Grid item xl={6} lg={7} md={8} xs={12}>
                    <h2 className='mb-8 text-3xl font-semibold text-center'>Accedi</h2>
                    <form onSubmit={formik.handleSubmit}>
                      <div className='mb-5'>
                        <TextField
                          label="Nome utente/e-mail"
                          variant="outlined"
                          className='w-full rounded-lg'
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.touched.email && formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </div>
                      <div className='mb-5'>
                        <FormControl variant="outlined" className='w-full' error={Boolean(formik.touched.password && formik.errors.password)}>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          {formik.touched.password && formik.errors.password && (
                            <FormHelperText>{formik.errors.password}</FormHelperText>
                          )}
                        </FormControl>

                        {errorMessage && <p style={{
                          color: '#f44336',
                          fontSize: '0.875rem',
                          margin: '0 0 20px 0',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                          lineHeight: '1.66',
                          textAlign: 'left',
                          marginLeft: '14px',
                        }}>{errorMessage}</p>}
                      </div>

                      <div className='mb-5'>
                        <button
                          type="submit"
                          className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                        >
                          {loading ? <CircularProgress size={24} color="inherit" /> : 'Accedi'}
                        </button>
                      </div>
                      <div className='mb-5 text-center'>
                        <Link to="#" onClick={resetOpen} className="text-[#828DEE] font-semibold underline">
                          Hai dimenticato la Password?
                        </Link>
                      </div>
                    </form>

                    <div className='mt-16 text-center'>
                      <p className='mb-4'>Non hai un account?</p>
                      <Link to="/register" className='block text-center leading-[54px] w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Registrati</Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} className='bg-cover bg-right-top table' style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
              <div className='align-middle table-cell py-10 relative'>
                <Grid container spacing={3} className='justify-center'>
                  <Grid item lg={6}>
                    <div className='text-center'>
                      <img src='assets/images/logo-white.png' className='mx-auto h-14' alt="Logo" />
                      <p className='my-4 text-white font-semibold'>La linea diretta con i tuoi pazienti</p>
                    </div>
                  </Grid>
                </Grid>
                <div className='text-center md:absolute w-full bottom-20'>
                  <p>Hai qualche dubbio? </p>
                  <p className='font-semibold'>Visita il nostro sito <Link to="#" className="underline">kiddocare.com</Link> </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
          <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Scansiona il QrCode e segui le istruzioni via APP</h2>
          <div className='mb-10'>
            <img src='assets/images/qrcode.png' className='mx-auto w-[200px]' />
          </div>
          <div className='text-center'>
            <Link to="#" onClick={handleClose} className="underline text-[#828DEE]">Chiudi</Link>
          </div>
        </Box>
      </Modal>

      <Modal
        open={resetmodal}
        onClose={resetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
          <h2 className="md:text-2xl text-xl font-bold text-center mb-5">Recupera password</h2>
          <p className="text-center mb-10">Inserisci la tua email per poter ricevere il link di reset password</p>

          {/* Formik Form */}
          <form onSubmit={resetPasswordformik.handleSubmit}>
            <div className="mb-5">
              <TextField
                label="Username / Email"
                variant="outlined"
                className="w-full rounded-lg"
                name="email"
                value={resetPasswordformik.values.email}
                onChange={resetPasswordformik.handleChange}
                onBlur={resetPasswordformik.handleBlur}
                error={resetPasswordformik.touched.email && Boolean(resetPasswordformik.errors.email)}
                helperText={resetPasswordformik.touched.email && resetPasswordformik.errors.email}
              />
            </div>

            {/* Error Message */}
            {apiError && (
              <p className="text-red-500 mb-5 text-center">
                {apiError}
              </p>
            )}

            <div className="mb-5">
              <button
                className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                type="submit"
              >
                {resetLoading ? <CircularProgress size={24} color="inherit" /> : 'Invia'}
              </button>
            </div>

            {/* Success Message */}
            {resetMessage && <p className="text-green-500 mb-5 text-center">{resetMessage}</p>}
          </form>

          <div className="text-center">
            <Link to="#" onClick={resetClose} className="underline text-[#828DEE]">
              Chiudi
            </Link>
          </div>
        </Box>
      </Modal>

    </>
  );
}
